import { AssetConfig, NetworkFirewallAsset } from '../../../../types';
import { defaultConfig } from '../default';
import { telnetSshConfig } from '../default/telnetSshConfig';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { submitDataParser } from '../default/submitDataParser';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';

export const firewallConfig: AssetConfig<NetworkFirewallAsset> = {
  ...defaultConfig,
  name: 'Firewall',
  type: 'firewall',
  description: 'Onboard a firewall via the SNMP and Syslog protocol.',
  icon: faFire,
  form: {
    ...defaultConfig.form,
    submitDataParser,
    initialValues: emptyAssetFactory<NetworkFirewallAsset>({
      domain: true,
      connectionTypes: ['snmp', 'telnet', 'ssh'],
      assetType: 'network_type',
      assetSubType: 'firewall',
      extraFields: {
        brand: '',
        series: '',
        network_config_mode: '',
      },
    }),
    connect: { ...defaultConfig.form.connect, ...telnetSshConfig },
  },
};
