import { KeyValue } from 'types';

export const uniq = (arr: any[], removeNullOrUndefined?: boolean) => {
  const uniqueArr = [...new Set(arr)];
  if (removeNullOrUndefined) return uniqueArr.filter(x => x != null);
  return uniqueArr;
};

export const arrayRemove = (
  arr: any[],
  elementToRemove: any,
  key: string | number
) => {
  if (key) return arr.filter(el => el[key] !== elementToRemove);
  return arr.filter(el => el !== elementToRemove);
};

export const deepCopy = (arr: any) => JSON.parse(JSON.stringify(arr));

export function arrayContains(arr: any[], value: any) {
  if (typeof value !== 'object') return arr.findIndex(x => x === value) > -1;
}

type UpdateObjectOptions = {
  index?: number;
  keyvalue?: KeyValue;
};

export function updateObjectInArray(
  array: any[],
  adjustedData: any,
  options: UpdateObjectOptions
): any[] {
  const hasIndex = !!String(options?.index);
  if (!options || (!hasIndex && !options.keyvalue)) return [];

  const arrayIndex = hasIndex
    ? options.index
    : array.findIndex(
        a => a[options.keyvalue?.key || 'undefined'] === options.keyvalue?.value
      );
  return array.map((item, index) => {
    if (index !== arrayIndex) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...adjustedData,
    };
  });
}

export function insertObjIf<T = Record<string, any>>(
  condition: boolean,
  obj: T
) {
  return condition ? obj : ({} as T);
}

export function insertArrayIf<T = any>(condition: boolean, array: T[]): T[] {
  return condition ? array : ([] as T[]);
}

export function insertArrayIfDefined<T = any[]>(array: T[] = [] as T[]) {
  return insertArrayIf(array.length > 0, array);
}

export function insertObjIfDefined<T extends {} = Record<string, any>>(
  obj: T = {} as T
) {
  return insertObjIf(Object.keys(obj).length > 0, obj);
}

export function insertValueIfDefined<T = string | number | undefined>(
  value: T
): T[] {
  return value ? [value] : [];
}

export function putValueInArrayAtPath(obj: any, path: string, value: any): any {
  const keys = path.split('.');

  const update = (current: any, keys: string[]): any => {
    const [key, ...rest] = keys;

    if (rest.length === 0) {
      if (!Array.isArray(current[key])) {
        current[key] = [];
      }
      return { ...current, [key]: [...current[key], value] };
    }

    return {
      ...current,
      [key]: update(current[key] || {}, rest),
    };
  };

  return update(obj, keys);
}
