import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ApiSite,
  Button,
  Flex,
  Grid,
  Me,
  Site,
} from '@mss/mss-component-library';
import { useQuery } from '@tanstack/react-query';
import Api from '../../common/services/Api';
import world from '../../sites/views/world-110m.json';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Point,
  ZoomableGroup,
  ZoomableGroupProps,
} from 'react-simple-maps';
import { selectSite } from '../store/slice';
import { geoMercator } from 'd3-geo';
import { useTranslation } from 'react-i18next';
import { Motion, spring } from 'react-motion';
import { StyledMarker } from '../components/StyledMarker';

export type SiteMarker = ApiSite & {
  color: string;
  coordinates: Point;
};

// Default d3-geo scale for the mercator projection
const baseScale = 961 / (2 * Math.PI);

export const geographyStyle = {
  default: {
    fill: '#ECEFF1',
    stroke: '#607D8B',
    strokeWidth: 0.75,
    outline: 'none',
  },
  hover: {
    fill: '#ECEFF1',
    stroke: '#607D8B',
    strokeWidth: 0.75,
    outline: 'none',
  },
  pressed: {
    fill: '#ECEFF1',
    stroke: '#607D8B',
    strokeWidth: 0.75,
    outline: 'none',
  },
};

const calculateZoomAndCenter = (
  markers: Point[],
  mapWidth: number,
  mapHeight: number,
): { zoomLevel: number; center: Point } => {
  const zoomRange = [1, 8];
  const longitudes = markers.map(marker => marker[0]);
  const latitudes = markers.map(marker => marker[1]);

  const minLong = Math.min(...longitudes);
  const maxLong = Math.max(...longitudes);
  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);

  const projection = geoMercator();
  const topLeft = projection([minLong, maxLat]);
  const bottomRight = projection([maxLong, minLat]);

  const boxWidth = Math.abs(bottomRight[0] - topLeft[0]);
  const boxHeight = Math.abs(bottomRight[1] - topLeft[1]);

  const zoom = Math.min(mapWidth / boxWidth, mapHeight / boxHeight);
  const zoomLevel = Math.max(zoomRange[0], Math.min(zoomRange[1], zoom - 2)); // Clamp to zoom range

  // Calculate the center point
  const center: Point = [(minLong + maxLong) / 2, (minLat + maxLat) / 2];

  return { zoomLevel, center };
};

const width = 980;
const height = 560;

export function serializeSitesToMap(sites: ApiSite[]): SiteMarker[] {
  return sites.map(site => ({
    ...site,
    coordinates: [parseFloat(site.longitude), parseFloat(site.latitude)],
    color: site.health_status === 'RED' ? '#fa4654' : '#5eba00',
  }));
}

function SiteMap() {
  const [t] = useTranslation('sites');
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: user } = useQuery({
    queryFn: async () => {
      const resp = await Api.get<Me>('/me/');
      return resp.data;
    },
    queryKey: ['me'],
  });

  const [center, setCenter] = useState<Point>([0, 0]);
  const [zoom, setZoom] = useState<number>(1);
  const [autoSet, setAutoSet] = useState(false);
  const [enableMotion, setEnableMotion] = useState(true);
  const markers = useMemo(
    () => serializeSitesToMap(user?.sites ? [...user.sites] : []),
    [user?.sites],
  );

  const initialPosition = useMemo(() => {
    if (markers.length)
      return calculateZoomAndCenter(
        markers.map(marker => marker.coordinates),
        width,
        height,
      );
  }, [markers]);

  useEffect(() => {
    if (initialPosition && !autoSet) {
      const { zoomLevel, center } = initialPosition;
      setAutoSet(true);
      setCenter(center);
      setZoom(zoomLevel);
    }
  }, [autoSet, initialPosition]);

  useEffect(() => {
    if (autoSet) {
      setTimeout(() => setEnableMotion(false), 250);
    }
  }, [autoSet]);

  function handleZoomIn() {
    setZoom(zoom => Math.min(8, zoom + (0.25 + zoom * 0.1)));
  }

  function handleZoomOut() {
    setZoom(zoom => Math.max(1, zoom - (0.25 + zoom * 0.1)));
  }

  function handleReset() {
    setCenter(initialPosition.center);
    setZoom(initialPosition.zoomLevel);
  }

  function handleMoveEnd(
    position: Parameters<ZoomableGroupProps['onMoveEnd']>[0],
  ) {
    setCenter(position.coordinates as Point);
    setZoom(position.zoom);
  }

  function handleSiteClick(site: Site) {
    return function () {
      dispatch(selectSite(site));
      history.push(`/`);
    };
  }

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Flex className="mb-4" h="end">
            <Button
              ghost
              variant="primary"
              theming="center"
              style={{ fontWeight: 600, textTransform: 'uppercase' }}
              onClick={handleZoomIn}
            >
              {t('Zoom in')}
            </Button>
            <Button
              ghost
              variant="primary"
              theming="center"
              style={{
                marginLeft: 8,
                fontWeight: 600,
                textTransform: 'uppercase',
              }}
              onClick={handleZoomOut}
            >
              {t('Zoom out')}
            </Button>
            <Button
              ghost
              variant="primary"
              theming="center"
              style={{
                marginLeft: 8,
                fontWeight: 600,
                textTransform: 'uppercase',
              }}
              onClick={handleReset}
            >
              {t('Reset')}
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col>
          <Motion
            defaultStyle={{
              zoom,
              longitude: center[0],
              latitude: center[1],
            }}
            style={{
              zoom: spring(zoom, {
                stiffness: 210,
                damping: 20,
              }),
              x: spring(center[0], { stiffness: 210, damping: 20 }),
              y: spring(center[1], { stiffness: 210, damping: 20 }),
            }}
          >
            {({ zoom: motionZoom }) => (
              <ComposableMap
                projection="geoMercator"
                className="w-full absolute top-0 left-0"
                projectionConfig={{
                  scale: baseScale,
                }}
                width={width}
                height={height}
              >
                <ZoomableGroup
                  center={center}
                  zoom={enableMotion ? motionZoom : zoom}
                  onMoveEnd={handleMoveEnd}
                >
                  <Geographies geography={world}>
                    {({ geographies }) =>
                      geographies.map(geo => {
                        return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            style={geographyStyle}
                          />
                        );
                      })
                    }
                  </Geographies>
                  {(markers ?? []).map(marker => (
                    <StyledMarker
                      marker={marker}
                      zoom={zoom}
                      handleSiteClick={handleSiteClick(marker)}
                    />
                  ))}
                </ZoomableGroup>
              </ComposableMap>
            )}
          </Motion>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
}

export default SiteMap;
