import { useSelector } from 'react-redux';
import { selectedDashboard } from 'dashboards/store/selectors';
import { useTranslation } from 'react-i18next';

function DashboardBreadcrumb() {
  const Dashboard = useSelector(selectedDashboard);
  const [t] = useTranslation('dashboard');
  return t(Dashboard.name?.trim()) || null;
}

export default DashboardBreadcrumb;
