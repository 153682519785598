import moment from 'moment';
import React from 'react';
import { FormGroup, Grid } from '../../../../../../components';
import { useAssetTranslation } from '../../../../../../hooks';

export function ModalContent({ record }: any) {
  const { t } = useAssetTranslation();
  return (
    <div className="border m-4 p-4 pb-0">
      <Grid.Row>
        <Grid.Col width={6}>
          <FormGroup label={t('ID')} input={record.winlog.event_id} />
        </Grid.Col>
        <Grid.Col width={6}>
          <FormGroup label={t('Log Level')} input={record.log.level} />
        </Grid.Col>
        <Grid.Col width={6}>
          <FormGroup
            label={t('Compute Name')}
            input={record.winlog.computer_name}
          />
        </Grid.Col>
        <Grid.Col width={6}>
          <FormGroup label={t('Channel')} input={record.winlog.channel} />
        </Grid.Col>
        <Grid.Col width={6}>
          <FormGroup label={t('Keywords')} input={record.winlog.keywords} />
        </Grid.Col>
        <Grid.Col width={6}>
          <FormGroup label={t('Record ID')} input={record.winlog.record_id} />
        </Grid.Col>
        <Grid.Col width={6}>
          <FormGroup
            label={t('Source (Provider Name)')}
            input={record.winlog.provider_name}
          />
        </Grid.Col>
        <Grid.Col width={6}>
          <FormGroup
            label={t('Date & Time')}
            input={moment(record.event.created).format('LLLL')}
          />
        </Grid.Col>
        <Grid.Col width={6}>
          <FormGroup label={t('Action')} input={record.event.action} />
        </Grid.Col>
        <Grid.Col width={12}>
          <FormGroup
            label={t('Message')}
            input={
              <pre style={{ whiteSpace: 'pre-line' }}>{record.message}</pre>
            }
          />
        </Grid.Col>
      </Grid.Row>
    </div>
  );
}
