import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { Steps } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { Grid, Loading, Segment } from 'components';
import { defaultResponseHandler, Fetch } from '../../../../utils';

const StepsContainer = styled.div`
  padding: 48px 0 0 48px;

  .ant-steps-item-process .ant-steps-item-icon {
    background: ${p => p.theme.colors.site.primary.main};
    border-color: ${p => p.theme.colors.site.primary.main};
  }
`;
const Address = styled.div`
  color: ${p => p.theme.colors.site.primary.main};
  font-weight: bold;
  font-size: 1.2em;
  padding-bottom: 8px;
`;

export default function SetupLogs() {
  const [t] = useTranslation(['assetNetwork', 'asset', 'common']);
  const { isLoading, data } = useQuery({
    queryKey: ['configuration'],
    queryFn: async () =>
      (
        await defaultResponseHandler<any>({
          request: Fetch().get('/api/configuration/'),
          t,
        })
      ).data ?? {},
  });

  return (
    <>
      <Grid.Row>
        <Grid.Col width={12}>
          <Segment border padding>
            <div>
              <strong>{t('Syslog')}</strong>
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <StepsContainer>
                <Steps direction="vertical" size="small">
                  <Steps.Step
                    status={'process'}
                    title={
                      <>
                        {t('Configure the network asset to send syslog data')}
                      </>
                    }
                  />
                  <Steps.Step
                    status={'process'}
                    title={
                      <>
                        {t(
                          'Send the TCP/UDP syslog data to the following address'
                        )}
                        :<Address>{data?.logstash_host}</Address>
                      </>
                    }
                  />
                  <Steps.Step
                    status={'process'}
                    title={
                      <>
                        {t(
                          'Validate if collected data is presented at the center component'
                        )}
                      </>
                    }
                  />
                </Steps>
              </StepsContainer>
            )}
          </Segment>
        </Grid.Col>
      </Grid.Row>
    </>
  );
}
