import React from 'react';
import styled, { css } from 'styled-components';

type AccessDeniedProps = {
  variant: 'site' | 'center';
  keycloak: any;
};

const colorVariants = {
  site: '#417e80',
  center: '#004f9b',
};

const Wrapper = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #1c2c41;
  text-align: left;
  font-family: sans-serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  width: 100vw;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  .error-code {
    color: #888e9a;
    margin-bottom: 1rem;
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .error-text {
    margin-bottom: 1rem;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 0;
  }

  .error-solution {
    color: #888e9a;
    font-weight: 400;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    margin-top: 0;
  }

  .go-back-button {
    display: inline-block;
    font-weight: 600;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.375;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03);
  }

  .go-back-button--back {
    align-items: center;
    display: flex;
  }

  .go-back-button--back > span {
    margin-left: 4px;
  }

  ${({ variant }: AccessDeniedProps) =>
    css`
      .go-back-button {
        background-color: ${colorVariants[variant]};
        border: 1px solid ${colorVariants[variant]};
      }
    `}
`;

export const AccessDenied = (props: AccessDeniedProps) => {
  return (
    <Wrapper {...props}>
      <div className="error-code">Access denied</div>
      <h1 className="error-text">You are not allowed to login here.</h1>
      <p className="">Please contact your site focal point.</p>
      <button
        className="go-back-button"
        onClick={async () => {
          localStorage.clear();
          sessionStorage.clear();
          await props.keycloak?.logout?.();
        }}
      >
        <div className="go-back-button--back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-1"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
          <span>Logout</span>
        </div>
      </button>
    </Wrapper>
  );
};

export default AccessDenied;
