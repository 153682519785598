import { AssetPageConfig, ControlApplication } from '../../../types';
import { centumVpConfig } from './centumvp';
import columns from './columns';

export const controlConfiguration: AssetPageConfig<ControlApplication> = {
  name: 'Control Application',
  licenseModule: 'controlapplications',
  permissions: ['controlapplicationcentumvp'],
  listView: {
    assetType: 'control',
    subTypeKey: 'control_type',
    columns,
  },
  assets: {
    centum_vp: centumVpConfig,
  },
};
