import { defaultAssetInputs } from '../../defaults/inputs';
import { inputOrderSorter } from '../../../util/inputOrderSorter';

export const defaultSecurityInputs = inputOrderSorter([
  ...defaultAssetInputs,
  {
    fieldName: 'api_url',
    type: 'string',
    required: true,
    isURL: true,
    label: 'API URL',
  },
]);
