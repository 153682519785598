import React from 'react';
import { defaultAssetInputs } from '../../defaults/inputs';
import { CalsysTooltip } from './Tooltip';
import { inputOrderSorter } from '../../../util/inputOrderSorter';

export const calsysConfigureInputs = inputOrderSorter([
  ...defaultAssetInputs,
  {
    type: 'string',
    fieldName: 'db_name',
    label: <CalsysTooltip />,
    placeholder: 'Calsys SQL Database Name',
  },
]);
