import { assetManagementConfiguration } from './assetmanagement';
import { controlConfiguration } from './control';
import { analyzerManagementConfiguration } from './analyzermanagement';
import { securityConfiguration } from './security';
import { computeConfiguration } from './compute';
import { plcdcsConfig } from './plcdcs';
import { networkConfig } from './network';
import { envConfiguration } from './environmental';
import { virtualizationConfiguration } from './virtualizationapplications';
import { AssetPageConfig, AssetTypeURI } from '../../types';

export const assetConfigurations: Record<AssetTypeURI, AssetPageConfig<any>> = {
  assetmanagement: assetManagementConfiguration,
  analyzermanagement: analyzerManagementConfiguration,
  virtualization: virtualizationConfiguration,
  control: controlConfiguration,
  security: securityConfiguration,
  compute: computeConfiguration,
  plcdcs: plcdcsConfig,
  network: networkConfig,
  env: envConfiguration,
  field: {} as AssetPageConfig<any>,
};
