import React from 'react';
import { Activity } from 'react-feather';
import { Col, Row, Tooltip } from 'antd';
import { ColumnsConfig, DefaultAssetColumnProps } from '../types';
import { capitalizeFirstLetter, objectArraySort } from '../utils';
import OperationalStatusIcon from './OperationalStatusIcon';
import Flex from './Flex';
import { AssetRemoteActionsSite } from './AssetRemoteActionsSite';

export function defaultSorter<DataType>(key: string) {
  return (a: DataType, b: DataType) => objectArraySort(a, b, key);
}

type ColumnProps<DataType> = Partial<ColumnsConfig<DataType>> &
  DefaultAssetColumnProps;

export function actionsColumn<DataType>({
  t,
  openConnectModal,
  render,
  ...props
}: ColumnProps<DataType>): ColumnsConfig<DataType> {
  return {
    title: '',
    dataIndex: '',
    key: 'actions' as any,
    width: 40,
    render,
    ...props,
  };
}

export function siteActionsColumn<DataType>({
  t,
  openConnectModal,
  ...props
}: ColumnProps<DataType>) {
  return {
    ...actionsColumn({
      t,
      openConnectModal,
      render: (record: any) => (
        <AssetRemoteActionsSite
          approvedRequests={record.approved_remote_request_count}
          visible={record.profile_count}
          id={record.id}
          openConnectModal={openConnectModal}
        />
      ),
      ...props,
    }),
  };
}

export function ipAddressColumn<DataType>({
  t,
  ...props
}: ColumnProps<DataType>) {
  return {
    title: t('IP Address'),
    dataIndex: 'ip_address',
    key: 'ip_address',
    width: 130,
    sorter: defaultSorter<DataType>('ip_address'),
    searchable: true,
    ...props,
  };
}

export function locationColumn<DataType>({
  t,
  ...props
}: ColumnProps<DataType>) {
  return {
    title: t('Location'),
    dataIndex: 'location',
    key: 'location',
    width: 150,
    sorter: defaultSorter<DataType>('location'),
    filterable: true,
    searchable: true,
    customFilterHeading: t('common:Location'),
    ...props,
  };
}

export function custodianColumn<DataType>({
  t,
  ...props
}: ColumnProps<DataType>) {
  return {
    title: t('Custodian'),
    dataIndex: 'custodian_name',
    key: 'custodian_name',
    width: 150,
    sorter: defaultSorter<DataType>('custodian_name'),
    filterable: true,
    searchable: true,
    customFilterHeading: t('common:Custodian'),
    ...props,
  };
}

export function roleColumn<DataType>({ t, ...props }: ColumnProps<DataType>) {
  return {
    title: t('Role'),
    dataIndex: 'role',
    key: 'role',
    width: 165,
    sorter: defaultSorter<DataType>('role'),
    filterable: true,
    searchable: true,
    customFilterHeading: t('common:Role'),
    ...props,
  };
}

export function assetTypeColumn<DataType>(
  { t, ...props }: ColumnProps<DataType>,
  key: string
) {
  return {
    title: t('Type'),
    dataIndex: key,
    key,
    width: 100,
    sorter: defaultSorter<DataType>(key as string),
    render: (type: string) => t(type),
    filterable: true,
    searchable: true,
    customFilterOptions: (value: DataType[keyof DataType]) =>
      t(`${value}`).toUpperCase(),
    customFilterHeading: t('common:Type'),
    ...props,
  };
}

export function assetTypeLogoColumn<DataType>(
  { t, ...props }: ColumnProps<DataType>,
  key: string,
  logo?: React.ReactNode
) {
  return {
    title: '',
    dataIndex: key,
    key: `${key.toString()}_logo` as any,
    width: 30,
    render: () => (
      <Flex h="center" v="center" style={{ maxWidth: 14, maxHeight: 14 }}>
        {logo}
      </Flex>
    ),
    ...props,
  };
}

export function nameColumn<DataType>({ t, ...props }: ColumnProps<DataType>) {
  return {
    title: t('Name'),
    dataIndex: 'name',
    key: 'name',
    sorter: defaultSorter<DataType>('name'),
    searchable: true,
    customFilterHeading: t('Name'),
    ...props,
  };
}

export function operationalStatusColumn<
  DataType extends {
    operational_status: string;
    heartbeat?: { state: string };
  }
>({ t, ...props }: ColumnProps<DataType>): ColumnsConfig<DataType> {
  return {
    title: <Activity size={18} className="mr-2 ml-2" />,
    dataIndex: 'operational_status',
    key: 'operational_status',
    width: 30,
    render: (_, record: DataType) => (
      <Tooltip
        style={{ lineHeight: 1 }}
        title={
          <div>
            <Row>
              <Col className="mr-1">
                <small>
                  <b>{t('common:Operational state')}</b>:
                </small>
              </Col>
              <Col>
                <small>
                  {t(
                    record.operational_status
                      ? `common:${capitalizeFirstLetter(
                          record.operational_status
                        )}`
                      : 'common:Unknown'
                  )}
                </small>
              </Col>
            </Row>
            {record.heartbeat?.state && (
              <Row>
                <Col className="mr-1">
                  <small>
                    <b>{t('common:Heartbeat state')}</b>:
                  </small>
                </Col>
                <Col>
                  <small>
                    {t(
                      ['up', 'down'].includes(record.heartbeat?.state)
                        ? `common:${capitalizeFirstLetter(
                            record.heartbeat?.state
                          )}`
                        : 'common:Turned off'
                    )}
                  </small>
                </Col>
              </Row>
            )}
          </div>
        }
        placement="right"
      >
        <Flex h="center" v="center" className="ml-1 text-gray-500">
          <OperationalStatusIcon
            operationalStatus={record.operational_status}
            heartbeatStatus={record.heartbeat?.state}
          />
        </Flex>
      </Tooltip>
    ),
    filterable: true,
    searchable: true,
    customFilterOptions: (value: DataType[keyof DataType]) => {
      const val = value as unknown as string[];
      return val ? `${val[0].toUpperCase()}${val.slice(1)}` : '';
    },
    customFilterHeading: t('common:Operational status'),
    ...props,
  };
}
