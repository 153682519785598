import React from 'react';

const IconStartConnection = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
    <g
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10 10l-4 4 4 4M1 22l4-4-4-4" />
      <path
        d="M4 6.75V4.875c0-.497.2-.974.557-1.326C4.913 3.198 5.397 3 5.9 3h15.2c.504 0 .987.198 1.343.55.357.35.557.828.557 1.325v11.25c0 .497-.2.974-.557 1.326-.356.351-.84.549-1.343.549h-5.7"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default IconStartConnection;
