import React from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import {
  Slash,
  Check,
  Clock,
  AlertTriangle,
  Shield,
  RefreshCw,
} from 'react-feather';

const Icon = styled.span`
  font-size: 18px;
`;
type StatusTagProps = {
  status: string;
  label?: string;
};
const iconSize = 14;
export default function StatusTag({ status, label }: StatusTagProps) {
  label = label || status;
  const colorType: any = {
    new: 'default',
    uploading: 'processing',
    syncing: 'processing',
    ready: 'success',
    expired: 'warning',
    error: 'error',
    locked: 'error',
  };
  const icons: any = {
    new: <Clock size={iconSize} />,
    uploading: <RefreshCw size={iconSize} />,
    syncing: <RefreshCw size={iconSize} />,
    ready: <Check size={iconSize} />,
    expired: <Slash size={iconSize} />,
    error: <AlertTriangle size={iconSize} />,
    locked: <Shield size={iconSize} />,
  };

  return (
    <Tag color={colorType[status]}>
      <Icon>{icons[status]}</Icon> {label}
    </Tag>
  );
}
