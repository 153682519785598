import { createAction, createSlice } from '@reduxjs/toolkit';
import { arrayToObject } from 'common/utils';

// List compute asset
export const fetchComputeAssetsRequested = createAction<{ site: string }>(
  'computeAssets/fetchComputeAssetsRequested',
);
export const fetchComputeAssetsFailed = createAction(
  'computeAssets/fetchComputeAssetsFailed',
);

// Single compute asset
export const fetchComputeAssetRequested = createAction<{
  id: string;
  site: string;
}>('computeAssets/fetchComputeAssetRequested');

export const fetchComputeAssetFailed = createAction(
  'computeAssets/fetchComputeAssetFailed',
);

const computeAssetsSlice = createSlice({
  name: 'computeAssets',
  initialState: {
    computeAssets: [],
    computeAsset: {},
    computeAssetsById: {},
  },
  reducers: {
    fetchComputeAssetsCompleted(state, action) {
      state.computeAssets = action.payload;
      state.computeAssetsById = arrayToObject(action.payload);
    },
    fetchComputeAssetCompleted(state, action) {
      state.computeAsset = action.payload;
    },
  },
});

export const { fetchComputeAssetCompleted, fetchComputeAssetsCompleted } =
  computeAssetsSlice.actions;

export default computeAssetsSlice.reducer;
