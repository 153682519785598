import styled, { css } from 'styled-components';
import React from 'react';

const Content = styled.div``;

const Footer = styled.div`
  text-align: right;
`;

const Container = styled.div`
  ${(p: { border?: boolean; padding?: boolean }) =>
    p.border &&
    css`
      border: 1px solid ${p => p.theme.colors.common.grey200};
    `}

  ${(p: { border?: boolean; padding?: boolean }) =>
    p.padding &&
    css`
      > ${Content}, > ${Footer} {
        padding: 8px 16px 16px;
      }
    `}
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 8px;
  padding: 0;
`;

const RequiredMark = styled.span`
  font-weight: 500;
`;

const Divider = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  width: 100%;
  margin: 0 auto 1rem auto;
  font-size: 0.875rem;
  color: #888e9a;
  letter-spacing: 0.5px;
  font-weight: 400;

  &:before,
  &:after {
    margin: 0;
    content: '';
  }

  &:before {
    flex-grow: 1;
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    margin-left: 0.5rem;
  }

  &:after {
    margin-right: 0.5rem;
  }
`;

type SegementProps = {
  heading?: React.ReactNode | string;
  children: React.ReactNode | string;
  required?: boolean;
  footer?: React.ReactNode | string;
  border?: boolean;
  padding?: boolean;
  style?: React.CSSProperties;
};

export const Segment = ({
  heading,
  children,
  required,
  footer,
  border,
  padding,
  style,
  ...rest
}: SegementProps) => {
  return (
    <Container border={border} padding={padding} style={style} {...rest}>
      {heading && (
        <>
          <Header>
            {heading}
            {required && <RequiredMark>{'*'}</RequiredMark>}
          </Header>
          <Divider />
        </>
      )}
      <Content>{children}</Content>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  );
};
