import React from 'react';

const IconNozomi = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 43.7 42.1"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M32.2 9.6 22.6 0v7.5l9.6 9.6zM39.9 17.2v7.6l3.8-3.8z"
    />
    <path
      fill="currentColor"
      d="M33.8 11.1v9.8L22.6 9.7v32.4l4.6-4.6V21.1l8.2 8.2 2.9-3V15.7z"
    />
    <path
      fill="currentColor"
      d="m34.3 30.4-5.6-5.6V36zM3.8 17.2 0 21l3.8 3.8zM11.4 32.4l9.7 9.7v-7.6l-9.7-9.7zM9.4 11.6l5.6 5.5v-11z"
    />
    <path
      fill="currentColor"
      d="M16.5 4.5v16.4l-8.2-8.2-3 3v10.6l4.6 4.6v-9.8l11.2 11.2V0z"
    />
  </svg>
);

export default IconNozomi;
