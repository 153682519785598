import {
  AssetConfig,
  SecurityWugApplication,
  StandardInputConfigs,
} from '../../../../types';
import { defaultSecurityApplicationConfig } from '../default';
import { defaultSecurityInputs } from '../default/settingsInputs';
import { TooltipLabel } from '../../../../components';
import React from 'react';

const baseConfig = defaultSecurityApplicationConfig<SecurityWugApplication>(
  {
    name: 'Veeam Backup Enterprise Manager',
    type: 'veeam',
    description:
      'Onboard Veeam Backup Enterprise Manager as a security application.',
  },
  [
    ...defaultSecurityInputs.map(x =>
      x.fieldName === 'api_url'
        ? ({
            ...x,
            label: (
              <TooltipLabel
                label={'WUG API URL'}
                tooltipLabel={
                  'Please enter the URL including the port (default 9644)'
                }
              />
            ),
          } as StandardInputConfigs)
        : x
    ),
  ]
);

export const wugConfig: AssetConfig<SecurityWugApplication> = {
  ...baseConfig,
  form: {
    ...baseConfig.form,
    connect: {
      ...baseConfig.form.connect,
      direct: {
        ...baseConfig.form.connect.direct!,
        testConnection: {
          valueKeys: [
            ...baseConfig.form.connect.direct!.testConnection.valueKeys,
            'api_url',
            'port',
          ],
        },
      },
    },
  },
};
