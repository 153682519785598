import React from 'react';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

const RowAction = styled(AntdButton)`
  border: none;
  &:hover,
  &:active {
    background: ${p => p.theme.colors.common.grey400};
  }
`;

export const RowActionButton = ({ ...props }) => (
  <RowAction style={{ height: 24, width: 24 }} {...props} />
);
