import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import { defaultAssetInputs, userNamePassInputs } from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { AssetConfig, EnvODUAsset } from '../../../../types';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';

export const oduConfig: AssetConfig<EnvODUAsset> = {
  name: 'Yokogawa - Online Diagnosis Unit (ODU)',
  type: 'odu',
  description:
    'Onboard a Yokogawa Online Diagnosis Unit (ODU) via the MNS protocol',
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultAssetInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    initialValues: emptyAssetFactory<EnvODUAsset>({
      connectionTypes: ['direct'],
      assetType: 'env_type',
      assetSubType: 'odu',
    }),
    configure: {
      inputs: defaultAssetInputs,
      schema: inputToValidationReducer(defaultAssetInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassInputs),
        inputs: userNamePassInputs,
        testConnection: {
          valueKeys: [...inputsToKeys(userNamePassInputs), 'ip_address'],
          customKeyMapping: { ip_address: 'host' },
        },
      },
    },
    collectors: {},
  },
};
