import {
  defaultAggsDataParser,
  defaultFilterBaseQuery,
} from '../../../../defaults/defaultQueryHelpers';
import React from 'react';
import { Sliders } from 'react-feather';
import { Query, TablePage } from 'types';
import { columns } from './columns';

const filterKeys = [
  'json.columns.automatic_recovery_action',
  'json.columns.automatic_shutdown_action',
  'json.columns.automatic_startup_action',
  'json.columns.incremental_backup_enabled',
  'json.columns.version',
];

const keywords = [
  'json.columns.automatic_recovery_action',
  'json.columns.automatic_shutdown_action',
  'json.columns.automatic_startup_action',
  'json.columns.version',
];

const parameters: Query['parameters'] = [
  {
    elasticQueryKey: 'mss.id',
    sourceParser: ({ params }) => params.assetId!,
  },
  {
    elasticQueryKey: 'mss.collector_key',
    sourceParser: () => 'virtualization_settings',
  },
  {
    matcher: 'range',
    elasticQueryKey: '@timestamp',
    sourceParser: () => ({
      gte: 'now-49h',
      lte: 'now',
    }),
  },
];

const index = 'virtualization-microsoft-hyper-v-catalogue*';

export const virtualizationSettings: TablePage = {
  slug: 'virtualization-settings',
  title: 'Virtualization Settings',
  icon: <Sliders size={14} />,
  type: 'table',
  tableConfig: {
    columns,
    rowKey: 'json.columns.element_name',
    filterKeys,
    keywords,
    sortingStyle: 'frontend',
    filterQuery: {
      key: 'filter',
      parameters,
      index,
      dataParser: data => ({
        total: data?.hits?.total?.value ?? 0,
        data: data.aggregations,
      }),
      baseQuery: defaultFilterBaseQuery(filterKeys, keywords),
    },
    contentQueries: [
      {
        key: 'virtualization-settings',
        parameters,
        index,
        queryType: 'aggregation',
        paginationStyle: 'frontend',
        defaultSort: {
          columnKey: '@timestamp',
          order: 'descend',
        },

        dataParser: defaultAggsDataParser,
        baseQuery: {
          size: 0,
          query: {},
          aggs: {
            'top-hits': {
              terms: {
                field: 'json.columns.element_name.keyword',
                size: 5000,
              },
              aggs: {
                top_uids_hits: {
                  top_hits: {
                    sort: [],
                    _source: {
                      includes: [
                        'json.columns.element_name',
                        'json.columns.automatic_recovery_action',
                        'json.columns.automatic_shutdown_action',
                        'json.columns.automatic_startup_action',
                        'json.columns.incremental_backup_enabled',
                        'json.columns.version',
                      ],
                    },
                    size: 1,
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};
