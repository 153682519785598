import styled from 'styled-components';
type ChangedEventProps = {
  changed: boolean;
};

const ChangedEvent = styled.span<ChangedEventProps>`
  background-color: ${({ changed }) =>
    changed ? 'var(--warning)' : 'transparent'};
`;

export default ChangedEvent;
