import { useTranslation } from 'react-i18next';
import React from 'react';
import { Modal } from 'antd';
import RemoteCreateConnection from './RemoteCreateConnection';

type Props = {
  closeModal: () => void;
  remoteRequest: any;
  visible?: any;
};

const DirectRemoteConnectModal = ({
  closeModal,
  remoteRequest,
  visible = false,
}: Props) => {
  const [t] = useTranslation(['remoteAccess', 'common']);

  return (
    <Modal
      title={t('Connect to asset')}
      visible={visible}
      footer={null}
      onCancel={closeModal}
    >
      {remoteRequest?.profile_object && (
        <RemoteCreateConnection
          closeModal={closeModal}
          requestId={remoteRequest.id}
          profile={{
            ...remoteRequest.profile_object,
            start_remote_session: remoteRequest.status === 'allow',
          }}
        />
      )}
    </Modal>
  );
};

export default DirectRemoteConnectModal;
