import React, { useEffect, useMemo, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedSite, selectedSites } from 'sites/store/selectors';
import emptySite from 'sites/util/emptySite';
import styled from 'styled-components';
import { Grid } from '@mss/mss-component-library';
import {
  ComposableMap,
  Geographies,
  Geography,
  Point,
  ZoomableGroup,
} from 'react-simple-maps';
import world from '../../sites/views/world-110m.json';
import { StyledMarker } from '../../sites/components/StyledMarker';
import {
  geographyStyle,
  serializeSitesToMap,
  SiteMarker,
} from '../../sites/views/SiteMap';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MapChart = () => {
  const [markers, setMarkers] = useState<SiteMarker[] | null>(null);
  const [center, setCenter] = useState<Point | null>(null);
  const site = useSelector(selectedSite);
  console.log('site', site, markers);
  useEffect(() => {
    setMarkers(serializeSitesToMap([site]));
    setCenter([parseFloat(site.longitude), parseFloat(site.latitude)]);
  }, [site]);
  console.log(center);
  if (!center) return null;
  return (
    <ComposableMap
      projection="geoMercator"
      className="w-full absolute top-0 left-0"
      width={980}
      height={550}
    >
      <ZoomableGroup center={center} zoom={7} minZoom={7} maxZoom={7}>
        <Geographies geography={world}>
          {({ geographies }) =>
            geographies.map(geo => {
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={geographyStyle}
                />
              );
            })
          }
        </Geographies>
        {(markers ?? []).map(marker => (
          <StyledMarker marker={marker} zoom={5} />
        ))}
      </ZoomableGroup>
    </ComposableMap>
  );
};

function Home() {
  const [t] = useTranslation('common');
  const { siteId } = useParams<{ siteId: string }>();
  const sites = useSelector(selectedSites);

  const selectedSite = useMemo(() => {
    const site = sites.find(x => x.id === siteId);
    if (!site) return emptySite;

    return site;
  }, [sites, siteId]);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Container>
            <h1 className="h3 mb-4 mt-4">
              {t('Site name')}:{' '}
              <span className="text-muted">{selectedSite.name}</span>
            </h1>
            <MapChart />
          </Container>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
}

export default withRouter(Home);
