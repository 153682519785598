import React from 'react';
import { Card } from 'antd';
import Grid from 'components/Grid';
import Loading from 'components/Loading';

type Props = {
  main: React.ReactNode;
  sidebar?: React.ReactNode;
  loading?: boolean;
  actions?: React.ReactNode;
  card?: boolean;
  padding?: boolean;
  cardClassNames?: string;
  breadCrumbs?: React.ReactNode;
};

const Page: React.FC<Props> = ({
  main,
  sidebar,
  actions,
  card = true,
  padding = true,
  cardClassNames,
  loading,
  breadCrumbs,
}) => {
  if (loading) return <Loading />;

  return (
    <Grid.Container>
      {(actions || breadCrumbs) && (
        <Grid.Row>
          <Grid.Col
            width={sidebar ? 10 : undefined}
            className="page-header flex items-center justify-between flex-wrap my-6 mx-0"
          >
            <div className="page-title m-0 text-xl font-normal leading-10">
              {breadCrumbs}
            </div>
            <div className="actions">{actions}</div>
          </Grid.Col>
        </Grid.Row>
      )}

      <Grid.Row>
        <Grid.Col width={sidebar ? 10 : undefined}>
          {card ? (
            <Card
              className={cardClassNames}
              bodyStyle={{ padding: padding ? '1.25rem' : 0 }}
            >
              {main}
            </Card>
          ) : (
            <>{main}</>
          )}
        </Grid.Col>
        {sidebar && <Grid.Col width={2}>{sidebar}</Grid.Col>}
      </Grid.Row>
    </Grid.Container>
  );
};

export default Page;
