import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { AnyAssetApplication, DefaultAssetColumnProps } from '../../../types';
import {
  assetTypeColumn,
  assetTypeLogoColumn,
  custodianColumn,
  ipAddressColumn,
  locationColumn,
  nameColumn,
  operationalStatusColumn,
  roleColumn,
} from 'components/DefaultAssetColumns';

function columns<Asset extends AnyAssetApplication>(
  props: DefaultAssetColumnProps,
  subTypeKey: string
) {
  return [
    operationalStatusColumn<any>(props),
    assetTypeLogoColumn<Asset>(
      props,
      subTypeKey,
      <FontAwesomeIcon icon={faWindows} size="sm" />
    ),
    nameColumn<Asset>(props),
    assetTypeColumn<Asset>(props, subTypeKey),
    roleColumn<Asset>(props),
    custodianColumn<Asset>(props),
    locationColumn<Asset>(props),
    ipAddressColumn<Asset>(props),
  ];
}

export default columns;
