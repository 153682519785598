import styled from 'styled-components';
import { Badge } from 'antd';

// @ts-ignore
export const StyledBadge = styled<any>(Badge)`
  sup.ant-badge-count {
    background: ${p => p.theme.colors.center.primary.main};
    padding: 0 2px;
  }
`;

export default StyledBadge;
