/* eslint-disable @typescript-eslint/camelcase */

import { TFunction } from 'i18next';
import { IconClaroty, IconMcAfee, IconNozomi, IconVeeam, IconWindows } from 'icons';
import IconWug from '../../icons/IconWug';

export function applicationTypeTableLabel(
  t: TFunction,
  application_type: string
): string {
  switch (application_type) {
    case 'mcafee_epo':
      return t('McAfee ePO');
    case 'wsus':
      return t('WSUS');
    case 'veeam':
      return t('Veeam');
    case 'claroty':
      return t('Claroty');
    case 'nozomi':
      return t('Nozomi');
    case 'whatsup_gold':
      return t('WhatsUp Gold');
    default:
      return 'To be implemented';
  }
}

export function applicationTypeFullLabel(
  t: TFunction,
  application_type: string
): string {
  switch (application_type) {
    case 'mcafee_epo':
      return t('McAfee ePolicy Orchestrator');
    case 'wsus':
      return t('Windows Server Update Services');
    case 'veeam':
      return t('Veeam Backup Enterprise Manager');
    case 'claroty':
      return t('Claroty Continuous Threat Detection');
    case 'nozomi':
      return t('Nozomi Guardian');
    case 'whatsup_gold':
      return t('WhatsUp Gold');
    default:
      return 'To be implemented';
  }
}

export function applicationTypeTableIcon(application_type: string): any {
  switch (application_type) {
    case 'mcafee_epo':
      return IconMcAfee;
    case 'wsus':
      return IconWindows;
    case 'veeam':
      return IconVeeam;
    case 'claroty':
      return IconClaroty;
    case 'nozomi':
      return IconNozomi;
    case 'whatsup_gold':
      return IconWug;
    default:
      return IconMcAfee;
  }
}
