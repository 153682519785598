import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import {
  defaultAssetInputs,
  userNamePassDomainInputs,
} from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { calsysConfigureInputs } from './settingsInputs';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';
import {
  AnalzyerManagementCalsysApplication,
  AssetConfig,
} from '../../../../types';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';

export const prmConfig: AssetConfig<AnalzyerManagementCalsysApplication> = {
  name: 'Krohne - Calsys AMADAS',
  type: 'calsys',
  description:
    'Add a Calsys AMADAS server as analyzer assets collector. MSS will use Calsys AMADAS to monitor the connected analyzers.',
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultAssetInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    initialValues: emptyAssetFactory<AnalzyerManagementCalsysApplication>({
      domain: true,
      connectionTypes: ['direct'],
      assetType: 'analyzermanagement_type',
      assetSubType: 'aaims',
      extraFields: {
        database_name: '',
      },
    }),
    configure: {
      inputs: calsysConfigureInputs,
      schema: inputToValidationReducer(calsysConfigureInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassDomainInputs),
        inputs: userNamePassDomainInputs,
        testConnection: {
          customKeyMapping: { ip_address: 'host', db_name: 'database' },
          valueKeys: [
            ...inputsToKeys(userNamePassDomainInputs),
            'ip_address',
            'db_name',
          ],
        },
      },
    },
    collectors: {},
  },
};
