import { defaultAssetInputs } from '../../defaults/inputs';
import { inputOrderSorter } from '../../../util/inputOrderSorter';

export const centumvpConfigureInputs = inputOrderSorter([
  ...defaultAssetInputs.map(x =>
    x.fieldName === 'ip_address'
      ? {
          ...x,
          label: 'Collector IP Address (HIS or HIS/ENG)',
        }
      : x
  ),
  {
    fieldName: 'ip_addresses',
    type: 'hmcg',
  },
  { fieldName: 'timezone', type: 'timezone', required: true },
]);
