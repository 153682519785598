import { SortState } from '../types';
import { antdToEsSort, insertObjIf } from '@mss/mss-component-library';

/** @deprecated */
// This file is deprecated, when every asset/application is moved to a generic asset it should not be used anymore.

export type FilterState<DataKey extends string> = {
  [key in DataKey]: (string | number)[];
};

export type CategoryFilters<DataKey extends string> = {
  [key in DataKey]: { buckets: { key: string }[] };
};

type FilterMatchPhrase = {
  match_phrase: {
    [key: string]: string | number;
  };
};

type Filter = {
  bool: {
    should: FilterMatchPhrase[];
  };
};

export type ElasticFilterQuery = {
  id: string;
  index: string;
  filter: { [key: string]: string[] };
  start: string;
  end: string;
  sort?: Record<string, string>;
  pagination?: { from: number; size: number };
  search?: {
    key: string;
    value: string;
    type: 'fuzzy' | 'wildcard';
    keyword?: boolean;
  }[];
};

function boolShouldMatchPhrase(
  key: string,
  values: (number | string)[],
): Filter {
  const arr = values.map(x => ({
    match_phrase: {
      [key]: x,
    },
  }));

  return {
    bool: {
      should: arr,
    },
  };
}

export function includeFilters(filter: FilterState<string>) {
  return Object.keys(filter)
    .filter(x => filter[x] != null)
    .map(x => boolShouldMatchPhrase(x, filter[x]));
}

export function includeSearch(
  search: {
    key: string;
    value: string;
    type: 'fuzzy' | 'wildcard';
    keyword?: boolean;
  }[],
) {
  return search.map(({ key, value, type = 'fuzzy', keyword }) => ({
    [type]: {
      [`${key}${keyword ? '.keyword' : ''}`]: {
        value: type === 'wildcard' ? `*${value}*` : value,
        ...insertObjIf(type === 'fuzzy', { fuzziness: 'AUTO' }),
        ...insertObjIf(type === 'wildcard', {
          case_insensitive: true,
        }),
      },
    },
  }));
}

export const elasticSorting = (keywords: string[], sorting: SortState) => {
  return {
    [keywords.includes(sorting.columnKey)
      ? `${sorting.columnKey}.keyword`
      : sorting.columnKey]: antdToEsSort(sorting.order),
  };
};
