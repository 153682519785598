import {
  defaultFrontendSort,
  filterSearchColumnElastic,
} from '../../../../../util/elasticColumns';
import { CenterTableColumnsFactory } from '../../../../../../types';
import moment from 'moment';
import React from 'react';
import { T } from 'hooks/useAssetTranslation';

export const columns: CenterTableColumnsFactory = ({
  filtering,
  filterOptions,
}) => {
  if (!filtering || filterOptions === undefined) return [];
  return [
    filterSearchColumnElastic({
      title: <T>Name</T>,
      key: 'json.columns.name',
      filtering,
      filterOptions,
      sorter: defaultFrontendSort('json.columns.name'),
    }),
    filterSearchColumnElastic({
      title: <T>Status</T>,
      key: 'json.columns.enabled_state',
      filtering,
      filterOptions,
      render: (data: string) => <T>{data}</T>,
      sorter: defaultFrontendSort('json.columns.enabled_state'),
    }),
    filterSearchColumnElastic({
      title: <T>Enabled Default</T>,
      key: 'json.columns.enabled_default',
      filtering,
      filterOptions,
      render: (data: string) => <T>{data}</T>,
      sorter: defaultFrontendSort('json.columns.enabled_default'),
    }),
    filterSearchColumnElastic({
      title: <T>Health State</T>,
      key: 'json.columns.health_state',
      filtering,
      filterOptions,
      sorter: defaultFrontendSort('json.columns.health_state'),
    }),
    filterSearchColumnElastic({
      title: <T>Install Date</T>,
      key: 'json.columns.install_date',
      filtering,
      filterOptions,
      sorter: defaultFrontendSort('json.columns.install_date'),
      render: (date: string) =>
        moment(date, 'YYYYMMDDHHmmss.SSSSSSZZ').format('LLLL'),
    }),
    filterSearchColumnElastic({
      title: <T>Last Replication</T>,
      key: 'json.columns.last_replication_time',
      filtering,
      filterOptions,
      sorter: defaultFrontendSort('json.columns.last_replication_time'),
      render: (date: string) =>
        date.startsWith('1601') ? (
          <span style={{ fontStyle: 'italic' }}>
            <T>Replication not enabled</T>
          </span>
        ) : (
          moment(date, 'YYYYMMDDHHmmss.SSSSSSZZ').format('LLLL')
        ),
    }),
  ];
};
