import { ColumnProps } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import get from 'lodash/get';
import { CategoryFilters, FilterState, SortState } from '../../types';
import { Flex, SearchFilterDropdown } from '../../components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export type SearchColumnElasticProps<DataKey extends string> = Partial<
  Omit<ColumnProps<any>, 'key'>
> & {
  key: DataKey;
  filterOptions: CategoryFilters<DataKey> | null;
  sorting?: SortState;
  filtering: FilterState<DataKey>;
  customFilterParser?: (
    acc: ColumnProps<any>['filters'],
    curr: { key: string | boolean }
  ) => ColumnFilterItem[];
  search?: { key: DataKey; value: string }[];
  handleSearch?: (
    typeOfSearch: 'wildcard' | 'fuzzy'
  ) => (selectedKeys: any, confirm: any, dataKey: any) => void;
  handleReset?: (confirm: () => void) => void;
  typeOfSearch?: 'wildcard' | 'fuzzy';
};

export function filterSearchColumnElastic<DataKey extends string>({
  title,
  key,
  filterOptions,
  sorting,
  filtering,
  customFilterParser,
  handleSearch,
  handleReset,
  search,
  typeOfSearch,
  ...rest
}: SearchColumnElasticProps<DataKey>): Omit<
  SearchColumnElasticProps<DataKey>,
  'filtering' | 'filterOptions' | 'handleSearch' | 'handleReset' | 'search'
> {
  const defaultFilterParser = (
    acc: ColumnProps<any>['filters'],
    cur: { key: string }
  ) => {
    if (!acc) return [];
    return [
      ...acc,
      {
        text: cur.key,
        value: cur.key,
      },
    ];
  };
  const hasFilters = filterOptions && filterOptions[key] && !search;

  const enableSearch =
    (hasFilters && filterOptions[key].buckets.length > 5) ?? undefined;

  const filterParsing = customFilterParser ?? defaultFilterParser;
  return {
    title,
    key,
    dataIndex: [...key.split('.')],
    filters: hasFilters
      ? filterOptions[key].buckets.reduce(filterParsing, [])
      : undefined,
    filteredValue: hasFilters ? filtering[key] : undefined,
    filterSearch: enableSearch,
    ...(sorting
      ? {
          sortOrder: sorting.columnKey === key ? sorting.order : undefined,
          sorter: true,
        }
      : {}),
    ...(!!search
      ? {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <SearchFilterDropdown
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              handleSearch={handleSearch?.(typeOfSearch ?? 'fuzzy')}
              handleReset={() => handleReset?.(confirm)}
              dataKey={key}
              visible={false}
            />
          ),
          filterIcon: (
            <Flex h="center" v="center" style={{ height: '100%' }}>
              <FontAwesomeIcon
                color={
                  (search.length > 0 && search[0].key) === key
                    ? '#1890ff'
                    : undefined
                }
                icon={faSearch}
              />
            </Flex>
          ),
        }
      : {}),
    ...rest,
  };
}

type CompareFunction = (
  a: { [key: string]: string },
  b: { [key: string]: string }
) => number;

export function defaultFrontendSort(key: string): CompareFunction {
  return (a, b) => {
    if (typeof get(a, key, '') === 'boolean') {
      return Number(get(a, key, false)) - Number(get(b, key, false));
    }
    return String(get(a, key, '')).localeCompare(String(get(b, key, '')));
  };
}
