import { filterSearchColumnElastic } from '../../../../../util/elasticColumns';
import { CenterTableColumnsFactory } from '../../../../../../types';
import moment from 'moment';
import React from 'react';
import { T } from 'hooks/useAssetTranslation';
import { Tooltip } from 'antd';
import { Copy } from 'react-feather';

export const columns: CenterTableColumnsFactory = ({
  filtering,
  filterOptions,
  sorting,
  search,
  handleSearch,
  handleReset,
}) => {
  if (!filtering || filterOptions === undefined) return [];
  return [
    filterSearchColumnElastic({
      title: <T>Log Level</T>,
      key: 'log.level',
      filtering,
      filterOptions,
      sorting,
    }),
    filterSearchColumnElastic({
      title: <T>ID</T>,
      key: 'winlog.event_id',
      filtering,
      filterOptions,
      sorting,
      search,
      handleSearch,
      handleReset,
      typeOfSearch: 'wildcard',
    }),
    filterSearchColumnElastic({
      title: <T>Date & Time</T>,
      key: 'event.created',
      filtering,
      filterOptions,
      sorting,
      render: (date_time: any) => moment(date_time).format('LLLL'),
    }),
    filterSearchColumnElastic({
      title: <T>Keywords</T>,
      key: 'winlog.keywords',
      filtering,
      filterOptions,
      sorting,
    }),
    filterSearchColumnElastic({
      title: <T>Source</T>,
      key: 'winlog.provider_name',
      filtering,
      filterOptions,
      sorting,
    }),
    {
      key: 'openModal',
      width: 32,
      render: () => (
        <Tooltip title={<T>Show details</T>}>
          <Copy size={14} />
        </Tooltip>
      ),
    },
  ];
};
