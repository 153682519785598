import { AssetPageConfig, SecurityApplication } from '../../../types';
import columns from './columns';
import { mcafeeEpoConfig } from './mcafeeEpo';
import { clarotyConfig } from './claroty';
import { nozomiConfig } from './nozomi';
import { veeamConfig } from './veeam';
import { wsusConfig } from './wsus';
import { wugConfig } from './wug';

export const securityConfiguration: AssetPageConfig<SecurityApplication> = {
  name: 'Security Application',
  licenseModule: 'securityapplications',
  permissions: [
    'securityapplicationmcafeeepo',
    'securityapplicationclaroty',
    'securityapplicationnozomi',
    'securityapplicationveeam',
    'securityapplicationwsus',
    'securityapplicationwhatsupgold',
  ],
  listView: {
    assetType: 'application',
    subTypeKey: 'application_type',
    columns,
  },
  assets: {
    mcafee_epo: mcafeeEpoConfig,
    claroty: clarotyConfig,
    nozomi: nozomiConfig,
    veeam: veeamConfig,
    wsus: wsusConfig,
    whatsup_gold: wugConfig,
  },
};
