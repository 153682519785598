import { Input } from 'antd';
import React, { useRef, useEffect } from 'react';
import Flex from './Flex';

type Props = {
  confirm: any;
  clearFilters: any;
  setSelectedKeys: any;
  selectedKeys: any;
  dataKey: any;
  handleSearch: any;
  handleReset: any;
  visible: boolean;
};

export default function SearchFilterDropdown({
  confirm,
  clearFilters,
  setSelectedKeys,
  selectedKeys,
  dataKey,
  handleSearch,
  handleReset,
  visible,
}: Props) {
  // @ts-ignore
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (visible) {
      inputRef?.current?.select();
    }
  }, [visible]);

  return (
    <div style={{ padding: 8 }}>
      {/* @ts-ignore */}
      <Input
        ref={inputRef}
        placeholder={`Search...`}
        value={Array.isArray(selectedKeys) ? selectedKeys[0] : selectedKeys}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataKey)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Flex h="start" v="center">
        <button
          className="btn btn-sm btn-outline-primary flex items-center"
          onClick={() => handleReset(clearFilters, setSelectedKeys)}
          style={{ width: 90 }}
        >
          Reset
        </button>
        <button
          className="btn btn-sm btn-primary ml-2 flex items-center"
          onClick={() => handleSearch(selectedKeys, confirm, dataKey)}
          style={{ width: 90 }}
        >
          Search
        </button>
      </Flex>
    </div>
  );
}
