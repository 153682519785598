import { defaultAssetInputs } from '../../defaults/inputs';
import { inputOrderSorter } from '../../../util/inputOrderSorter';

export const fcnInputs = inputOrderSorter([
  ...defaultAssetInputs.map(x =>
    x.fieldName === 'ip_address'
      ? { ...x, label: 'Collector IP Address (Stardom Machine)' }
      : x
  ),
  {
    fieldName: 'timezone',
    type: 'timezone',
    required: true,
  },
]);
