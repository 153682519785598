import {
  AssetConfig,
  SecurityClarotyApplication,
  StandardInputConfigs,
} from '../../../../types';
import { defaultSecurityApplicationConfig } from '../default';
import { defaultSecurityInputs } from '../default/settingsInputs';

const baseConfig = defaultSecurityApplicationConfig<SecurityClarotyApplication>(
  {
    name: 'Claroty Continuous Threat Detection',
    type: 'claroty',
    description:
      'Onboard Claroty Continuous Threat Detection (CTD) as security application.',
  },
  defaultSecurityInputs.map(x =>
    x.fieldName === 'api_url'
      ? ({ ...x, label: 'Claroty API URL' } as StandardInputConfigs)
      : x
  )
);

export const clarotyConfig: AssetConfig<SecurityClarotyApplication> = {
  ...baseConfig,
};
