import { useSelector } from 'react-redux';
import { PhaseCategoryEntityAPI } from 'reports/views/Phase';
import { selectedCategory } from 'reports/store/selectors';

function ReportsCategoryBreadcrumb() {
  const selectedPhaseCategory: PhaseCategoryEntityAPI =
    useSelector(selectedCategory);

  return selectedPhaseCategory?.title || null;
}

export default ReportsCategoryBreadcrumb;
