import { createSelector } from 'reselect';
import { Access, ApiSite, Me } from '@mss/mss-component-library';

export type MeCenter = Me & {
  sites: ApiSite[];
};

const selectedNav = state => state.default.settings.nav;
const selectedMe = state => state.default.settings.me;
const chosenLanguages = state => state.default.settings.languages;
const chosenTimezones = state => state.default.settings.timezones;

export const selectNav = createSelector([selectedNav], (nav: Access[]) => nav);
export const selectMe = createSelector(
  [selectedMe],
  (me: MeCenter | null) => me,
);
export const languages = createSelector([chosenLanguages], x => x);
export const timezones = createSelector([chosenTimezones], x => x);
