import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { createFilters, getNested } from 'utils/filtering';

export const useFilters = (
  dataSet: any[],
  filterKeys: string[],
  searchKeys?: string[],
  customFilterLabel?: (key: string, value: string) => string,
  customHeading?: (heading: string) => string
): [
  {
    [key: string]: {
      label: string;
      options: { label: string; value: string }[];
    };
  },
  (key: string, value: string) => void,
  any[],
  { value: string; set: Dispatch<SetStateAction<string>> },
  (filters: { [key: string]: string[] }) => void,
  { [key: string]: string[] }
] => {
  const [enabledFilters, setEnabledFilters] = useState<{
    [key: string]: string[];
  }>({});
  const [searchValue, setSearchValue] = useState<string>('');

  const toggleFiltersOnMount = (filters: { [key: string]: string[] }) => {
    setEnabledFilters(filters);
  };

  const toggleFilter = (key: string, value: string) => {
    const filterExists = enabledFilters[key] != null;
    const index = enabledFilters[key]?.findIndex(x => x === value);
    const newFilters = { ...enabledFilters };

    if (!filterExists || index < 0) {
      if (!filterExists) newFilters[key] = [];
      newFilters[key].push(value);
    } else {
      if (newFilters[key].length === 1) {
        delete newFilters[key];
      } else {
        newFilters[key].splice(index, 1);
      }
    }
    setEnabledFilters(newFilters);
  };

  const matchesFilters = (item: any): boolean =>
    filterKeys.every(key => {
      const filter = enabledFilters[key];
      return !filter || filter.includes(getNested(item, key));
    });

  const matchesSearch = (item: any): boolean => {
    return (
      !searchKeys ||
      !searchValue ||
      searchKeys
        .reduce((acc, key) => `${acc} ${String(getNested(item, key))}`, '')
        .trim()
        .toLowerCase()
        .indexOf(searchValue.toLowerCase()) > -1
    );
  };

  const matchesAllCriteria = (item: any): boolean =>
    matchesFilters(item) && matchesSearch(item);

  const filters = useMemo(
    () => createFilters(filterKeys, dataSet, customFilterLabel, customHeading),
    [dataSet, filterKeys, customFilterLabel, customHeading]
  );

  return [
    filters,
    toggleFilter,
    dataSet.filter(matchesAllCriteria),
    { value: searchValue, set: setSearchValue },
    toggleFiltersOnMount,
    enabledFilters,
  ];
};

export default useFilters;
