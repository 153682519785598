import { AssetPageConfig, EnvAsset } from '../../../types';
import { oduConfig } from './odu';
import columns from './columns';

export const envConfiguration: AssetPageConfig<EnvAsset> = {
  name: 'Environmental Asset',
  licenseModule: 'environmentalassets',
  permissions: ['envassetodu'],
  listView: {
    assetType: 'env',
    subTypeKey: 'env_type',
    columns,
  },
  assets: {
    odu: oduConfig,
  },
};
