import { AssetPageConfig, NetworkAsset } from '../../../types';
import columns from './columns';
import { timeserverConfig } from './timeserver';
import { firewallConfig } from './firewall';
import { routerConfig } from './router';
import { switchConfig } from './switch';
import { TFunction } from 'react-i18next';

export const networkConfig: AssetPageConfig<NetworkAsset> = {
  name: 'Network Asset',
  licenseModule: 'networkassets',
  permissions: [
    'networkassetfirewall',
    'networkassettimeserver',
    'networkassetswitch',
    'networkassetrouter',
  ],
  listView: {
    assetType: 'network',
    subTypeKey: 'network_type',
    columns,
    extraSearchKeys: ['brand_name', 'series_name'],
    extraFilterKeys: [
      {
        key: 'brand_name',
        customHeading: (t: TFunction) => {
          return t('asset:Brand');
        },
      },
      {
        key: 'series_name',
        customHeading: (t: TFunction) => {
          return t('asset:Series');
        },
      },
    ],
  },
  assets: {
    timeserver: timeserverConfig,
    firewall: firewallConfig,
    router: routerConfig,
    switch: switchConfig,
  },
};
