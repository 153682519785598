import { number, object, string } from 'yup';

export const REQUIRED_MSG = 'Required';

export const snmpSchema = object().shape({
  version: number().oneOf([1, 2, 3]).required(REQUIRED_MSG),
  security_level: string().when('version', {
    is: (v: number) => [3].includes(v),
    then: string().required(REQUIRED_MSG),
    otherwise: string().notRequired(),
  }),
  community: string().when('version', {
    is: (v: number) => [1, 2].includes(v),
    then: string().required(REQUIRED_MSG),
    otherwise: string().notRequired(),
  }),
  security_username: string().when('version', {
    is: (v: number) => [3].includes(v),
    then: string().when('security_level', {
      is: (v: string) =>
        [
          'auth_without_privacy',
          'auth_with_privacy',
          'no_auth_or_privacy',
        ].includes(v),
      then: string().required(REQUIRED_MSG),
      otherwise: string().notRequired(),
    }),
    otherwise: string().notRequired(),
  }),
  auth_password: string().when('version', {
    is: (v: number) => [3].includes(v),
    then: string()
      .when('security_level', {
        is: (v: string) =>
          ['auth_without_privacy', 'auth_with_privacy'].includes(v),
        then: string().required(REQUIRED_MSG).nullable(),
        otherwise: string().notRequired(),
      })
      .nullable(),
    otherwise: string().notRequired(),
  }),
  privacy_password: string()
    .when('version', {
      is: (v: number) => [3].includes(v),
      then: string()
        .when('security_level', {
          is: (v: string) => ['auth_with_privacy'].includes(v),
          then: string().required(REQUIRED_MSG).nullable(),
          otherwise: string().notRequired().nullable(),
        })
        .nullable(),
      otherwise: string().notRequired().nullable(),
    })
    .nullable(),
});
