import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import { userNamePassInputs } from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { defaultSecurityInputs } from './settingsInputs';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import {
  AssetConfig,
  BaseAsset,
  PredefinedConfig,
  SecurityApplication,
  SecuritySubType,
  StandardInputConfigs,
} from '../../../../types';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';
import { applicationTypeTableIcon } from '../../../../utils';

export const defaultSecurityApplicationConfig = <
  T extends BaseAsset & { application_type: SecuritySubType }
>(
  config: Pick<
    AssetConfig<T & SecurityApplication>,
    'name' | 'description' | 'type'
  >,
  inputs: (StandardInputConfigs | PredefinedConfig)[]
): AssetConfig<T & SecurityApplication> => ({
  ...config,
  mssIcon: applicationTypeTableIcon(config.type),
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultSecurityInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    initialValues: emptyAssetFactory<SecurityApplication>({
      connectionTypes: ['direct'],
      assetType: 'application_type',
      assetSubType: config.type,
      extraFields: {
        api_url: '',
      },
    }),
    configure: {
      inputs,
      schema: inputToValidationReducer(inputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassInputs),
        inputs: userNamePassInputs,
        testConnection: {
          valueKeys: [...inputsToKeys(userNamePassInputs), 'api_url'],
        },
      },
    },
    collectors: {},
  },
});
