import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ArrowLeft } from 'react-feather';
import { Trans } from 'react-i18next';
import * as Sentry from '@sentry/browser';

type State = {
  hasError: boolean;
};

type ContainerProps = {
  fullscreen?: boolean;
};

const Container = styled.div`
  ${(p: ContainerProps) =>
    p.fullscreen &&
    css`
      display: flex;
      height: 100vh;
      width: 100vw;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`;

class ErrorBoundary extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container fullscreen={true}>
          <div className="display-2 text-muted mb-3">500</div>

          <h1 className="h2 mb-3">
            <Trans ns="common">Oops&hellip; There has been an error</Trans>
          </h1>
          <p className="h4 text-muted font-weight-normal mb-5">
            <Trans ns="common">
              Please try again later or contact the administrator.
            </Trans>
          </p>

          <button
            className="btn btn-primary"
            onClick={() => {
              this.setState({ hasError: false });
              window.location.href = '/';
            }}
          >
            <div className="d-flex align-items-center">
              <ArrowLeft size={18} className="mr-1" />{' '}
              <span>
                <Trans ns="common">Go home</Trans>
              </span>
            </div>
          </button>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
