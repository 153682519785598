import { createSelector } from 'reselect';

const selectedNetworkAssets = state =>
  state.default.networkAssets.networkAssets;
const selectedNetworkAsset = state => state.default.networkAssets.networkAsset;

export const selectNetworkAssets = createSelector(
  [selectedNetworkAssets],
  networkAssets => networkAssets,
);

export const selectNetworkAsset = createSelector(
  [selectedNetworkAsset],
  networkAssets => networkAssets,
);
