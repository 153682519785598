import { Collapse, Input, Spin } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { ColorVariants } from './Button';
import Divider from './Divider';
import FilterSidebar from './FilterSidebar';
import { useTranslation } from 'react-i18next';

const StyledCollapse = styled(Collapse)`
  border: none;
  background: none;

  .ant-collapse-content {
    border: none !important;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse {
    &-item {
      border: none;
      padding: 0;
    }

    &-arrow {
      right: 0px !important;
    }

    &-header {
      cursor: pointer;
      border: none !important;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 !important;
      font-weight: 200;
      text-transform: uppercase;
      font-style: italic;
    }
  }
`;

type PageSidebarProps = {
  loading?: boolean;
  search: {
    value: string;
    set: (value: string) => void | Dispatch<SetStateAction<string>>;
  };
  availableFilters: {
    [key: string]: {
      label: string;
      options: { label: string; value: string }[];
    };
  };
  toggleFilter: (key: string, value: string) => void;
  theming?: ColorVariants;
  selectedFilters?: {
    [key: string]: Array<string>;
  };
  translationPrefix?: Array<string>;
  customOptionLabel?: (key: string, value: any, label?: any) => any;
};

function PageSidebar({
  search,
  availableFilters,
  toggleFilter,
  theming = 'center',
  selectedFilters = {},
  loading = false,
  translationPrefix = [],
  customOptionLabel,
}: PageSidebarProps) {
  const filterKeys = Object.keys(availableFilters);
  const [t] = useTranslation([...translationPrefix, 'common']);

  return (
    <>
      {/* @ts-ignore */}
      <Input
        onChange={({ currentTarget: { value } }) => {
          search.set(value);
        }}
        value={search.value}
        style={{ width: '100%' }}
        placeholder="Search"
      />

      <>
        <Divider />
        {/* @ts-ignore */}
        <Spin spinning={loading}>
          {filterKeys.length === 0 && <div style={{ height: '100px' }}></div>}
          {filterKeys.length > 0 && (
            <StyledCollapse
              expandIconPosition="end"
              defaultActiveKey={Object?.keys(selectedFilters)?.filter(
                key => selectedFilters[key]?.length !== 0
              )}
              style={{ minHeight: '50px' }}
            >
              {filterKeys.map(key => (
                // @ts-ignore
                <StyledCollapse.Panel
                  key={key}
                  header={
                    <span data-ta={`filterPanel-${key}`}>
                      {t(availableFilters[key].label)}
                    </span>
                  }
                >
                  {availableFilters[key].options?.map(x => {
                    const title = customOptionLabel
                      ? customOptionLabel(key, x.value, x.label)
                      : t(x.label);
                    return (
                      <FilterSidebar.Item
                        theming={theming}
                        key={x.value}
                        title={title}
                        onClick={() => {
                          toggleFilter(key, x.value);
                        }}
                        active={
                          selectedFilters[key]?.includes(x.label) ||
                          selectedFilters[key]?.includes(x.value)
                        }
                      />
                    );
                  })}
                </StyledCollapse.Panel>
              ))}
            </StyledCollapse>
          )}
        </Spin>
      </>
    </>
  );
}

export default PageSidebar;
