import { defaultConfig } from '../default';
import { AssetConfig, PlcDcsFCSAsset } from '../../../../types';

const baseFCSConfig = defaultConfig as AssetConfig<PlcDcsFCSAsset>;

export const fcsConfig: AssetConfig<PlcDcsFCSAsset> = {
  ...baseFCSConfig,
  name: 'Yokogawa - Field Control Station (FCS)',
  type: 'fcs',
  description:
    'Onboard a Yokogawa Field Control Station (FCS) via HIS or HIS/ENG.',
  form: {
    ...baseFCSConfig.form,
    initialValues: { ...baseFCSConfig.form.initialValues, dsc_plc_type: 'fcs' },
  },
};
