import { defaultConfig } from '../default';
import { AssetConfig, PlcDcsSCSAsset } from '../../../../types';

const baseSCSConfig = defaultConfig as AssetConfig<PlcDcsSCSAsset>;

export const scsConfig: AssetConfig<PlcDcsSCSAsset> = {
  ...baseSCSConfig,
  name: 'Yokogawa - Safety Control Station (SCS)',
  type: 'scs',
  description:
    'Onboard a Yokogawa Safety Control Station (SCS) via HIS or HIS/ENG.',
  form: {
    ...baseSCSConfig.form,
    initialValues: { ...baseSCSConfig.form.initialValues, dsc_plc_type: 'scs' },
  },
};
