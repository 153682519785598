import React, { useEffect, useMemo, useState } from 'react';
import { ExpandedContentProps } from '../../../../../../types';
import { ElasticQueryParams, useElasticQuery } from 'hooks/useElasticQuery';
import { useAssetParams, useAssetTranslation } from '../../../../../../hooks';
import Grid from 'components/Grid';
import Chart from 'react-apexcharts';
import Loading from '../../../../../../components/Loading';
import { humanBits } from 'utils/humanBits';
import { UseQueryResult } from '@tanstack/react-query';
import Button from '../../../../../../components/Button';
import Flex from '../../../../../../components/Flex';
import { Checkbox } from 'antd';
import Divider from '../../../../../../components/Divider';

type Props = Pick<ExpandedContentProps, 'datePickerSettings'>;

const chartOptions = (categories: any[]) => ({
  chart: {
    stacked: false,
    animations: {
      enabled: true,
    },
    toolbar: {
      show: true,
      tools: { download: false },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
  } as ApexStroke,
  xaxis: {
    type: 'datetime',
    categories,
  } as ApexXAxis,
  yaxis: {
    labels: {
      formatter: (val: number) => humanBits(val * 1000),
    },
  },
  tooltip: {
    shared: false,
    intersect: true,
    x: {
      format: 'dd/MM/yy HH:mm',
    },
  },
  legend: {
    show: false,
  },
  markers: {
    size: 4,
    showNullDataPoints: false,
    hover: { sizeOffset: 4 },
  },
});

const bytesGraphQuery = (
  direction: 'received' | 'sent',
  assetId: string,
  { start, end }: ExpandedContentProps['datePickerSettings']
): ElasticQueryParams => ({
  index: 'virtualization-microsoft-hyper-v-metrics*',
  parameters: [
    {
      elasticQueryKey: 'mss.id',
      value: assetId,
    },
    {
      elasticQueryKey: 'mss.collector_key',
      value: 'network',
    },
  ],
  dataParser: data => {
    const buckets =
      data?.aggregations.bytes_per_minute.buckets.filter(
        (x: any) => x.network_name.buckets.length > 0
      ) ?? [];
    const timestamps = buckets.map((bucket: any) => bucket.key_as_string);
    const adapters = buckets.reduce((acc: any, bucket: any) => {
      bucket.network_name.buckets.forEach((networkBucket: any) => {
        if (!!acc[networkBucket.key]) return acc;
        acc[networkBucket.key] = timestamps.map(() => undefined);
      });
      return acc;
    }, {});

    buckets.forEach((bucket: any, i: number) => {
      const adapterBuckets = bucket.network_name.buckets;
      Object.keys(adapters).forEach(adapter => {
        const adapterBucket = adapterBuckets.find(
          (adapterBucket: any) => adapterBucket.key === adapter
        );
        adapters[adapter][i] = adapterBucket?.bytes.value ?? null;
      });
    });

    return {
      total: 0,
      data: {
        series: Object.keys(adapters).map((name, i) => ({
          color: colors[i % 9],
          name,
          data: adapters[name],
        })),
        categories: timestamps,
      },
    };
  },
  key: `network-${direction}`,
  baseQuery: {
    size: 0,
    query: {},
    aggs: {
      bytes_per_minute: {
        auto_date_histogram: {
          field: '@timestamp',
          buckets: 300,
          minimum_interval: 'minute',
        },
        aggs: {
          network_name: {
            terms: {
              field: 'json.columns.name.keyword',
            },
            aggs: {
              bytes: {
                max: {
                  field: `json.columns.bytes_${direction}_persec`,
                },
              },
            },
          },
          total_bytes: {
            sum_bucket: {
              buckets_path: 'network_name>bytes',
            },
          },
        },
      },
    },
  },
  dateRange: { key: '@timestamp', start, end },
  queryType: 'aggregation',
});

export function NetworkMetrics({ datePickerSettings }: Props) {
  const { assetId } = useAssetParams();

  const queryConfigs = [
    bytesGraphQuery('received', assetId!, datePickerSettings),
    bytesGraphQuery('sent', assetId!, datePickerSettings),
  ];
  const [bytesReceivedQuery, bytesSentQuery] = useElasticQuery(
    queryConfigs,
    queryConfigs.map(() => ({
      refetchInterval: datePickerSettings.isPaused
        ? false
        : datePickerSettings.refreshInterval,
    }))
  );

  return (
    <Grid.Row>
      <BytesChart
        query={bytesReceivedQuery}
        title={'Bytes received per adapter'}
      />
      <BytesChart query={bytesSentQuery} title={'Bytes sent per adapter'} />
    </Grid.Row>
  );
}

type Serie = { name: string; data: any[] };
type BytesChartProps = {
  query: UseQueryResult<{ data: { categories: any; series: Serie[] } }>;
  title: string;
};

const colors = [
  '#e60049',
  '#0bb4ff',
  '#50e991',
  '#e6d800',
  '#9b19f5',
  '#ffa300',
  '#dc0ab4',
  '#b3d4ff',
  '#00bfa0',
];

const BytesChart = ({ query, title }: BytesChartProps) => {
  const { t } = useAssetTranslation();
  const [seriesFilter, setSeriesFilter] = useState<string[] | null>(null);
  const [showFilters, setShowFilters] = useState(false);

  const series = useMemo(
    () =>
      (seriesFilter ? query.data?.data.series ?? [] : []).map(x =>
        seriesFilter?.includes(x.name)
          ? x
          : {
              ...x,
              data: x.data.map(() => null),
            }
      ),
    [seriesFilter, query.data?.data.series]
  );

  useEffect(() => {
    if (query.data?.data.series && !seriesFilter) {
      setSeriesFilter(query.data?.data.series.map(x => x.name));
    }
  });

  function checkAll() {
    setSeriesFilter(query.data?.data.series.map(x => x.name) ?? []);
  }

  function uncheckAll() {
    setSeriesFilter([]);
  }

  return (
    <Grid.Col width={12}>
      <Flex h="between" v="center">
        <h5 className="mt-4">{t(title)}</h5>
        <span>
          <Button
            variant="primary"
            theming={'center'}
            colorOverrides={{ border: 'transparent' }}
            ghost
            onClick={() => setShowFilters(x => !x)}
          >
            {t('common:Filter')}
          </Button>
        </span>
      </Flex>
      {showFilters && seriesFilter && (
        <>
          <div className="mt-4">
            <Button
              ghost={false}
              theming="center"
              className="mr-2"
              onClick={checkAll}
            >
              {t('common:Check all')}
            </Button>
            <Button ghost={false} theming="center" onClick={uncheckAll}>
              {t('common:Uncheck all')}
            </Button>
          </div>
          <Divider />
          <Grid.Row>
            {series.map(x => (
              <Grid.Col
                width={6}
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflowX: 'hidden',
                  marginBottom: 2,
                }}
              >
                <Checkbox
                  className="mr-2"
                  checked={seriesFilter.includes(x.name)}
                  onChange={val => {
                    if (val.target.checked) {
                      setSeriesFilter(s => [...(s ?? []), x.name]);
                    } else {
                      setSeriesFilter(s => (s ?? []).filter(y => y !== x.name));
                    }
                  }}
                >
                  {x.name}
                </Checkbox>
              </Grid.Col>
            ))}
          </Grid.Row>
        </>
      )}
      {query.isLoading && <Loading style={{ height: 400 }} />}
      {!query.isLoading && query.data?.data.series && (
        <Chart
          options={{
            ...chartOptions(query.data?.data.categories ?? []),
            colors,
          }}
          series={series}
          type="line"
          height="400"
        />
      )}
    </Grid.Col>
  );
};
