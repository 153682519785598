import { defaultAssetInputs } from '../../defaults/inputs';
import { inputOrderSorter } from '../../../util/inputOrderSorter';
import { commonInputConfigs } from '../../defaults/commonInputConfigs';

export const wsusConfigureInputs = inputOrderSorter([
  ...defaultAssetInputs,
  {
    type: 'string',
    fieldName: 'database_ip',
    required: true,
    isIP: true,
    label: 'WSUS Database IP',
  },
  commonInputConfigs.port({
    fieldName: 'database_port',
    required: true,
    label: 'WSUS Database Port',
  }),
]);
