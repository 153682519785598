import {
  AssetConfig,
  SecurityNozomiApplication,
  StandardInputConfigs,
} from '../../../../types';
import { defaultSecurityApplicationConfig } from '../default';
import { defaultSecurityInputs } from '../default/settingsInputs';

const baseConfig = defaultSecurityApplicationConfig<SecurityNozomiApplication>(
  {
    name: 'Nozomi Guardian',
    type: 'nozomi',
    description: 'Onboard Nozomi Guardian as security application.',
  },
  defaultSecurityInputs.map(x =>
    x.fieldName === 'api_url'
      ? ({ ...x, label: 'Nozomi API URL' } as StandardInputConfigs)
      : x
  )
);

export const nozomiConfig: AssetConfig<SecurityNozomiApplication> = {
  ...baseConfig,
};
