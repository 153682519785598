import { defaultConfig } from '../default';
import { AssetConfig, PlcDcsBCVAsset } from '../../../../types';

const baseBCVConfig = defaultConfig as AssetConfig<PlcDcsBCVAsset>;

export const bcvConfig: AssetConfig<PlcDcsBCVAsset> = {
  ...baseBCVConfig,
  name: 'Yokogawa - Bus Converter (BCV)',
  type: 'bcv',
  description: 'Onboard a Yokogawa Bus Converter (BCV) via HIS or HIS/ENG.',
  form: {
    ...baseBCVConfig.form,
    initialValues: { ...baseBCVConfig.form.initialValues, dsc_plc_type: 'bcv' },
  },
};
