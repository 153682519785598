import Loading from 'common/components/Loading';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Route as RouterRoute } from 'react-router-dom';

export const Route = ({ middlewares = [], ...props }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    middlewares.forEach(middleware => middleware(history));

    setLoading(false);
  }, [history, middlewares]);

  if (loading) return <Loading />;

  return <RouterRoute {...props} />;
};

export default Route;
