import { AnyAssetSubType, AssetTypeURI } from '../../types';

export const assetTypeToBreadcrumb: Record<AssetTypeURI, string> = {
  security: 'Security applications',
  control: 'Control applications',
  assetmanagement: 'Asset management applications',
  analyzermanagement: 'Analyzer management applications',
  virtualization: 'Virtualization applications',
  compute: 'Compute assets',
  field: 'Field assets',
  plcdcs: 'PLC/DCS assets',
  env: 'Environmental assets',
  network: 'Network assets',
};

export const assetSubTypeToBreadcrumb: Record<AnyAssetSubType, string> = {
  prm: 'Yokogawa - Plant Resource Manager (PRM)',
  isae: 'Yokogawa - InsightSuite AE (ISAE)',
  aaims: 'Yokogawa - Analyzer Management Interface (AAIMS)',
  calsys: 'Krohne - Calsys AMADAS',
  microsoft_hyper_v: 'Microsoft Hyper-V',
  mcafee_epo: 'McAfee ePolicy Orchestrator',
  wsus: 'Windows Server Update Services',
  veeam: 'Veeam Backup Enterprise Manager',
  claroty: 'Claroty Continuous Threat Detection',
  nozomi: 'Nozomi Guardian',
  whatsup_gold: 'WhatsUp Gold',
  centum_vp: 'Yokogawa Centum VP',
  field: 'Field',
  windows_wmi: 'Windows WMI',
  windows_agent: 'Windows Agent',
  fcs: 'Yokogawa - Field Control Station (FCS)',
  scs: 'Yokogawa - Safety Control Station (SCS)',
  avr: 'Yokogawa - Vnet Router (AVR)',
  bcv: 'Yokogawa - Bus Convertor (BCV)',
  wac: 'Yokogawa - Vnet/IP WAC',
  fcn: 'Yokogawa - Field Control Node (FCN)',
  switch: 'Switch',
  router: 'Router',
  firewall: 'Firewall',
  timeserver: 'Time Server',
  odu: 'Yokogawa - Online Diagnosis Unit (ODU)',
};
