import React from 'react';
import Flex from 'components/Flex';
import Loading from 'components/Loading';
import { SandboxStatusType, SandboxType } from 'types';
import SandboxIcon from 'icons/SandboxIcon';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

type Props = {
  sandboxType: SandboxType;
  pod: SandboxStatusType;
};

const podCondition = (pod: SandboxStatusType) => {
  let status = pod.status;
  const condition = pod.container_status?.status || null;
  if (pod.condition) status = pod.condition;

  return (
    <>
      <pre>
        {status.toLowerCase()}
        {condition ? ` | ${condition}` : ``}
      </pre>
      {pod.container_status?.data?.message ? (
        <Alert
          message={pod.container_status.data.message}
          description={pod.container_status.data?.reason}
          type="warning"
          style={{ width: '37%' }}
        />
      ) : null}
    </>
  );
};

function SandboxLoader({ pod, sandboxType }: Props) {
  const [t] = useTranslation(['common', 'remoteAccess']);

  return (
    <Flex v={'center'} h={'center'} column style={{ height: '100vh' }}>
      <h2>{t('Loading Web Browser')}</h2>
      <SandboxIcon sandboxType={sandboxType} width={250} height={250} />
      <h3>
        {t('Creating sandbox environment')} <Loading />
      </h3>
      {podCondition(pod)}
    </Flex>
  );
}

export default SandboxLoader;
