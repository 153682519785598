import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AssetParams, useAssetParams } from './useAssetParams';
import { useLocation } from 'react-router-dom';
import { defaultResponseHandler, Fetch } from 'utils/_fetch';
import { createUrl } from 'utils/createAssetApiUrl';
import { AnyAssetApplication, APIAsset } from '../types';
import { useAssetConfig } from 'configs/ConfigProvider';

export function useAssetQuery<AssetType extends AnyAssetApplication>(
  disabled?: boolean,
  paramsOverride?: Partial<AssetParams>,
  options?: UseQueryOptions<any>,
  useGeneric?: boolean
) {
  const params = { ...useAssetParams(), ...paramsOverride };
  const { assetType, assetId, assetSubType, siteId } = params;
  const { t } = useTranslation(['common', 'asset']);
  const { matchingConfig } = useAssetConfig(assetSubType);
  const queryKey = [
    assetType,
    assetSubType,
    assetId,
    siteId,
    useGeneric ? 'generic' : 'specific',
  ];
  const isSettingsPage = useLocation().pathname.includes('asset-settings');
  const query = useQuery({
    queryKey,
    ...(isSettingsPage
      ? {
          refetchOnWindowFocus: false,
          refetchOnMount: true,
          refetchInterval: Infinity,
          staleTime: Infinity,
          cacheTime: Infinity,
        }
      : {
          staleTime: 5000,
          cacheTime: 1000 * 60,
          refetchInterval: 1000 * 30,
          refetchOnMount: true,
        }),
    enabled: disabled || (!!assetType && !!assetSubType && !!assetId),
    queryFn: async () => {
      const { error, data } = await defaultResponseHandler<
        APIAsset<AssetType>[]
      >({
        t,
        request: Fetch(siteId).get(
          createUrl(
            { assetType, assetSubType, assetId, siteId },
            undefined,
            useGeneric,
            useGeneric
          )
        ),
      });
      if (error) return undefined;
      const asset = Array.isArray(data) ? data[0] : data;
      return (matchingConfig?.form.fetchDataParser?.(asset) ||
        asset) as APIAsset<AssetType>;
    },
    ...(options ?? {}),
  });

  return {
    ...query,
    queryKey,
  };
}
