import Table from 'antd/es/table';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';

const FileTransferTable: StyledComponent<any, DefaultTheme, {}, never> = styled(
  Table
)`
  &.ant-table,
  * .ant-table {
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }
    overflow-x: scroll;
  }
  + ul.ant-table-pagination.ant-pagination {
    float: left;
    margin: 16px;
  }

  .ant-pagination-item-link {
    svg {
      vertical-align: unset;
    }
  }
`;
export default FileTransferTable;
