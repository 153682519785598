import 'core-js/stable/object/has-own';
import { useEffect, useRef } from 'react';

import { HttpRequest } from 'tus-js-client';
import type { UppyFile } from '@uppy/core';
import Uppy from '@uppy/core';

import Tus from '@uppy/tus';
import DropTarget from '@uppy/drop-target';

import { getAuthHeader, getSiteHeader } from 'utils';
import { Site } from 'types';

type UseUppyArgs = {
  factory: () => Uppy;
  site?: Site;
};

/**
 * Uppy hook.
 *
 * Before every request the current openid auth token is set in the headers.
 *
 */
export function useUppy({ factory, site }: UseUppyArgs) {
  function tusfactory(uppy: Uppy) {
    return uppy
      .use(Tus, {
        endpoint: '/api/file-transfer/upload/',
        chunkSize: 1023 * 1024 * 4, // 4MB - 1KB
        removeFingerprintOnSuccess: true,
        limit: 1,
        retryDelays: [
          0, 1000, 1000, 1000, 1000, 2000, 2000, 2000, 2000, 2000, 2000, 5000,
          5000, 5000, 8000, 8000, 13000, 21000, 34000, 55000,
        ],
        headers: { ...getSiteHeader(site?.id) },
        // @ts-ignore
        onBeforeRequest: (req: HttpRequest) => {
          req.setHeader('Authorization', getAuthHeader()?.Authorization);
        },
      })
      .use(DropTarget, { target: document.body })
      .on('file-added', (file: UppyFile) => {
        uppy.setFileMeta(file.id, { fingerprint: file.id });
      })
      .on('upload-retry', () => {
        console.log('BACK ONLINE!');
        uppy.retryAll();
      });
  }

  const uppyRef = useRef<Uppy | undefined>(undefined);
  // Make sure we only initialize it the first time:
  if (uppyRef.current === undefined) {
    uppyRef.current = tusfactory(factory());
  }

  /**
   * Cleanup handler
   */
  useEffect(() => {
    if (uppyRef.current !== undefined) {
      return () => {
        // console.log('Close uppy instance');
        uppyRef.current && uppyRef.current.close();
      };
    }
  }, []);

  return uppyRef.current;
}

export default useUppy;
