import React from 'react';
// import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { useTranslation } from 'react-i18next';
import { File } from 'react-feather';
import { SandboxAction, SandboxApiType } from 'apps/services';

type Props = {
  api: SandboxApiType;
};
export default function FileTransferMenu({ api }: Props) {
  const [t] = useTranslation(['common', 'remoteAccess']);
  // const [loading, setLoading] = useState<boolean>(false);

  const sandboxExec = async (action: SandboxAction) => {
    try {
      await api.exec(action);
    } catch (e) {
      console.error('Error executing action in sandbox', e);
    }
  };

  return (
    <span>
      {/* <AntButton
        ghost={false}
        style={{
          borderColor: '#fff',
          backgroundColor: 'transparent',
          position: 'relative',
        }}
        onClick={() => sandboxExec('xterm')}
      >
        <Terminal
          style={{ stroke: '#fff' }}
          size={16}
          xlinkTitle={t('Terminal')}
        />
      </AntButton>{' '} */}
      <AntButton
        ghost={false}
        style={{
          borderColor: '#fff',
          backgroundColor: 'transparent',
          position: 'relative',
        }}
        onClick={() => sandboxExec('site-tab-filetransfer')}
      >
        <File
          style={{ stroke: '#fff' }}
          size={16}
          xlinkTitle={t('FileTransfer')}
        />
      </AntButton>
    </span>
  );
}
