import { createSelector } from 'reselect';
import {
  PhaseCategoryEntityAPI,
  SubcategoryAlarmsEntityAPI,
} from 'reports/views/Phase';
import { ReportEntityAPI } from 'reports/views/List';

const selectReport = state => state.default.reports.selectedReport;
const selectCategories = state => state.default.reports.categories;
const selectCategory = state => state.default.reports.selectedCategory;
const selectSubcategory = state => state.default.reports.selectedSubcategory;

export const selectedReport = createSelector(
  [selectReport],
  (selectedReport: ReportEntityAPI) => selectedReport,
);

export const categories = createSelector(
  [selectCategories],
  (categories: PhaseCategoryEntityAPI[]) => categories,
);

export const selectedCategory = createSelector(
  [selectCategory],
  (selectedCategory: PhaseCategoryEntityAPI) => selectedCategory,
);

export const selectedSubcategory = createSelector(
  [selectSubcategory],
  (selectedSubcategory: SubcategoryAlarmsEntityAPI) => selectedSubcategory,
);
