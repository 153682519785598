import { defaultAssetInputs } from '../../defaults/inputs';
import { inputOrderSorter } from '../../../util/inputOrderSorter';

export const defaultPlcInputs = inputOrderSorter([
  ...defaultAssetInputs.map(x =>
    x.fieldName === 'ip_address'
      ? { ...x, label: 'Collector IP Address (HIS or HIS/ENG)' }
      : x
  ),
  {
    fieldName: 'domain_number',
    type: 'number',
    required: true,
    isInt: true,
    label: 'Domain number',
  },
  {
    fieldName: 'station_number',
    type: 'number',
    required: true,
    isInt: true,
    label: 'Station number',
  },
]);
