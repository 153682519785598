import { Field, FieldProps } from 'formik';
import React from 'react';
import Button, { ColorVariants } from './Button';

export const SubmitButton = ({
  children,
  theming,
  ...restProps
}: any & { theming: ColorVariants }) => (
  <Field>
    {({ form: { isSubmitting, isValid, dirty, submitCount } }: FieldProps) => (
      <Button
        theming={theming}
        loading={isSubmitting}
        variant="primary"
        htmlType="submit"
        disabled={!isValid && (dirty || submitCount > 0)}
        data-ta="submitButton"
        {...restProps}
      >
        {children}
      </Button>
    )}
  </Field>
);

export default SubmitButton;
