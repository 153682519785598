import { isaeConfig } from './isae';
import columns from './columns';
import { prmConfig } from './prm';
import { AssetManagementApplication, AssetPageConfig } from '../../../types';

export const assetManagementConfiguration: AssetPageConfig<AssetManagementApplication> =
  {
    name: 'Asset Management Application',
    licenseModule: 'fieldassetprm',
    permissions: [
      'assetmanagementapplicationisae',
      'assetmanagementapplicationprm',
    ],
    listView: {
      assetType: 'asset_management',
      subTypeKey: 'assetmanagement_type',
      columns,
    },
    assets: {
      isae: isaeConfig,
      prm: prmConfig,
    },
  };
