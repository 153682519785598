import {
  BaseInputConfig,
  NumberConfig,
  PredefinedConfig,
  StringConfig,
  TextConfig,
} from '../../../types';

export const commonInputConfigs = {
  name: (props?: Partial<StringConfig>): StringConfig => ({
    required: true,
    type: 'string',
    label: 'Asset Name',
    fieldName: 'name',
    placeholder: 'Name of the asset',
    maxLength: 50,
    ...(props ?? {}),
  }),
  role: (props?: Partial<BaseInputConfig>): PredefinedConfig => ({
    required: true,
    type: 'role',
    fieldName: 'role',
    ...(props ?? {}),
  }),
  custodian: (props?: Partial<BaseInputConfig>): PredefinedConfig => ({
    required: true,
    type: 'custodian',
    fieldName: 'custodian',
    ...(props ?? {}),
  }),
  ip_address: (props?: Partial<StringConfig>): StringConfig => ({
    required: true,
    type: 'string',
    label: 'IP Address',
    fieldName: 'ip_address',
    maxLength: 39,
    isIP: true,
    ...(props ?? {}),
  }),
  port: (props?: Partial<NumberConfig>): NumberConfig => ({
    required: true,
    type: 'number',
    label: 'Port',
    fieldName: 'port',
    min: 1,
    max: 65535,
    isInt: true,
    ...(props ?? {}),
  }),
  criticality: (props?: Partial<BaseInputConfig>): PredefinedConfig => ({
    required: true,
    type: 'criticality',
    fieldName: 'priority',
    ...(props ?? {}),
  }),
  location: (props?: Partial<BaseInputConfig>): PredefinedConfig => ({
    required: true,
    type: 'location',
    fieldName: 'location',
    ...(props ?? {}),
  }),
  description: (props?: Partial<TextConfig>): TextConfig => ({
    type: 'text',
    label: 'Description',
    fieldName: 'description',
    width: 12,
    rows: 4,
    placeholder:
      'If required, you can enter a description about the asset in here...',
    ...(props ?? {}),
  }),
};
