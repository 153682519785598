import { useLocation, useRouteMatch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useEffect, useState } from 'react';

// interface UsePageQuery

export function usePageQuery(
  totalPages: number,
  customUrl?: string
): [number, (number: number) => void] {
  const pages = Math.ceil(totalPages);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [page, setPage] = useState<number>(parseInt(params.get('page') || '1'));
  const history = createBrowserHistory();
  const { url } = useRouteMatch();

  const sanitizedSetPage = (newPage: number) => {
    if (newPage !== page) setPage(newPage);
  };

  useEffect(() => {
    if (!pages && page !== 1) {
      setPage(1);
      return;
    }
    if ((pages && page - 1 > pages) || isNaN(page) || page < 1) setPage(1);

    const hasPage = params.has('page');

    params.delete('token');
    params.delete('uid');

    const paramsModifier = hasPage ? 'set' : 'append';
    params[paramsModifier]('page', `${page}`);

    const historyModifier = 'replace';

    history[historyModifier](`${customUrl || url}?${params.toString()}`);
  }, [history, page, params, url, pages, customUrl]);

  return [page, sanitizedSetPage];
}

export default usePageQuery;
