import React from 'react';
import {
  AnyAssetApplication,
  AnyAssetSubType,
  AssetConfig,
  AssetPageConfig,
} from '../types';

export const ConfigContext = React.createContext<AssetPageConfig<any>>(
  {} as AssetPageConfig<any>
);
type ContextProps<AssetType extends AnyAssetApplication> =
  React.PropsWithChildren<{
    value: AssetPageConfig<AssetType>;
  }>;

export function ConfigProvider<AssetType extends AnyAssetApplication>({
  children,
  value,
}: ContextProps<AssetType>) {
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}

export function useAssetConfig<AssetType extends AnyAssetApplication>(
  assetSubType: AnyAssetSubType
): {
  config: AssetPageConfig<AssetType>;
  matchingConfig: AssetConfig<AssetType>;
};
export function useAssetConfig<AssetType extends AnyAssetApplication>(): {
  config: AssetPageConfig<AssetType>;
};
export function useAssetConfig<AssetType extends AnyAssetApplication>(
  assetSubType?: AnyAssetSubType
) {
  const config = React.useContext<AssetPageConfig<AssetType>>(ConfigContext);

  if (!assetSubType) return { config };

  const matchingConfig = config.assets?.[assetSubType];

  return { config, matchingConfig };
}
