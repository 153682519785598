import { Trans, useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';
import { useCallback } from 'react';
import React from 'react';

const namespaces = ['asset', 'common', 'validation'];

export function useAssetTranslation(ns?: string[]) {
  const { t, ...rest } = useTranslation([...namespaces, ...(ns ?? [])]);
  const escapedT = useCallback(
    (key: string | string[], options?: TOptions<any> | string) =>
      t(key, {
        interpolation: { escapeValue: false, ...options?.interpolation },
        ...options,
      }),
    [t]
  );

  return { t: escapedT, ...rest };
}

export function T({ ns, children }: { ns?: string[]; children: string }) {
  return <Trans ns={[...namespaces, ...(ns ?? [])]}>{children}</Trans>;
}
