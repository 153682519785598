import { createAction, createSlice } from '@reduxjs/toolkit';

export const fetchCategoriesRequested = createAction<string>(
  'reports/fetchCategoriesRequested',
);
export const fetchCategoriesFailed = createAction(
  'reports/fetchCategoriesFailed',
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    selectedReport: {},
    categories: [],
    selectedCategory: {},
    selectedSubcategory: {},
  },
  reducers: {
    selectReport(state, action) {
      return {
        ...state,
        selectedReport: action.payload,
      };
    },
    fetchCategoriesCompleted(state, action) {
      return {
        ...state,
        categories: action.payload,
      };
    },
    selectCategory(state, action) {
      return { ...state, selectedCategory: action.payload };
    },
    selectSubcategory(state, action) {
      return { ...state, selectedSubcategory: action.payload };
    },
  },
});

export const {
  fetchCategoriesCompleted,
  selectCategory,
  selectSubcategory,
  selectReport,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
