import { Dropdown, Menu, Modal, notification, DatePicker } from 'antd';
import React, { useState } from 'react';
import { openNotificationWithIcon } from 'utils';
import { useUserinfo } from 'components/UserProvider';
import { FormGroup } from 'components';

import { FileTransfer } from 'types';
import moment from 'moment';

import { MoreVertical } from 'react-feather';
import styled from 'styled-components';
import { Button, Flex } from 'components';
import { RowActionButton } from 'components';
import { FileTransferApiType } from '../services';
import dateFormat from 'dateformat';
import { TFunction } from 'i18next';

export const MenuItem = styled(Menu.Item)`
  padding: 8px 24px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

type ActionMenuProps = {
  t: TFunction;
  fileTransfer: FileTransfer;
  updateFileTransfer: (fileTransfer: FileTransfer) => void;
  api: FileTransferApiType;
  setDetailModel: (fileTransfer: FileTransfer | null) => void;
  isUploading?: boolean;
};

export default function FileTransferActionMenu({
  t,
  fileTransfer,
  updateFileTransfer,
  api,
  setDetailModel,
  isUploading,
}: ActionMenuProps) {
  const [isOpen, setOpen] = useState(false);
  // const [logsModal, setLogsModal] = useState(false);
  // const [expiryDate, setExpiryDate] = useState(null);

  const [expireDateModal, setExpireDateModal] = useState<string | null>(null);
  const [hasPermission, , user] = useUserinfo();

  const addPermission = api.isCenter
    ? 'add_center_filetransfer'
    : 'add_filetransfer';

  return (
    <>
      <Dropdown
        trigger={['click']}
        disabled={!!isUploading}
        overlay={
          // @ts-ignore
          <Menu style={{ minWidth: 192 }}>
            <MenuItem
              key={'share_filetransfer'}
              disabled={
                !hasPermission('share_filetransfer') ||
                (fileTransfer.user !== user?.id && !user?.is_superuser)
              }
              onClick={async () => {
                const isPublic = !fileTransfer.is_public;
                const id = fileTransfer.id;
                try {
                  const result = await api.update({ id, isPublic });
                  updateFileTransfer(result);
                  openNotificationWithIcon(
                    notification.success,
                    t('Success'),
                    <>
                      {result.is_public ? t('File shared') : t('File unshared')}
                    </>
                  );
                } catch (e) {
                  openNotificationWithIcon(
                    notification.error,
                    t('Error'),
                    String(e)
                  );
                }
              }}
            >
              <>{fileTransfer.is_public ? t('Unshare') : t('Share')}</>
            </MenuItem>
            <MenuItem
              key={'expire_filetransfer'}
              disabled={
                !hasPermission('expire_filetransfer') ||
                (fileTransfer.user !== user?.id && !user?.is_superuser)
              }
              onClick={() => setExpireDateModal(fileTransfer.expires_at)}
            >
              <>{t('Set expire date')}</>
            </MenuItem>
            <MenuItem
              key={'delete_filetransfer'}
              disabled={
                !hasPermission(addPermission) ||
                (fileTransfer.user !== user?.id && !user?.is_superuser)
              }
              // icon={<Trash size={14} />}
              onClick={async () => {
                try {
                  await api.destroy(fileTransfer);
                  fileTransfer.deleted = true;
                  updateFileTransfer(fileTransfer);
                  openNotificationWithIcon(
                    notification.success,
                    t('File deleted'),
                    fileTransfer.file_name
                  );
                } catch (e) {
                  openNotificationWithIcon(
                    notification.error,
                    t('Error'),
                    `${e}`
                  );
                }
              }}
            >
              <>{t('Delete')}</>
            </MenuItem>
            <MenuItem
              key={'details_filetransfer'}
              disabled={!hasPermission(addPermission)}
              // icon={<Trash size={14} />}
              onClick={() => setDetailModel(fileTransfer)}
            >
              <>{t('Details')}</>
            </MenuItem>
            <MenuItem
              key={'scan_filetransfer'}
              disabled={
                !hasPermission(addPermission) ||
                (fileTransfer.user !== user?.id && !user?.is_superuser)
              }
              // icon={<Trash size={14} />}
              onClick={async () => {
                try {
                  await api.scan(fileTransfer);
                  updateFileTransfer(fileTransfer);
                  openNotificationWithIcon(
                    notification.success,
                    t('File scan queued'),
                    fileTransfer.file_name
                  );
                } catch (e) {
                  openNotificationWithIcon(
                    notification.error,
                    t('Error scanning file'),
                    `${e}`
                  );
                }
              }}
            >
              <>{t('Re-scan for virus')}</>
            </MenuItem>
          </Menu>
        }
      >
        <RowActionButton
          onClick={() => setOpen(!isOpen)}
          shape="circle"
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 0,
          }}
        >
          <MoreVertical size="18" />
        </RowActionButton>
      </Dropdown>
      <Modal
        title={<>{t('Set expire date')}</>}
        footer={[
          <Flex h="end" key="closeBtn">
            <Button
              theming={api.appContext}
              variant={'primary'}
              ghost
              onClick={() => setExpireDateModal(null)}
            >
              <>{t('common:Close')}</>
            </Button>
          </Flex>,
        ]}
        open={!!expireDateModal}
        closable={false}
      >
        <FormGroup
          label={''}
          input={
            // @ts-ignore
            <DatePicker
              showToday={false}
              allowClear={false}
              disabledDate={(d: any) =>
                !d || d.isBefore(moment().add(0, 'day'))
              }
              onChange={async value => {
                setExpireDateModal(value ? value.toISOString() : null);

                try {
                  const result = await api.update({
                    id: fileTransfer.id,
                    expiresAt: value?.toISOString(),
                  });
                  updateFileTransfer(result);
                  openNotificationWithIcon(
                    notification.success,
                    t('Expiry date updated'),
                    <p>
                      <>
                        {t('File will be expired at {{date}}', {
                          date: dateFormat(
                            result.expires_at || '',
                            'mmmm dS, yyyy'
                          ),
                        })}
                      </>
                    </p>
                  );
                } catch (e) {
                  openNotificationWithIcon(
                    notification.error,
                    t('Error'),
                    String(e)
                  );
                } finally {
                  setExpireDateModal(null);
                }
                return true;
              }}
              style={{ minWidth: '100%' }}
              placeholder={t('Set expire date')}
              defaultValue={moment(expireDateModal)}
            />
          }
        />
      </Modal>
    </>
  );
}
