import React from 'react';
import styled from 'styled-components';

type Props = {
  vertical?: boolean;
};

const Vertical = styled.div`
  width: 1px;
  height: 38px;
  background: #d8d8d8;
  margin-right: 1.4rem;
  margin-left: 0rem;
`;

const Horizontal = styled.div`
  margin: 1rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
`;

const Divider = ({ vertical }: Props) =>
  vertical ? <Vertical /> : <Horizontal />;

export default Divider;
