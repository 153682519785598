import { createSlice, createAction } from '@reduxjs/toolkit';

export const fetchAnalyzerManagementApplicationsRequested = createAction<{
  query?: object;
}>(
  'analyzerManagementApplications/fetchAnalyzerManagementApplicationsRequested',
);

export const fetchAnalyzerManagementApplicationsFailed = createAction(
  'analyzerManagementApplications/fetchAnalyzerManagementApplicationsFailed',
);

export const fetchAnalyzerManagementApplicationRequested = createAction<string>(
  'analyzerManagementApplications/fetchAnalyzerManagementApplicationRequested',
);

export const fetchAnalyzerManagementApplicationFailed = createAction(
  'analyzerManagementApplications/fetchAnalyzerManagementApplicationFailed',
);

export const fetchAnalyzerManagementApplicationsByIdRequested = createAction(
  'analyzerManagementApplications/fetchAnalyzerManagementApplicationsByIdRequested',
);

export const fetchAnalyzerManagementApplicationsByIdFailed = createAction(
  'analyzerManagementApplications/fetchAnalyzerManagementApplicationsByIdFailed',
);

const analyzerManagementApplications = createSlice({
  name: 'analyzerManagementApplications',
  initialState: {
    analyzerManagementApplication: {},
    analyzerManagementApplications: [],
    analyzerManagementApplicationsById: {},
    total: 0,
  },
  reducers: {
    fetchAnalyzerManagementApplicationsCompleted(state, action) {
      state.analyzerManagementApplications = action.payload;
    },
    fetchAnalyzerManagementApplicationCompleted(state, action) {
      state.analyzerManagementApplication = action.payload;
    },
    fetchAnalyzerManagementApplicationsByIdCompleted(state, action) {
      state.analyzerManagementApplicationsById = action.payload.reduce(
        (assetApp, current) => ({ ...assetApp, [current.id]: current }),
        {},
      );
    },
  },
});

export const {
  fetchAnalyzerManagementApplicationsCompleted,
  fetchAnalyzerManagementApplicationCompleted,
  fetchAnalyzerManagementApplicationsByIdCompleted,
} = analyzerManagementApplications.actions;

export default analyzerManagementApplications.reducer;
