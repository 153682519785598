import { defaultFrontendSort } from '../../../../../util/elasticColumns';
import { CenterTableColumnsFactory } from '../../../../../../types';
import { BooleanRender } from '../../../../defaults/defaultQueryHelpers';
import get from 'lodash/get';
import React from 'react';
import { T } from 'hooks/useAssetTranslation';

const render = (data: string) => (!data ? '-' : `${data} MB`);
const labels = { true: 'Enabled', false: 'Disabled' };
const baseColumns = [
  { key: 'json.columns.assigned_memory', title: 'Assigned Memory', width: 140 },
  { key: 'json.columns.memory_demand', title: 'Memory demand', width: 130 },
  { key: 'json.columns.startup_memory', title: 'Startup Memory', width: 125 },
  { key: 'json.columns.minimum_memory', title: 'Min. Memory', width: 110 },
  { key: 'json.columns.maximum_memory', title: 'Max. Memory', width: 110 },
];

export const columns: CenterTableColumnsFactory = ({
  dataSource: _dataSource,
  filtering,
}) => {
  const dataSource = _dataSource || [];

  const nameFilters = dataSource.map((data: any) => {
    const name = get(data, 'json.columns.name', '');
    return {
      text: name,
      value: name,
    };
  });

  const dynamicMemoryFilters = dataSource.map((data: any) => {
    const bool = get(data, 'json.columns.dynamic_memory', false);
    return {
      value: bool,
      text: (<BooleanRender value={bool} labels={labels} />) as any,
    };
  });

  return [
    {
      key: 'json.columns.name',
      title: <T>Name</T>,
      dataIndex: ['json', 'columns', 'name'],
      sorter: defaultFrontendSort('json.columns.name'),
      filterSearch: nameFilters.length > 5,
      filteredValue: filtering?.['json.columns.name'],
      filters: nameFilters,
    },
    {
      key: 'json.columns.dynamic_memory',
      dataIndex: 'json.columns.dynamic_memory'.split('.'),
      title: <T>Dynamic Memory</T>,
      width: 150,
      sorter: defaultFrontendSort('json.columns.dynamic_memory'),
      filteredValue: filtering?.['json.columns.dynamic_memory'],
      filters: dynamicMemoryFilters,
      render: value => <BooleanRender value={value} labels={labels} />,
    },
    ...baseColumns.map(({ key, title, width }) => ({
      key,
      title: <T>{title}</T>,
      dataIndex: key.split('.'),
      sorter: defaultFrontendSort(key),
      width,
      render,
    })),
  ];
};
