import styled from 'styled-components';

type JustifyContent = 'start' | 'end' | 'between' | 'around' | 'center';
type AlignItems = 'start' | 'end' | 'center';

type Props = {
  h?: JustifyContent;
  v?: AlignItems;
  column?: boolean;
  inline?: boolean;
};

function propsToFlex(prop: JustifyContent | AlignItems) {
  switch (prop) {
    case 'start':
      return 'flex-start';
    case 'end':
      return 'flex-end';
    case 'between':
      return 'space-between';
    case 'around':
      return 'space-around';
    case 'center':
      return 'center';
  }
}

const Flex = styled.div`
  display: ${p => (p.inline ? 'inline-flex' : 'flex')};
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: ${({ v = 'start', h = 'start', column }: Props) =>
    propsToFlex(column ? h : v)};
  justify-content: ${({ v = 'start', h = 'start', column }: Props) =>
    propsToFlex(column ? v : h)};
`;

export default Flex;
