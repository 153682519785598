import { userNamePassInputs } from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { fcnInputs } from './settingsInputs';
import { connectInputs } from './connectInputs';
import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import { AssetConfig, PlcDcsFCNAsset } from '../../../../types';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';
import { emptyAssetFactory } from '../../../../utils';

const select = {
  key: 'connection_type',
  label: 'Connection Type Configuration',
};

export const fcnConfig: AssetConfig<PlcDcsFCNAsset> = {
  name: 'Yokogawa - Field Control Node (FCN)',
  type: 'fcn',
  description:
    'Onboard a Yokogawa Field Control Node (FCN) via HIS or HIS/ENG.',
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(formikContext, numberOfAssets, fcnInputs),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext => {
        return formikContext.values.assets.some(asset => {
          const directStatus = asset.test_direct.result.status.port;
          const sshStatus = asset.test_ssh.result.status.port;
          const noTestRun = directStatus == null && sshStatus == null;
          const someTestFailed = directStatus === false || sshStatus === false;
          return noTestRun || someTestFailed;
        });
      },
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    initialValues: emptyAssetFactory<PlcDcsFCNAsset>({
      connectionTypes: ['direct', 'ssh'],
      assetType: 'dsc_plc_type',
      assetSubType: 'fcn',
      sshType: 'fcn',
      extraFields: {
        timezone: '',
        connection_type: 'direct',
      },
    }),

    configure: {
      inputs: fcnInputs,
      schema: inputToValidationReducer(fcnInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(
          userNamePassInputs,
          select.key,
          'direct',
          ['username', 'password']
        ),
        inputs: userNamePassInputs,
        select,
        testConnection: {
          valueKeys: [
            ...inputsToKeys(userNamePassInputs),
            'ip_address',
            'connection_type',
          ],
          customKeyMapping: {
            ip_address: 'stm_ip',
            username: 'stm_user',
            password: 'stm_password',
          },
        },
      },
      ssh: {
        schema: inputToValidationReducer(connectInputs, select.key, 'ssh', [
          'username',
          'password',
        ]),
        inputs: connectInputs,
        select,
        testConnection: {
          valueKeys: [
            ...inputsToKeys(connectInputs),
            'ip_address',
            'connection_type',
          ],
          customKeyMapping: {
            ip_address: 'stm_ip',
            username: 'stm_user',
            password: 'stm_password',
          },
        },
      },
    },
    collectors: {},
  },
};
