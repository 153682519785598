import { AnyAssetSubType, AssetTypeURI } from 'types';
import { AssetParams } from 'hooks/useAssetParams';

function anyAssetTypeToAPICategory(type: string) {
  switch (type) {
    case 'asset_management':
    case 'assetmanagement':
    case 'analyzer_management':
    case 'analyzermanagement':
    case 'virtualization':
    case 'control':
      return 'applications';
    default:
      return 'assets';
  }
}

const assetTypeURIToAPI = (assetType: AssetTypeURI) => {
  switch (assetType) {
    case 'security':
      return 'application';
    case 'plcdcs':
      return 'dcsplc';
    default:
      return assetType;
  }
};

export function createUrl(
  {
    assetType,
    assetSubType,
    assetId,
    siteId,
  }: Omit<AssetParams, 'category' | 'assetSubType'> & {
    assetSubType?: AnyAssetSubType;
  },
  suffix?: string,
  removeTrailingSlash?: boolean,
  useGeneric?: boolean
) {
  if (siteId) {
    return `/api/assets/${assetId}/?site=${siteId}`;
  }
  const url = useGeneric
    ? `/api/assets/?id=${assetId!}`
    : [
        '/api',
        anyAssetTypeToAPICategory(assetType),
        assetTypeURIToAPI(assetType),
        assetSubType?.toString().replace('windows_', ''),
        assetId,
        suffix,
      ]
        .filter(Boolean)
        .join('/');

  return `${url}${removeTrailingSlash ? '' : '/'}`;
}
