import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

type CurrentStep = {
  index: number;
  set: Dispatch<SetStateAction<number>>;
};

type StepperProps = {
  steps: {
    label: string;
    value: string;
  }[];
  currentStep: CurrentStep;
  disabled?: boolean;
};

const StepperContainer = styled.ul`
  background: #fff;
  color: #ffffff;
  padding: 0;
  justify-content: start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${p => p.theme.colors.common.grey200};
`;

export const Stepper = ({ steps, currentStep, disabled }: StepperProps) => {
  return (
    <StepperContainer>
      {steps.map((step, index) => (
        <Step
          key={step.value}
          label={step.label}
          index={index}
          currentStep={currentStep}
          disabled={disabled}
        />
      ))}
    </StepperContainer>
  );
};

type StepProps = {
  label: string;
  index: number;
  currentStep: CurrentStep;
  disabled?: boolean;
};

type StepContainerProps = {
  isActive: boolean;
  isDone: boolean;
  disabled?: boolean;
};

const StepContainer = styled.li<StepContainerProps>`
  min-width: 196px;
  display: flex;
  align-items: center;
  color: inherit;
  padding: 0.5rem 0rem !important;
  border-bottom: 3px solid transparent;
  color: ${p => p.theme.colors.common.grey300};
  height: 100%;

  &:not(:last-child) {
    margin-right: 1.4rem;
  }

  ${p =>
    p.isActive &&
    css`
      border-bottom-color: ${p => p.theme.colors.site.primary.main};
      color: ${p => p.theme.colors.site.primary.main};
      font-weight: 600;
    `}

  ${p =>
    p.isDone &&
    css`
      font-weight: 600;
      cursor: pointer;

      &:hover {
        border-bottom-color: ${(p: any) =>
          !p.disabled ? p.theme.colors.site.primary.main : 'transparent'};
        color: ${(p: any) =>
          !p.disabled
            ? p.theme.colors.site.primary.main
            : p.theme.colors.common.grey300};
      }
    `}
`;

const Step = ({ label, index, currentStep, disabled }: StepProps) => {
  const isActive = index === currentStep.index;
  const isDone = index < currentStep.index;

  const handleClick = () => {
    if (isDone) {
      currentStep.set(index);
    }
  };
  return (
    <StepContainer
      disabled={disabled}
      isActive={isActive}
      isDone={isDone}
      onClick={!disabled ? handleClick : undefined}
    >
      {label}
    </StepContainer>
  );
};
