import { Fetch } from '../../../../utils';
import { DownloadLink } from '../../../../apps';

export const getAgentInstallerLink = async (id: string) => {
  const response = await Fetch().get(
    `/api/assets/compute/agent/${id}/download_link/`
  );
  if (response.status !== 200) throw new Error(response.statusText);
  const result: DownloadLink = await response.json();
  return result;
};
