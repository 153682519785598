import { ArgsProps } from 'antd/lib/notification';
import { ReactNode } from 'react';

export const openNotificationWithIcon = (
  constructor: (args: ArgsProps) => void,
  message: string,
  description: string | ReactNode,
  duration = 10
) => {
  constructor({
    message,
    description,
    duration,
  });
};
