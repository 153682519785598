import {
  AssetConfig,
  SecurityEpoApplication,
  StandardInputConfigs,
} from '../../../../types';
import { defaultSecurityApplicationConfig } from '../default';
import { defaultSecurityInputs } from '../default/settingsInputs';

const baseConfig = defaultSecurityApplicationConfig<SecurityEpoApplication>(
  {
    name: 'McAfee ePolicy Orchestrator',
    type: 'mcafee_epo',
    description:
      'Onboard McAfee ePolicy Orchestrator (ePO) as security application.',
  },
  defaultSecurityInputs.map(x =>
    x.fieldName === 'api_url'
      ? ({
          ...x,
          label: 'McAfee ePO API URL',
        } as StandardInputConfigs)
      : x
  )
);

export const mcafeeEpoConfig: AssetConfig<SecurityEpoApplication> = {
  ...baseConfig,
};
