import { createGlobalStyle } from 'styled-components';

const fontSizeBase = 1;
const fontSizeUnit = 'rem';

const MssGlobalStyles = createGlobalStyle`
  /* id selector to increase specificity */
  #root {
    h1, h2, h3, h4, h5, h6 {
      color: rgba(0, 0, 0, 0.65);
      line-height: 1.2;
      font-weight: 600;
    }

    h1 { font-size: ${fontSizeBase * 2}${fontSizeUnit} };
    h2 { font-size: ${fontSizeBase * 1.75}${fontSizeUnit} };
    h3 { font-size: ${fontSizeBase * 1.5}${fontSizeUnit} };
    h4 { font-size: ${fontSizeBase * 1.25}${fontSizeUnit} };
    h5 { font-size: ${fontSizeBase * 1}${fontSizeUnit} };
    h6 { font-size: ${fontSizeBase * 0.875}${fontSizeUnit} };

    .text-muted {
      color: ${p => p.theme.colors.common.grey600};
    }
    
  }
`;

export default MssGlobalStyles;
