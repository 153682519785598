export function removeSearchParam(
  param: string,
  refresh: boolean | null = false
) {
  /**
   * Remove `param` from query parameters and return new url.
   * - if no parameters exist, return original url
   * - `refresh`=undefined -> only return url.
   * - `refresh`=false -> update browser window location without refresh.
   * - `refresh`=true -> update browser window location with refresh.
   */

  // Sanitize href to ensure it's a valid URL
  const href = window.location.href;
  try {
    new URL(href);
  } catch (e) {
    throw new Error('Invalid URL');
  }

  // Ensure param is a valid string
  if (typeof param !== 'string' || param.trim() === '') {
    throw new Error('Invalid parameter');
  }

  const paramIndex = href.indexOf('?');
  let cleanUrl: string;

  if (paramIndex >= 0) {
    const location = href.slice(0, href.indexOf('?'));
    const params = new URLSearchParams(href.slice(href.indexOf('?') + 1));
    params.delete(encodeURIComponent(param)); // Encode the param to prevent injection
    const hasParams = params.toString().length > 0;
    cleanUrl = hasParams ? `${location}?${params.toString()}` : location;
  } else {
    cleanUrl = href;
  }

  if (refresh === true) {
    window.location.href = cleanUrl;
  } else if (refresh === false) {
    window.history.replaceState(null, document.title, cleanUrl);
  }

  return cleanUrl;
}
