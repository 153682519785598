import { notification } from 'antd';
import React, { useCallback, useRef } from 'react';
import { openNotificationWithIcon } from 'utils';
import { FileTransfer } from 'types';
import { RowActionButton } from '../../../components/RowActionButton';
import { FileTransferApiType } from '../services/Api';
import { Download } from 'react-feather';
import { TFunction } from 'i18next';

type DownloadActionProps = {
  t: TFunction;
  fileTransfer: FileTransfer;
  api: FileTransferApiType;
};
export default function DownloadAction({
  t,
  fileTransfer,
  api,
}: DownloadActionProps) {
  const downloadLink = useRef<HTMLAnchorElement | null>(null);

  const downloadFile = useCallback(async () => {
    if (!downloadLink?.current) return;
    if (!fileTransfer.file_field) return;
    try {
      const link = await api.getDownloadLink(fileTransfer);
      const anchor = downloadLink.current;
      anchor.href = link.url;
      anchor.click();
      openNotificationWithIcon(
        notification.success,
        t('Download started'),
        `${fileTransfer.file_name} (${fileTransfer.file_size_str})`
      );
    } catch (e) {
      openNotificationWithIcon(
        notification.error,
        t('Error'),
        `${t('Unable to get download token')} ${e}`
      );
    }
  }, [fileTransfer, t]);
  return (
    <>
      <RowActionButton
        onClick={() => downloadFile()}
        shape="circle"
        style={{ border: 'none', backgroundColor: 'transparent' }}
      >
        <Download size="20" />
      </RowActionButton>
      {/* 
      // eslint-disable-next-line jsx-a11y/anchor-has-content 
      // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid 
      */}
      <a href="#" ref={downloadLink} key={fileTransfer.id} target="_blank"></a>
    </>
  );
}
