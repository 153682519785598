import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ColorVariants } from './Button';

const Container = styled.div`display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}`;

function Sidebar({ children, ...props }: React.PropsWithChildren<{}>) {
  return <Container {...props}>{children}</Container>;
}

type SidebarItemProps = {
  title: string;
  icon: React.ReactNode;
  url: string;
  exact?: boolean;
  theming?: ColorVariants;
};

const StyledNavLink = styled(NavLink)<{ theming: ColorVariants }>`
  padding: 0.5rem 1rem;
  background: none;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;

  .sidebar-icon {
    color: ${p => p.theme.colors.common.grey500};
    width: 1rem;
    margin-right: 1rem;
  }

  &:hover:not(.sidebar-active) {
    color: ${p => p.theme.colors[p.theming].primary.main};
    background: ${p => p.theme.colors[p.theming].primary.main}29;
  }

  &.sidebar-active {
    color: ${p => p.theme.colors[p.theming].primary.main};
    background: ${p => p.theme.colors[p.theming].primary.main}0f;

    .sidebar-icon {
      color: inherit;
    }
  }
`;

const SidebarItem = ({
  title,
  icon,
  url,
  theming = 'center',
  exact = false,
  ...props
}: SidebarItemProps) => {
  const match = useRouteMatch();
  const toUrl =
    match.url === '/' ? `${match.url}${url}` : `${match.url}/${url}`;

  return (
    <StyledNavLink
      to={exact ? url : toUrl}
      activeClassName={'sidebar-active'}
      theming={theming}
      {...props}
    >
      <span className="sidebar-icon">{icon}</span>
      {title}
    </StyledNavLink>
  );
};

Sidebar.Item = SidebarItem;

export default Sidebar;
