import React from 'react';

const IconRequestConnection = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
    <g
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 16H2c-.552 0-1 .488-1 1.09v3.82c0 .602.448 1.09 1 1.09h7c.552 0 1-.488 1-1.09v-3.82c0-.602-.448-1.09-1-1.09zM2 16v-2.221c0-.688.324-1.353.912-1.864.587-.51 1.396-.832 2.268-.9.872-.07 1.746.118 2.452.526.706.408 1.194 1.008 1.368 1.683" />
      <path
        d="M3 7V5a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2h-6"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default IconRequestConnection;
