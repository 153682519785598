import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Flex from 'components/Flex';
import { Popover as AntPopover, Button as AntButton } from 'antd';
import { useTranslation } from 'react-i18next';
import { Clipboard, X } from 'react-feather';
import TextArea from 'antd/lib/input/TextArea';

const Popover = styled(AntPopover)`
  .ant-popover {
    * {
      animation-duration: 0s !important;
      transform: 0;
    }

    width: 300px;
  }

  .ant-popover-title {
    padding-right: 0;
  }

  .ant-popover-inner-content {
    padding: 0;

    textarea {
      width: 300px;
      min-height: 200px;
    }
  }

  .title {
    line-height: 32px;
  }
`;

type Props = {
  clipboardValue: string;
  onClipboardChanged: (x: string) => void;
};
export default function ClipboardMenu({
  clipboardValue,
  onClipboardChanged,
}: Props) {
  const [t] = useTranslation(['common', 'remoteAccess']);
  const [visible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>(clipboardValue);

  useEffect(() => {
    if (clipboardValue === value) return;
    setValue(clipboardValue);
  }, [clipboardValue]);

  const visibleChange = (x: boolean) => {
    console.log('visibleChange!', x);
    if (!x && value !== clipboardValue) {
      onClipboardChanged(value);
    }
    setVisible(x);
  };
  return (
    <Popover
      // overlayStyle={{ width: '300px' }}
      content={
        <TextArea
          onChange={x => {
            setValue(x.target.value);
            onClipboardChanged(x.target.value);
          }}
          value={value}
        />
      }
      title={
        <Flex h={'between'}>
          <div className={'title'}>{t('Clipboard')}</div>
          <AntButton type="link" onClick={() => setVisible(false)}>
            <X size={16} />
          </AntButton>
        </Flex>
      }
      trigger="click"
      open={visible}
      overlayStyle={{
        width: '300px',
      }}
      placement={'bottomRight'}
      onOpenChange={visibleChange}
      getPopupContainer={container => container}
      arrowPointAtCenter
      autoAdjustOverflow={false}
    >
      <span>
        <AntButton
          ghost={false}
          style={{
            borderColor: '#fff',
            backgroundColor: 'transparent',
            position: 'relative',
          }}
        >
          <Clipboard style={{ stroke: '#fff' }} size={16} />
        </AntButton>
      </span>
    </Popover>
  );
}
