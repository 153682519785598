import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import {
  APIAsset,
  AssetConfig,
  ComputeWindowsAgentAsset,
} from '../../../../types';
import { defaultAssetInputs } from '../../defaults/inputs';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import Setup from './Setup';
import React from 'react';
import DownloadAgent from './DownloadAgent';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';

export const windowsAgentConfig: AssetConfig<ComputeWindowsAgentAsset> = {
  name: 'Windows Asset - Agent',
  type: 'windows_agent',
  description: 'Agent based data collection for Windows Client and Servers',
  icon: faWindows,
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultAssetInputs
        ),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
    {
      label: 'Setup',
      value: 'afterOnboarding',
      render: ((asset: APIAsset) => <Setup {...asset} />) as any,
    },
  ],
  form: {
    method: 'put',
    initialValues: emptyAssetFactory<ComputeWindowsAgentAsset>({
      assetType: 'compute_type',
      assetSubType: 'windows_agent',
      connectionTypes: [],
    }),
    configure: {
      inputs: defaultAssetInputs,
      schema: inputToValidationReducer(defaultAssetInputs),
    },
    connect: {},
    collectors: {
      disablePrefix: true,
      actions: <DownloadAgent />,
      tooltips: {
        dns_server:
          'DNS Server data collection needs extra configuration. Make sure you run the onboarding tool with the required settings before enabling this collector.',
      },
      labels: {
        dns_server: 'DNS Server Only',
        directory_service: 'Domain Controller Only',
      },
    },
  },
};
