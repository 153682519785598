import React, { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';

type Props = {
  title?: string | ReactNode;
  subtitle?: string;
  actions?: ReactNode;
  className?: string;
};

const PageContent = ({
  title,
  subtitle,
  actions,
  children,
  className,
}: PropsWithChildren<Props>) => {
  const classes = cn({
    'flex justify-between border-bottom border-light mb-2 pb-4':
      (title && typeof title === 'string') || subtitle,
  });
  return (
    <>
      <div className={`${classes} ${className}`}>
        <div>
          {title && typeof title === 'string' ? (
            <h4 className="">{title}</h4>
          ) : (
            <>{title}</>
          )}
          {subtitle && <span className="text-sm text-muted">{subtitle}</span>}
        </div>
        {actions && actions}
      </div>
      {children}
    </>
  );
};

export default PageContent;
