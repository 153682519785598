import React from 'react';
import styled, { keyframes } from 'styled-components';

const bounceDelay = keyframes`
0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Bounce = styled.div`
  width: 12px;
  height: 12px;
  background-color: var(--primary);

  border-radius: 100%;
  display: inline-block;
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;
`;

const Bounce1 = styled(Bounce)`
  animation-delay: -0.32s;
`;

const Bounce2 = styled(Bounce)`
  animation-delay: -0.16s;
`;

const Spinner = styled.div`
  text-align: center;
`;

function Loading({ ...props }) {
  return (
    <Spinner {...props}>
      <Bounce1 className="bounce1"></Bounce1>
      <Bounce2 className="bounce2"></Bounce2>
      <Bounce className="bounce3"></Bounce>
    </Spinner>
  );
}

export default Loading;
