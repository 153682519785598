import { darken, lighten } from 'polished';

type ButtonColorConfig = {
  bg: string;
  text: string;
  border?: string;
  hoverBg?: string;
  hoverText?: string;
  hoverBorder?: string;
};

const sitePrimary = '#417e80';
const siteSecondary = '#5187D2';

const centerPrimary = '#004f9b';
const centerSecondary = '#417e80';

const buttonColors = (config: ButtonColorConfig) => {
  const defaultHoverBg = darken(0.1, config.bg);
  return {
    bg: config.bg,
    text: config.text,
    border: config.border || config.bg,
    hoverBg: config.hoverBg || defaultHoverBg,
    hoverText: config.hoverText || config.text,
    hoverBorder: config.hoverBg || config.hoverBg || defaultHoverBg,
  };
};

type ColorType = {
  light?: number;
  dark?: number;
  color: string;
};

const colorSet = (config: ColorType) => ({
  light: lighten(config.light || 0.2, config.color),
  main: config.color,
  dark: darken(config.dark || 0.2, config.color),
});

type ButtonsetConfig = {
  primary: string;
  secondary: string;
};

const buttonSet = (colors: ButtonsetConfig) => ({
  primary: buttonColors({
    bg: colors.primary,
    border: colors.primary,
    text: '#FFFFFF',
  }),
  secondary: buttonColors({
    bg: colors.secondary,
    border: colors.primary,
    text: '#FFFFFF',
  }),
  default: {
    bg: '#FFFFFF',
    text: 'rgba(0,0,0,.65)',
    border: 'rgba(0,0,0,.65)',
    hoverBg: 'rgba(0,0,0,.44)',
    hoverText: '#FFFFFF',
    hoverBorder: 'rgba(0,0,0,.44)',
  },
});

const colors = {
  site: {
    primary: colorSet({ color: sitePrimary }),
    secondary: colorSet({ color: siteSecondary }),
    button: buttonSet({ primary: sitePrimary, secondary: siteSecondary }),
  },
  center: {
    primary: colorSet({ color: centerPrimary }),
    secondary: colorSet({ color: centerSecondary }),
    button: buttonSet({ primary: centerPrimary, secondary: centerSecondary }),
  },
  common: {
    gray100: '#f8f9fa',
    gray200: '#e9ecef',
    gray300: '#dee2e6',
    gray400: '#ced4da',
    gray500: '#adb5bd',
    gray600: '#868e96',
    gray700: '#495057',
    gray800: '#343a40',
    gray900: '#212529',
    grey100: '#f8f9fa',
    grey200: '#e9ecef',
    grey300: '#dee2e6',
    grey400: '#ced4da',
    grey500: '#adb5bd',
    grey600: '#868e96',
    grey700: '#495057',
    grey800: '#343a40',
    grey900: '#212529',
    blue: '#004f9b',
    azure: '#45aaf2',
    indigo: '#6574cd',
    purple: '#a55eea',
    pink: '#f66d9b',
    red: '#fa4654',
    orange: '#fd9644',
    yellow: '#f1c40f',
    lime: '#7bd235',
    green: '#5eba00',
    teal: '#2bcbba',
    cyan: '#17a2b8',
  },
};

export type ThemeColors = typeof colors;
export default colors;
