import { Tooltip } from 'antd';
import React from 'react';
import { Info } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../../components';

export const CalsysTooltip = () => {
  const { t } = useTranslation(['asset', 'common', 'validation']);
  return (
    <Tooltip
      placement="right"
      title={t(
        'Enter the name of the Calsys SQL Database, this should be different than the AMADAS_AlarmAndEvents database.'
      )}
    >
      <Flex v="center">
        {t('SQL Database Name')}
        <Info className="ml-1 text-gray-500" size={16} />
      </Flex>
    </Tooltip>
  );
};
