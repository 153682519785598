import RootModal from 'common/components/Modals/RootModal';
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/scss/mss-style.scss';
import ErrorBoundary from 'common/components/HOC/ErrorBoundary';
import MainRoutes from 'common/components/Router/MainRoutes';
import configureStore from './store';
import UserGuard from 'common/components/Router/UserGuard';
import { ThemeProvider } from 'styled-components';
import { ContentTypeAPI, theme, useFetch } from '@mss/mss-component-library';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import pjson from '../package.json';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      cacheTime: 1000 * 60,
      refetchIntervalInBackground: false,
      refetchInterval: 1000 * 60,
      initialDataUpdatedAt: 1,
      retry: process.env.NODE_ENV === 'production' ? 3 : false,
    },
  },
});

const store = configureStore();

export type AllPermissionAPI = {
  id: number;
  name: string;
  codename: string;
  content_type: number;
};

type IAppContext = {
  allPermissions: AllPermissionAPI[];
  allPermissionsMap: Map<string, number>;
  contentTypes?: ContentTypeAPI[];
  mssVersions: MSSVersions;
  keycloak: any;
};

type SentryData = {
  dsn: string;
  environment: string;
  release: string;
};

type MSSVersions = {
  mss_center_api: string;
  mss_center_api_tag: string;
  mss_center_api_full: string;
  mss_center_client: string;
  mss_shared: string;
  mss_component_library: string;
  celery: string;
  django: string;
  react: string;
  redis: string;
};

export const AppContext = React.createContext<IAppContext>({
  allPermissions: [],
  allPermissionsMap: new Map<string, number>(),
  contentTypes: [],
  mssVersions: {} as MSSVersions,
  keycloak: {},
});

const App = ({ keycloak }: any) => {
  const [mssVersions, setMSSVersions] = useState({} as MSSVersions);
  const [allPermissionsMap, setAllPermissionsMap] = useState(
    new Map<string, number>(),
  );
  const [{ data: allPermissions }] = useFetch({
    url: '/api/permission/',
    defaultValue: [],
    onSucces: ({ data }) => {
      const codes = new Map<string, number>();

      data.forEach?.((x: any) => {
        if (codes.has(x.codename))
          process.env.NODE_ENV === 'development' &&
            console.info('Duplicate codename!', x);
        else codes.set(x.codename, x.id);
      });
      setAllPermissionsMap(codes);
    },
  });

  const [{ data: configData }] = useFetch({
    url: '/api/center/configuration/',
    onSucces: result => {
      if (result.data) {
        const mss_center_api_full = result.data['mss_center_api'];
        const mss_center_api = mss_center_api_full.split('-')[0];
        const mss_center_api_tag = mss_center_api_full.split('-')[1] || '';
        setMSSVersions({
          ...result.data,
          react: pjson.dependencies['react'],
          mss_center_api_full: mss_center_api_full,
          mss_center_api: mss_center_api,
          mss_center_api_tag: mss_center_api_tag,
        });
      }
    },
  });

  const sentryData: SentryData = configData?.sentry;

  if (sentryData && process.env.REACT_APP_DISABLE_SENTRY !== 'true') {
    Sentry.init({
      dsn: sentryData.dsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: sentryData.environment,
      release: sentryData.release,
    });
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ReactQueryDevtools initialIsOpen={false} />
          <AppContext.Provider
            value={{
              allPermissions,
              allPermissionsMap,
              mssVersions,
              keycloak,
            }}
          >
            <Router>
              <ErrorBoundary>
                <UserGuard>
                  <MainRoutes />
                </UserGuard>
              </ErrorBoundary>
            </Router>
            <RootModal />
          </AppContext.Provider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
