import React from 'react';

const IconMcAfee = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...rest}>
    <path
      d="M24 9.647L3 0v38.353L24 48l21-9.647V0L24 9.647zm12.444 23.25l-12.385 5.681-12.375-5.681V13.379l12.375 5.681 12.385-5.681v19.518z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default IconMcAfee;
