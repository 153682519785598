import React, { ReactNode, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { useAssetTranslation } from '../hooks';
import { Tooltip } from 'antd';
import { Info } from 'react-feather';

const Container = styled.div`
  margin-bottom: 1rem;
  display: block;
`;

const FormLabel = styled.label<{ actions?: boolean }>`
  display: block;
  margin-bottom: 0.375rem;
  font-size: 14px;
  font-weight: 600;

  ${p =>
    p.actions &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
`;

export const FieldError = styled.div`
  color: #fa4654 !important;
  font-size: 12px;
  margin: 4px 12px 0 12px;
`;

const RequiredMark = styled.span`
  color: #fa4654 !important;
  margin-left: 4px;
`;

type Props = {
  label: ReactNode;
  input: ReactNode;
  error?: ReactNode;
  actions?: ReactNode;
  required?: boolean;
  className?: string;
};

export const FormGroup = ({
  label,
  input,
  error,
  actions,
  required,
  className,
}: Props) => {
  return (
    <Container className={className}>
      <FormLabel actions={!!actions}>
        <span>
          {label}
          {required && <RequiredMark>*</RequiredMark>}
        </span>
        {!!actions && actions}
      </FormLabel>
      {input}
      {error ? (
        <FieldError data-ta={'validation-error'}>{error}</FieldError>
      ) : null}
    </Container>
  );
};

type TooltipLabelProps = {
  label: string;
  tooltipLabel: string;
};
export const TooltipLabel = ({ label, tooltipLabel }: TooltipLabelProps) => {
  const { t } = useAssetTranslation();
  const theme = useContext(ThemeContext);
  return (
    <span>
      {t(`assets:${label}`)}
      <Tooltip title={t(`assets:${tooltipLabel}`)}>
        <Info size={16} className="ml-2" color={theme.colors.common.grey500} />
      </Tooltip>
    </span>
  );
};
