import {
  AssetConfig,
  SecurityVeeamApplication,
  StandardInputConfigs,
} from '../../../../types';
import { defaultSecurityApplicationConfig } from '../default';
import { defaultSecurityInputs } from '../default/settingsInputs';

const baseConfig = defaultSecurityApplicationConfig<SecurityVeeamApplication>(
  {
    name: 'Veeam Backup Enterprise Manager',
    type: 'veeam',
    description:
      'Onboard Veeam Backup Enterprise Manager as a security application.',
  },
  defaultSecurityInputs.map(x =>
    x.fieldName === 'api_url'
      ? ({ ...x, label: 'Veeam API URL' } as StandardInputConfigs)
      : x
  )
);

export const veeamConfig: AssetConfig<SecurityVeeamApplication> = {
  ...baseConfig,
};
