import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import Table from 'antd/es/table';

const CompactTable: StyledComponent<any, DefaultTheme, {}, never> = styled(
  Table
)`
    &.ant-table,
    * .ant-table {
        & + ul.ant-table-pagination.ant-pagination {
            float: left;
            margin: 16px;
        }

        border: 1px solid ${p =>
          p.bordered !== false ? p.theme.colors.common.gray200 : 'transparent'};

        tbody,
        thead {
            > tr {
                cursor: pointer;

                &:last-child {
                    :not(:first-child) {
                        .ant-table-cell {
                            border-left: none;
                            border-right: none;
                            border-bottom: ${(p: any) =>
                              p.isNested
                                ? `1px solid ${p.theme.colors.common.gray200}`
                                : 'none'}
                            border-top: ${(p: any) =>
                              p.isNested ? 'unset' : 'none'};
                        }
                    }
                }

                .ant-table-cell {
                    padding: 8px 8px;

                    .ant-table-column-sorters {
                        padding: 0;
                    }
                }
            }
        }
    }

    + ul.ant-table-pagination.ant-pagination {
        float: left;
        margin: 16px;
    }

    .ant-pagination-item-link {
        svg {
            vertical-align: unset;
        }
    }
`;

export default CompactTable;
