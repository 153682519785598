import {
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import dateMath from '@elastic/datemath';
import { Moment } from 'moment';
import { FilterState, Query, QueryParameter, SortState } from '../types';
import {
  antdToEsSort,
  defaultResponseHandler,
  Fetch,
  insertArrayIfDefined,
  insertObjIf,
  insertObjIfDefined,
  openNotificationWithIcon,
} from '../utils';
import { useAssetParams } from './useAssetParams';
import { useAssetTranslation } from './useAssetTranslation';
import { notification } from 'antd';

type FilterMatchPhrase = {
  match_phrase: {
    [key: string]: string;
  };
};

type Filter = {
  bool: {
    should: FilterMatchPhrase[];
  };
};

export type ElasticQueryParams = Pick<
  Query,
  'index' | 'dataParser' | 'baseQuery' | 'queryType' | 'sortingLocation' | 'key'
> & {
  keywords?: string[];
  dateRange?: Query['dateRange'] & {
    start: string;
    end: string;
  };
  pagination?: {
    from: number;
    size: number;
  };
  sort?: Query['defaultSort'];
  filter?: FilterState;
  parameters?: (Omit<QueryParameter, 'sourceParser'> & {
    value: any;
  })[];
  search?: { key: string; value: string; type: 'fuzzy' | 'wildcard' }[];
  searchType?: 'fuzzy' | 'wildcard';
};

function boolShouldMatchPhrase(key: string, values: string[]): Filter {
  const arr = values.map(x => ({
    match_phrase: {
      [key]: x,
    },
  }));

  return {
    bool: {
      should: arr,
    },
  };
}

export function includeFilters(filter?: FilterState) {
  if (!filter) return undefined;
  return Object.keys(filter ?? {})
    .filter(x => filter[x] != null)
    .map(x => boolShouldMatchPhrase(x, filter[x] ?? []));
}

export function parseDate(date: string | undefined, roundUp = false): Moment {
  const dateMoment = dateMath.parse(date ?? new Date().toISOString(), {
    roundUp,
  });
  if (!dateMoment || !dateMoment.isValid()) {
    throw new Error('Unable to parse start string');
  }

  return dateMoment;
}

function formatSorting(
  sorting: SortState,
  keywords: string[] = []
): {
  [key: string]: 'asc' | 'desc';
} {
  const newOrder = antdToEsSort(sorting.order);
  if (!newOrder) throw Error('Invalid sort order');
  return {
    [`${sorting.columnKey}${
      keywords.includes(sorting.columnKey) ? '.keyword' : ''
    }`]: newOrder,
  };
}

function formatParameters(
  parameters: ElasticQueryParams['parameters']
): QueryConstructorOptions['parameters'] {
  return (
    parameters?.map(({ elasticQueryKey, value, matcher = 'match' }) => ({
      [matcher]: {
        [elasticQueryKey]: value,
      },
    })) ?? []
  );
}

type QueryConstructorOptions = Omit<ElasticQueryParams, 'parameters'> & {
  parameters: {
    [key: string]: {
      [fieldName: string]:
        | string
        | number
        | boolean
        | { [key: string]: string };
    };
  }[];
};

function generateSearchQuery(search: QueryConstructorOptions['search']) {
  if (!search || search.length < 1) return undefined;
  // TODO: Only supports one search type for now, more not needed yet.
  const searchType = search[0].type;
  return [
    {
      [searchType]:
        search.reduce(
          (acc, s) => ({
            ...acc,
            [s.key]: {
              value: s.type === 'wildcard' ? `*${s.value}*` : s.value,
              ...insertObjIf(searchType === 'fuzzy', { fuzziness: 'AUTO' }),
            },
          }),
          {}
        ) ?? {},
    },
  ];
}

function generateDateRangeQuery(
  dateRange: QueryConstructorOptions['dateRange']
) {
  if (!dateRange) return undefined;
  return [
    {
      range: {
        [dateRange?.key ?? '@timestamp']: {
          gte: `${parseDate(dateRange?.start).format(dateRange?.customFormat)}`,
          lte: `${parseDate(dateRange?.end, true)?.format(
            dateRange?.customFormat
          )}`,
        },
      },
    },
  ];
}

export function constructQuery(config: QueryConstructorOptions) {
  return {
    // @ts-ignore
    size: 10000,
    ...config.baseQuery,
    ...insertObjIfDefined(config.pagination),
    ...insertObjIf(!!config.sort && config.queryType !== 'aggregation', {
      sort: formatSorting(
        config.sort ?? { columnKey: '', order: 'descend' },
        config.keywords
      ),
    }),
    query: {
      ...insertObjIfDefined(config.baseQuery.query),
      bool: {
        ...insertObjIfDefined(config.baseQuery.query?.bool),
        must: [
          ...insertArrayIfDefined(generateSearchQuery(config.search)),
          ...insertArrayIfDefined(config.baseQuery.query?.bool?.must),
          ...config.parameters,
          ...insertArrayIfDefined(includeFilters(config.filter)),
          ...insertArrayIfDefined(generateDateRangeQuery(config.dateRange)),
        ],
      },
    },
  };
}

function recursiveMap(obj: any): string {
  if (typeof obj === 'object' && obj !== null) {
    const { sourceParser, ...restObj } = obj;
    return Object.entries(restObj)
      .map(([key, value]) => `${key}-${recursiveMap(value)}`)
      .join('-');
  }
  return obj?.toString();
}

export function useElasticQuery(
  configs: ElasticQueryParams[],
  options?: UseQueryOptions<any>[],
  isFilters?: boolean
): UseQueryResult<any>[] {
  const { t } = useAssetTranslation();
  const { siteId } = useAssetParams();
  return useQueries({
    queries: configs.map((config, index) => ({
      queryKey: [
        'elastic',
        config.key,
        config.index,
        ...(config.search?.map(x => `${x.key}-${x.value}`) ?? []),
        ...(config.parameters?.map(x => recursiveMap(x)) ?? []),
        ...(config.sort ? [config.sort.columnKey, config.sort.order] : []),
        ...(config.pagination
          ? [config.pagination.from, config.pagination.size]
          : []),
        ...(config.filter
          ? Object.entries(config.filter)
              .filter(([, value]) => !!value)
              .flat()
          : []),
        ...(config.dateRange
          ? [config.dateRange.start, config.dateRange.end]
          : []),
      ],
      queryFn: async (): Promise<{
        data: any;
        total: number;
        error?: null | any;
      }> => {
        const response = await defaultResponseHandler<{
          data: any;
          error?: any;
        }>({
          t,
          request: Fetch(siteId!).put(
            `/api/elastic/${siteId}-${config.index}/`,
            {
              body: JSON.stringify(
                constructQuery({
                  ...config,
                  parameters: formatParameters(config.parameters),
                })
              ),
            }
          ),
        });

        if (response.error || response.data?.error) {
          const errorMsg = t(
            `An error occurred while retrieving the ${
              isFilters ? 'filters' : 'data'
            }, please try again later.`
          );
          openNotificationWithIcon(notification.error, errorMsg, '');
          return {
            data: [],
            total: 0,
            error: errorMsg,
          };
        }

        return config.dataParser(response.data);
      },
      ...insertObjIfDefined(options?.[index]),
      staleTime: options?.[index]?.staleTime ?? 5000,
      cacheTime: options?.[index]?.cacheTime ?? 60000,
      refetchInterval: options?.[index]?.refetchInterval ?? 60000,
      refetchIntervalInBackground: false,
    })),
  });
}
