import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import { defaultNetworkInputs } from './settingsInputs';
import { snmpSchema } from './snmpSchema';
import React from 'react';
import SetupLogsModal from './SetupLogsModal';
import SetupLogs from './SetupLogs';
import { AssetConfig, NetworkAsset } from '../../../../types';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';

export const defaultConfig: Omit<
  AssetConfig<NetworkAsset>,
  'name' | 'label' | 'description' | 'type'
> = {
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultNetworkInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext => {
        return formikContext.values.assets.some(
          asset => !asset.test_snmp.result.status.port
        );
      },
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
    {
      label: 'Setup',
      value: 'afterOnboarding',
      render: () => <SetupLogs />,
      show: formikContext =>
        formikContext.values.assets.some(asset =>
          // @ts-ignore
          asset.values.logs?.includes('syslog')
        ),
    },
  ],
  form: {
    initialValues: {} as NetworkAsset,
    configure: {
      inputs: defaultNetworkInputs,
      schema: inputToValidationReducer(defaultNetworkInputs),
    },
    connect: {
      snmp: {
        schema: snmpSchema,
        inputs: [],
        testConnection: {
          customKeyMapping: { ip_address: 'host' },
          jsonBody: true,
          valueKeys: [
            'version',
            'security_level',
            'community',
            'security_username',
            'auth_password',
            'privacy_password',
            'ip_address',
          ],
          cleanEmpty: true,
        },
      },
    },
    collectors: {
      extraOptions: {
        network_config: {
          'running-config': 'Running Configuration',
          version: 'Version',
          sysinfo: 'System Information',
          'ip route': 'IP Route',
          route: 'Route',
        },
      },
      staticCollectors: {
        logs: {
          extraInfo: <SetupLogsModal />,
          label: 'Logs',
          collectors: [
            {
              key: 'syslog',
              label: 'Syslog',
            },
          ],
        },
      },
    },
  },
};
