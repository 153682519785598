export const submitDataParser = (values: any) => {
  const isTelnet = values.network_config_mode === 'telnet';
  const isSSH = values.network_config_mode === 'ssh';
  const isNeither = !isSSH && !isTelnet;

  return Object.keys(values).reduce((acc, key) => {
    const shouldModifySSHKey =
      (isTelnet || isNeither) && key.startsWith('ssh_');
    const shouldModifyTelnetKey =
      (isSSH || isNeither) && key.startsWith('telnet_');
    if (shouldModifyTelnetKey || shouldModifySSHKey) return acc;
    return { ...acc, [key]: values[key] };
  }, {} as typeof values);
};
