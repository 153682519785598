import { Moment } from 'moment';
import dateMath from '@elastic/datemath';

/** @deprecated */

export default function parseDateRange(
  start: string,
  end: string,
): {
  startMoment: Moment;
  endMoment: Moment;
} {
  const startMoment = dateMath.parse(start);
  // dateMath.parse is inconsistent with unparsable strings.
  // Sometimes undefined is returned, other times an invalid moment is returned
  if (!startMoment || !startMoment.isValid()) {
    throw new Error('Unable to parse start string');
  }

  // Pass roundUp when parsing end string
  const endMoment = dateMath.parse(end, { roundUp: true });
  if (!endMoment || !endMoment.isValid()) {
    throw new Error('Unable to parse end string');
  }

  return { startMoment, endMoment };
}
