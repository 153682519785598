import {
  custodianValidation,
  numberValidation,
  stringValidation,
} from './validationSchemas';
import { AnySchema, array, lazy, object, ObjectSchema, string } from 'yup';
import {
  BaseInputConfig,
  NumberConfig,
  PredefinedConfig,
  StringConfig,
  TextConfig,
} from '../types';

export function inputToValidation(
  input: NumberConfig | StringConfig | TextConfig | PredefinedConfig
): AnySchema | ReturnType<typeof lazy> {
  switch (input.type) {
    case 'number':
      return numberValidation(input);
    case 'password':
    case 'string':
    case 'text':
      return stringValidation(input);
    case 'custodian':
      return custodianValidation();
    case 'role':
    case 'location':
      return stringValidation({
        required: true,
        maxLength: 50,
      });
    case 'criticality':
      return stringValidation({ required: true }).oneOf([
        'low',
        'medium',
        'high',
      ]);
    case 'brand':
    case 'series':
    case 'timezone':
      return stringValidation({ required: true });
    case 'hmcg':
      return array().of(stringValidation({ isIP: true }));
    default:
      return stringValidation();
  }
}

export function inputToValidationReducer(
  arr: BaseInputConfig[],
  conditionField?: string,
  conditionValue?: string,
  exemptedConditionKeys?: string[]
) {
  return arr.reduce((acc: ObjectSchema<any>, input: BaseInputConfig) => {
    return object().shape({
      ...acc.fields,
      [String(input.fieldName ?? input.type)]:
        conditionValue &&
        conditionField &&
        !exemptedConditionKeys?.includes(input.fieldName)
          ? string().when(conditionField, {
              is: (val: string) => !!val && val === conditionValue,
              then: () => inputToValidation(input),
              otherwise: () => string().nullable(),
            })
          : inputToValidation(input),
    });
  }, object().shape({}));
}
