import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Flex } from '../../../../components';
import { getAgentInstallerLink } from './getDownloadLink';

export default function DownloadAgent() {
  const { t } = useTranslation('asset');
  const { assetId } = useParams<any>();
  return (
    <Flex h="end" v="center">
      <Button
        theming="site"
        variant="primary"
        ghost
        onClick={async (e: any) => {
          e.preventDefault();
          const link = await getAgentInstallerLink(assetId!);
          window.location.href = link.url;
        }}
      >
        {t('Download agent installer')}
      </Button>
    </Flex>
  );
}
