import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AnyAssetApplication,
  ColumnsConfig,
  DefaultAssetColumnProps,
  NetworkSubType,
} from '../../../types';
import {
  assetTypeColumn,
  assetTypeLogoColumn,
  custodianColumn,
  ipAddressColumn,
  locationColumn,
  nameColumn,
  operationalStatusColumn,
  roleColumn,
} from 'components/DefaultAssetColumns';
import Flex from 'components/Flex';
import { networkTypeTableIcon } from '../../../utils';

function columns<Asset extends AnyAssetApplication>(
  props: DefaultAssetColumnProps,
  subTypeKey: string
): ColumnsConfig<Asset>[] {
  return [
    operationalStatusColumn<any>(props),
    assetTypeLogoColumn<Asset>(
      {
        ...props,
        render: (network_type: NetworkSubType, row: any) => (
          <Tooltip
            title={
              <span style={{ fontSize: 14 }}>
                <strong>{row.series.brand_name}</strong> {row.series.name}
              </span>
            }
          >
            <Flex h="center" v="center" style={{ maxWidth: 14, maxHeight: 14 }}>
              <FontAwesomeIcon icon={networkTypeTableIcon(network_type)} />
            </Flex>
          </Tooltip>
        ),
      },
      subTypeKey
    ),
    nameColumn<Asset>(props),
    assetTypeColumn<Asset>(props, subTypeKey),
    roleColumn<Asset>(props),
    custodianColumn<Asset>(props),
    locationColumn<Asset>(props),
    ipAddressColumn<Asset>(props),
  ];
}

export default columns;
