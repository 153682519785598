import {
  array,
  boolean,
  lazy,
  number,
  NumberSchema,
  object,
  SchemaOf,
  string,
  StringSchema,
} from 'yup';
import { TFunction } from 'i18next';
import { CustomField, NumberConfig, StringConfig } from '../types';
import Regex from './regExp';

export const PORT_RANGE_MSG = 'Please enter a number between 1 and 65536';
export const INTEGER_MSG = 'Please enter a number without decimals';
export const STATION_DOMAIN_RANGE_MSG =
  'Please enter a number between 1 and 64';
export const REQUIRED_MSG = 'Required';
export const MAX_LENGTH_MSG = 'Max (maxLength) characters';
export const MIN_MAX_RANGE_MSG =
  'Please enter a number between (MIN) and (MAX)';
export const NO_WHITESPACE_MSG = 'Cannot start or end with whitespace';

const containsDollarSign = (word: any) => /\$/.test(word);

export const connectionSchema = (t: TFunction) =>
  object().shape({
    username: string().required(t('Required')),
    password: string().required(t('Required')),
  });

export const getCustomFieldsSchema = (
  t: TFunction,
  customFields: CustomField[]
) => {
  let schema: any = {};
  if (Array.isArray(customFields)) {
    customFields.forEach(element => {
      if (element.required) {
        if (element.field_type === 'integer') {
          schema[element.slug] = number()
            .typeError(t('asset:Please enter a valid integer value'))
            .integer(t('asset:Please enter a valid integer value'))
            .required(t(REQUIRED_MSG));
        } else if (
          element.field_type === 'string' ||
          element.field_type === 'date_time'
        ) {
          schema[element.slug] = string()
            .required(t(REQUIRED_MSG))
            .max(250, t(MAX_LENGTH_MSG, { maxLength: 250 }));
        } else if (element.field_type === 'user_group') {
          schema[element.slug] = lazy(value => {
            if (typeof value === 'object') {
              return object().shape({
                id: string().required(t(REQUIRED_MSG)),
                name: string().required(t(REQUIRED_MSG)),
                type: string().required(t(REQUIRED_MSG)),
              });
            }
            return string()
              .required(t(REQUIRED_MSG))
              .max(250, t(MAX_LENGTH_MSG, { maxLength: 250 }));
          });
        } else if (element.field_type === 'boolean') {
          schema[element.slug] = boolean().required(t(REQUIRED_MSG));
        } else if (element.field_type === 'ip_address') {
          schema[element.slug] = string()
            .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
            .required(t(REQUIRED_MSG));
        }
      } else {
        if (element.field_type === 'integer') {
          schema[element.slug] = number()
            .typeError(t('asset:Please enter a valid integer value'))
            .integer(t('asset:Please enter a valid integer value'));
        } else if (
          element.field_type === 'string' ||
          element.field_type === 'date_time'
        ) {
          schema[element.slug] = string()
            .max(250, t(MAX_LENGTH_MSG, { maxLength: 250 }))
            .nullable();
        } else if (element.field_type === 'user_group') {
          schema[element.slug] = lazy(value => {
            if (typeof value === 'object') {
              return object().shape({
                id: string(),
                name: string(),
                type: string(),
              });
            }
            return string().max(250, t(MAX_LENGTH_MSG, { maxLength: 250 }));
          });
        } else if (element.field_type === 'boolean') {
          schema[element.slug] = boolean();
        } else if (element.field_type === 'ip_address') {
          schema[element.slug] = string().matches(
            Regex.ip,
            t('asset:Please enter a valid IP Address')
          );
        }
      }
    });
  }
  return { custom_field_values: object(schema) };
};

export const computeAssetValidationSchema = (
  t: TFunction,
  customFields: CustomField[],
  disableConnection?: boolean
) => {
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    ip_address: string()
      .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
      .required(t(REQUIRED_MSG)),
    priority: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    description: string(),
    username: disableConnection
      ? string()
      : string().when('compute_type', {
          is: 'windows_agent',
          then: string(),
          otherwise: string().required(t('asset:Required')),
        }),
    password: disableConnection
      ? string().nullable()
      : string().when('compute_type', {
          is: 'windows_agent',
          then: string(),
          otherwise: string().required(t('asset:Required')),
        }),
    domain: string(),
  };

  let customFieldsSchema = getCustomFieldsSchema(t, customFields);

  customFieldsSchema = Object.assign(
    {},
    initialFieldsSchema,
    customFieldsSchema
  );

  return object().shape(customFieldsSchema);
};

export const fieldAssetValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) => {
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    ip_address: string().matches(
      Regex.ip,
      t('asset:Please enter a valid IP Address')
    ),
    priority: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    description: string(),
  };

  let customFieldsSchema = getCustomFieldsSchema(t, customFields);

  customFieldsSchema = Object.assign(
    {},
    initialFieldsSchema,
    customFieldsSchema
  );

  return object().shape(customFieldsSchema);
};

export const multipleComputeAssetValidationSchema = (
  t: TFunction,
  customFields: CustomField[],
  schema?: (t: TFunction, customFields: CustomField[]) => SchemaOf<object>,
  extend?: boolean
) => {
  const _schema = schema || computeAssetValidationSchema;
  if (extend && schema) {
    return object().shape({
      assets: array().of(
        object().shape({
          values: computeAssetValidationSchema(t, []).concat(
            schema(t, customFields)
          ),
        })
      ),
    });
  }
  return object().shape({
    assets: array().of(object().shape({ values: _schema(t, customFields) })),
  });
};

export const plcDcsAssetValidationSchema =
  (isFCN: boolean, disableConnection?: boolean) =>
  (t: TFunction, customFields: CustomField[]) => {
    let customFieldsSchema = getCustomFieldsSchema(t, customFields);
    let initialFieldsSchema: any = isFCN
      ? {
          name: string()
            .required(t('Required'))
            .max(50, t('Max 50 characters'))
            .trim(t(NO_WHITESPACE_MSG))
            .strict(true),

          role: string()
            .required(t('asset:Required'))
            .max(50, t('asset:Max 50 characters'))
            .trim(t(NO_WHITESPACE_MSG))
            .strict(true),
          custodian: lazy(value => {
            if (typeof value === 'object') {
              return object().shape({
                id: string().required(t(REQUIRED_MSG)),
                name: string().required(t(REQUIRED_MSG)),
                type: string().required(t(REQUIRED_MSG)),
              });
            }
            return string().required(t(REQUIRED_MSG));
          }),
          ip_address: string()
            .matches(Regex.ip, t('Please enter a valid IP Address'))
            .required(t('Required')),
          priority: string().required(t('Required')),
          location: string()
            .required(t('asset:Required'))
            .max(50, t('asset:Max 50 characters'))
            .trim(t(NO_WHITESPACE_MSG))
            .strict(true),
          description: string(),
          timezone: string().required(t('Required')),
          username: string().required(t('Required')),
          password: disableConnection
            ? string().nullable()
            : string().required(t('Required')),
          ssh_hostname: string().when('connection_type', {
            is: 'ssh',
            then: string()
              .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
              .required(t('Required')),
            otherwise: string().notRequired().nullable(),
          }),
          ssh_username: string().when('connection_type', {
            is: 'ssh',
            then: string().required(t('Required')),
            otherwise: string().notRequired().nullable(),
          }),
          ssh_password: string().when('connection_type', {
            is: 'ssh',
            then: string().required(t('Required')),
            otherwise: string().notRequired().nullable(),
          }),
          ssh_port: number().when('connection_type', {
            is: 'ssh',
            then: number()
              .integer(t(PORT_RANGE_MSG))
              .min(1, t(PORT_RANGE_MSG))
              .max(65536, t(PORT_RANGE_MSG))
              .typeError(t(PORT_RANGE_MSG)),
            otherwise: number().notRequired().nullable(),
          }),
        }
      : {
          name: string()
            .required(t('Required'))
            .max(50, t('Max 50 characters'))
            .trim(t(NO_WHITESPACE_MSG))
            .strict(true),

          role: string()
            .required(t('asset:Required'))
            .max(50, t('asset:Max 50 characters'))
            .trim(t(NO_WHITESPACE_MSG))
            .strict(true),
          custodian: lazy(value => {
            if (typeof value === 'object') {
              return object().shape({
                id: string().required(t(REQUIRED_MSG)),
                name: string().required(t(REQUIRED_MSG)),
                type: string().required(t(REQUIRED_MSG)),
              });
            }
            return string().required(t(REQUIRED_MSG));
          }),
          ip_address: string()
            .matches(Regex.ip, t('Please enter a valid IP Address'))
            .required(t('Required')),
          priority: string().required(t('Required')),
          location: string()
            .required(t('asset:Required'))
            .max(50, t('asset:Max 50 characters'))
            .trim(t(NO_WHITESPACE_MSG))
            .strict(true),
          description: string(),
          domain_number: number()
            .typeError(t(STATION_DOMAIN_RANGE_MSG))
            .min(1, t(STATION_DOMAIN_RANGE_MSG))
            .max(64, t(STATION_DOMAIN_RANGE_MSG))
            .required(t(STATION_DOMAIN_RANGE_MSG)),
          station_number: number()
            .typeError(t(STATION_DOMAIN_RANGE_MSG))
            .min(1, t(STATION_DOMAIN_RANGE_MSG))
            .max(64, t(STATION_DOMAIN_RANGE_MSG))
            .required(t(STATION_DOMAIN_RANGE_MSG)),
          username: string().required(t('Required')),
          password: disableConnection
            ? string().nullable()
            : string().required(t('Required')),
        };
    return object().shape(
      Object.assign({}, initialFieldsSchema, customFieldsSchema)
    );
  };

export const multiplePlcDcsAssetValidationSchema = (
  t: TFunction,
  customFields: CustomField[],
  isFCN: boolean,
  disableConnection?: boolean
) =>
  object().shape({
    assets: array().of(
      object().shape({
        values: plcDcsAssetValidationSchema(isFCN, disableConnection)(
          t,
          customFields
        ),
      })
    ),
  });

export const envAssetValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) => {
  let customFieldsSchema = getCustomFieldsSchema(t, customFields);
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    ip_address: string()
      .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
      .required(t(REQUIRED_MSG)),
    priority: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    description: string(),
    username: string().required(REQUIRED_MSG),
    password: string().required(REQUIRED_MSG),
  };
  customFieldsSchema = Object.assign(
    {},
    initialFieldsSchema,
    customFieldsSchema
  );
  return object().shape(customFieldsSchema);
};

export const multipleEnvAssetValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) =>
  object().shape({
    assets: array().of(
      object().shape({ values: envAssetValidationSchema(t, customFields) })
    ),
  });

export const fieldAssetDiscoveryValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) => {
  let customFieldsSchema = getCustomFieldsSchema(t, customFields);
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    priority: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    description: string(),
  };
  customFieldsSchema = Object.assign(
    {},
    initialFieldsSchema,
    customFieldsSchema
  );
  return object().shape(customFieldsSchema);
};

export const multiplefieldAssetDiscoveryValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) =>
  object().shape({
    fieldassetsdiscovery: array().of(
      object().shape({
        values: fieldAssetDiscoveryValidationSchema(t, customFields),
      })
    ),
  });

export const networkAssetValidationSchema = (
  t: TFunction,
  customFields: CustomField[],
  disableConnection?: boolean
) => {
  let customFieldsSchema = getCustomFieldsSchema(t, customFields);
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    ip_address: string()
      .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
      .required(t(REQUIRED_MSG)),
    priority: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    brand: string().required(t(REQUIRED_MSG)),
    description: string(),
    syslog_host: string().max(100, t(MAX_LENGTH_MSG, { maxLength: 100 })),
    series: number().required(t('asset:Please select a model/series')),
    version: string().required(t(REQUIRED_MSG)),
    security_level: string().when('version', {
      is: (v: string) => ['3'].includes(v),
      then: string().required(t(REQUIRED_MSG)),
      otherwise: string().notRequired(),
    }),
    community: string().when('version', {
      is: (v: string) => ['1', '2'].includes(v),
      then: string().required(t('Required')),
      otherwise: string().notRequired(),
    }),
    security_username: string().when('version', {
      is: (v: string) => ['3'].includes(v),
      then: string().when('security_level', {
        is: (v: string) =>
          [
            'auth_without_privacy',
            'auth_with_privacy',
            'no_auth_or_privacy',
          ].includes(v),
        then: string().required(t('Required')),
        otherwise: string().notRequired(),
      }),
      otherwise: string().notRequired(),
    }),
    auth_password: disableConnection
      ? string().nullable()
      : string().when('version', {
          is: (v: string) => ['3'].includes(v),
          then: string()
            .when('security_level', {
              is: (v: string) =>
                ['auth_without_privacy', 'auth_with_privacy'].includes(v),
              then: string().required(t('Required')).nullable(),
              otherwise: string().notRequired(),
            })
            .nullable(),
          otherwise: string().notRequired(),
        }),
    privacy_password: disableConnection
      ? string().nullable()
      : string()
          .when('version', {
            is: (v: string) => ['3'].includes(v),
            then: string()
              .when('security_level', {
                is: (v: string) => ['auth_with_privacy'].includes(v),
                then: string().required(t('Required')).nullable(),
                otherwise: string().notRequired().nullable(),
              })
              .nullable(),
            otherwise: string().notRequired().nullable(),
          })
          .nullable(),
  };

  const sshSchema = object().shape({
    ssh_username: string().required(t('Required')),
    ssh_password: string().required(t('Required')),
    ssh_port: number().required(t('Required')),
    ssh_passphrase: string(),
    ssh_private_key: string().required(t('Required')),
    ssh_public_key: string().required(t('Required')),
  });

  const telnetSchema = object().shape({
    telnet_username: string().required(t('Required')),
    telnet_password: string().required(t('Required')),
    telnet_port: number().required(t('Required')),
  });

  const networkConfigSchema = object().shape({
    network_config_mode: string(),
  });

  const connectionTypeSchema = networkConfigSchema.when('network_config_mode', {
    is: 'telnet',
    then: networkConfigSchema.concat(telnetSchema),
    otherwise: object().when('network_config_mode', {
      is: 'ssh',
      then: networkConfigSchema.concat(sshSchema),
      otherwise: networkConfigSchema,
    }),
  });

  return object()
    .shape({
      ...initialFieldsSchema,
      ...customFieldsSchema,
    })
    .concat(connectionTypeSchema);
};

export const multipleNetworkAssetValidationSchema = (
  t: TFunction,
  customFields: CustomField[],
  disableConnection?: boolean
) =>
  object().shape({
    assets: array().of(
      object().shape({
        values: networkAssetValidationSchema(
          t,
          customFields,
          disableConnection
        ),
      })
    ),
  });

export const rdpValidationSchema = (t: TFunction) =>
  object().shape({
    name: string()
      .required(t('Required'))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    enabled: boolean().required(),
    asset: string().required(),
    protocol: string().matches(/^rdp$/).required(),
    settings: object()
      .shape({
        port: number()
          .integer(t(PORT_RANGE_MSG))
          .min(1, t(PORT_RANGE_MSG))
          .max(65536, t(PORT_RANGE_MSG))
          .typeError(t(PORT_RANGE_MSG)),
        domain: string(),
        username: string(),
        password: string(),
        security: string().oneOf([
          'any',
          'nla',
          'nla-ext',
          'tls',
          'vmconnect',
          'rdp',
        ]),
        'disable-auth': boolean(),
        'ignore-cert': boolean(),
        console: boolean(),
        'gateway-hostname': string(),
        'gateway-port': number()
          .integer(t(PORT_RANGE_MSG))
          .min(1, t(PORT_RANGE_MSG))
          .max(65536, t(PORT_RANGE_MSG))
          .typeError(t(PORT_RANGE_MSG)),
        'gateway-username': string(),
        'gateway-password': string(),
        'gateway-domain': string(),
        'initial-program': string(),
        'client-name': string(),
        'server-layout': string().oneOf([
          'any',
          'en-us-qwerty',
          'en-gb-qwerty',
          'de-ch-qwerty',
          'de-de-qwertz',
          'fr-be-azerty',
          'fr-fr-azerty',
          'fr-ch-qwertz',
          'hu-hu-qwertz',
          'it-it-qwerty',
          'ja-jp-qwerty',
          'pt-br-qwerty',
          'es-es-qwerty',
          'es-latam-qwerty',
          'sv-se-qwerty',
          'tr-tr-qwerty',
          'failsafe',
        ]),
        timezone: string(),
        'color-depth': number().oneOf([8, 16, 24, 256]),
        width: number().integer(t(INTEGER_MSG)).typeError(t(INTEGER_MSG)),
        height: number().integer(t(INTEGER_MSG)).typeError(t(INTEGER_MSG)),
        dpi: number().integer(t(INTEGER_MSG)).typeError(t(INTEGER_MSG)),
        'resize-method': string().oneOf(['display-update', 'reconnect']),
        'console-audio': boolean(),
        'disable-audio': boolean(),
        'enable-audio-input': boolean(),
        'enable-printing': boolean(),
        'printer-name': string(),
        'disable-copy': boolean(),
        'disable-paste': boolean(),
        'normalize-clipboard': string().oneOf(['windows', 'unix', 'preserve']),
        'enable-wallpaper': boolean(),
        'enable-theming': boolean(),
        'enable-font-smoothing': boolean(),
        'enable-full-window-drag': boolean(),
        'enable-desktop-composition': boolean(),
        'enable-menu-animations': boolean(),
        'disable-bitmap-caching': boolean(),
        'disable-offscreen-caching': boolean(),
        'disable-glyph-caching': boolean(),
        'remote-app': string(),
        'remote-app-dir': string(),
        'remote-app-args': string(),
        'preconnection-id': number()
          .integer(t(INTEGER_MSG))
          .typeError(t(INTEGER_MSG)),
        'preconnection-blob': string(),
      })
      .required(),
  });
export const webValidationSchema = (t: TFunction) =>
  object().shape({
    name: string()
      .required(t(REQUIRED_MSG))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    enabled: boolean().required(t(REQUIRED_MSG)),
    asset: string().required(t(REQUIRED_MSG)),
    protocol: string().matches(/^web$/).required(t(REQUIRED_MSG)),
    settings: object()
      .shape({
        url: string()
          .url()
          .test(
            'Please enter a valid URL',
            'Please enter a valid URL',
            value => !containsDollarSign(value)
          ),
        limit_network: boolean(),
        hosts: string(),
        'disable-copy': boolean(),
        'disable-paste': boolean(),
      })
      .required(t(REQUIRED_MSG)),
  });
export const vncValidationSchema = (t: TFunction) =>
  object().shape({
    name: string()
      .required(t('Required'))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    enabled: boolean().required(),
    asset: string().required(),
    protocol: string().matches(/^vnc$/).required(),
    settings: object()
      .shape({
        port: number()
          .integer(t(PORT_RANGE_MSG))
          .min(1, t(PORT_RANGE_MSG))
          .max(65536, t(PORT_RANGE_MSG))
          .typeError(t(PORT_RANGE_MSG)),
        username: string(),
        password: string(),
        cursor: string().oneOf(['local', 'remote']),
        'color-depth': number().oneOf([8, 16, 24, 256]),
        'read-only': boolean(),
        'swap-red-blue': boolean(),
        'enable-audio': boolean(),
        'audio-servername': string(),
        'clipboard-encoding': string().oneOf([
          'ISO8859-1',
          'UTF-8',
          'UTF-16',
          'CP1252',
        ]),
        'disable-copy': boolean(),
        'disable-paste': boolean(),
      })
      .required(),
  });

export const sshValidationSchema = (t: TFunction) =>
  object().shape({
    name: string()
      .required(t('Required'))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    enabled: boolean().required(),
    asset: string().required(),
    protocol: string().matches(/^ssh$/).required(),
    settings: object()
      .shape({
        port: number()
          .integer(t(PORT_RANGE_MSG))
          .min(1, t(PORT_RANGE_MSG))
          .max(65536, t(PORT_RANGE_MSG))
          .typeError(t(PORT_RANGE_MSG)),
        'host-key': string(),
        username: string(),
        password: string(),
        private_key: string(),
        passphrase: string(),
        color_scheme: string().oneOf([
          'black-white',
          'gray-black',
          'green-black',
          'white-black',
        ]),
        'disable-copy': boolean(),
        'disable-paste': boolean(),
        backspace: string(),
      })
      .required(),
  });

export const applicationWsusValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) => {
  let customFieldsSchema = getCustomFieldsSchema(t, customFields);
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    ip_address: string()
      .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
      .required(t(REQUIRED_MSG)),
    priority: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    description: string(),
    domain: string(),
    username: string(),
    password: string(),
    database_ip: string()
      .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
      .required(t(REQUIRED_MSG)),
    database_port: number()
      .integer(t(PORT_RANGE_MSG))
      .min(1, t(PORT_RANGE_MSG))
      .max(65536, t(PORT_RANGE_MSG))
      .typeError(t(PORT_RANGE_MSG))
      .required(t(REQUIRED_MSG)),
  };
  customFieldsSchema = Object.assign(
    {},
    initialFieldsSchema,
    customFieldsSchema
  );
  return object().shape(customFieldsSchema);
};

export const multipleApplicationWsusValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) =>
  object().shape({
    assets: array().of(
      object().shape({
        values: applicationWsusValidationSchema(t, customFields),
      })
    ),
  });

export const applicationEpoValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) => {
  let customFieldsSchema = getCustomFieldsSchema(t, customFields);
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    ip_address: string()
      .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
      .required(t(REQUIRED_MSG)),
    priority: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    description: string(),
    username: string().required(t('asset:Required')),
    password: string().required(t('asset:Required')),
    api_url: string()
      .url(t('asset:Please enter a valid URL'))
      .required(t(REQUIRED_MSG)),
  };
  customFieldsSchema = Object.assign(
    {},
    initialFieldsSchema,
    customFieldsSchema
  );
  return object().shape(customFieldsSchema);
};

export const multipleApplicationEpoValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) =>
  object().shape({
    assets: array().of(
      object().shape({
        values: applicationEpoValidationSchema(t, customFields),
      })
    ),
  });

export const multipleApplicationVeeamValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) =>
  object().shape({
    assets: array().of(
      object().shape({
        values: applicationEpoValidationSchema(t, customFields),
      })
    ),
  });

export const multipleApplicationClarotyValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) =>
  object().shape({
    assets: array().of(
      object().shape({
        values: applicationEpoValidationSchema(t, customFields),
      })
    ),
  });
export const multipleApplicationNozomiValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) =>
  object().shape({
    assets: array().of(
      object().shape({
        values: applicationEpoValidationSchema(t, customFields),
      })
    ),
  });

export const controlApplicationValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) => {
  let customFieldsSchema = getCustomFieldsSchema(t, customFields);
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    ip_address: string()
      .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
      .required(t(REQUIRED_MSG)),
    priority: string().required(t(REQUIRED_MSG)),
    timezone: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    description: string(),
    username: string(),
    password: string(),
    ip_addresses: array().of(
      string().matches(Regex.ip, t('asset:Please enter a valid IP Address'))
    ),
  };
  customFieldsSchema = Object.assign(
    {},
    initialFieldsSchema,
    customFieldsSchema
  );
  return object().shape(customFieldsSchema);
};

export const multipleControlApplicationValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) =>
  object().shape({
    assets: array().of(
      object().shape({
        values: controlApplicationValidationSchema(t, customFields),
      })
    ),
  });

export const isaeApplicationValidationSchema = (
  t: TFunction,
  customFields: CustomField[]
) => {
  let customFieldsSchema = getCustomFieldsSchema(t, customFields);
  let initialFieldsSchema: any = {
    name: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),

    role: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    custodian: lazy(value => {
      if (typeof value === 'object') {
        return object().shape({
          id: string().required(t(REQUIRED_MSG)),
          name: string().required(t(REQUIRED_MSG)),
          type: string().required(t(REQUIRED_MSG)),
        });
      }
      return string().required(t(REQUIRED_MSG));
    }),
    ip_address: string()
      .matches(Regex.ip, t('asset:Please enter a valid IP Address'))
      .required(t(REQUIRED_MSG)),
    isae_port: number()
      .required(t(REQUIRED_MSG))
      .integer(t(PORT_RANGE_MSG))
      .min(1, t(PORT_RANGE_MSG))
      .max(65536, t(PORT_RANGE_MSG))
      .typeError(t(PORT_RANGE_MSG)),

    priority: string().required(t(REQUIRED_MSG)),
    location: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 }))
      .trim(t(NO_WHITESPACE_MSG))
      .strict(true),
    description: string(),
    domain: string(),
    username: string(),
    password: string(),
  };
  customFieldsSchema = Object.assign(
    {},
    initialFieldsSchema,
    customFieldsSchema
  );
  return object().shape(customFieldsSchema);
};

const didStringValidation = object({
  enabled: boolean(),
  did: string().when('enabled', {
    is: (val: boolean | string) => val === true || val === 'true',
    then: string().max(50).required(REQUIRED_MSG),
    otherwise: string().max(50),
  }),
});

const didParameterValidation = object({
  enabled: boolean(),
  did: array().when('enabled', {
    is: (val: boolean | string) => val === true || val === 'true',
    then: array().of(
      object({
        key: string().min(1).required(REQUIRED_MSG),
        title: string().min(1).required(REQUIRED_MSG),
      })
    ),
    otherwise: array().of(
      object({
        key: string(),
        title: string(),
      })
    ),
  }),
});

export const isaeConfigValidationSchema = (t: TFunction) =>
  object().shape({
    port: number()
      .integer(t(PORT_RANGE_MSG))
      .min(1, t(PORT_RANGE_MSG))
      .max(65536, t(PORT_RANGE_MSG))
      .typeError(t(PORT_RANGE_MSG)),
    device_id: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 })),
    device_tag: string()
      .required(t(REQUIRED_MSG))
      .max(50, t(MAX_LENGTH_MSG, { maxLength: 50 })),
    asset_config: object({
      controllability: didStringValidation,
      hunting: didStringValidation,
      linkage: didStringValidation,
      stiction: didStringValidation,
      inadequate_air: didStringValidation,
      parameter: didParameterValidation,
    }),
  });

const ipv4Regex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const ipv6Regex =
  /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

export type StringValidationConfig = Pick<
  StringConfig,
  'maxLength' | 'isIP' | 'isURL' | 'isEmail' | 'required'
>;
export const stringValidation = (types?: StringValidationConfig) => {
  const baseValidation = string();
  if (!types) return baseValidation;

  const validations: Record<keyof typeof types, StringSchema> = {
    required: string().required('Required'),
    maxLength: string().max(types.maxLength ?? 1, 'Max (maxLength) characters'),
    isIP: string().test(
      'Please enter a valid IP Address',
      'Please enter a valid IP Address',
      (value = '') => ipv4Regex.test(value) || ipv6Regex.test(value)
    ),
    isURL: string()
      .url('Please enter a valid URL')
      .test(
        'Please enter a valid URL',
        'Please enter a valid URL',
        value => !containsDollarSign(value)
      ),
    isEmail: string().email('Please enter a valid email address'),
  };

  return Object.entries(types).reduce(
    (acc: StringSchema<any>, [key, value]) => {
      if (!!value) return acc.concat(validations[key as keyof typeof types]);
      return acc;
    },
    baseValidation
  );
};

export type NumberValidationConfig = Pick<
  NumberConfig,
  'min' | 'max' | 'isInt' | 'required' | 'nullable'
>;
export const numberValidation = (types?: NumberValidationConfig) => {
  const baseValidation = number();

  if (!types) {
    return baseValidation;
  }

  const isMinMax = types.min != null && types.max != null;
  const min = types.min ?? 0;
  const max = types.max ?? 0;

  const validations: Record<keyof typeof types | 'minMax', NumberSchema> = {
    min: number().min(min, 'Please enter a number of (MIN) or higher'),
    max: number().max(max, 'Please enter a number of (MAX) or lower'),
    minMax: number().min(min, MIN_MAX_RANGE_MSG).max(max, MIN_MAX_RANGE_MSG),
    isInt: number().integer(INTEGER_MSG).typeError(INTEGER_MSG),
    required: number().required(REQUIRED_MSG),
    // @ts-ignore
    nullable: number().optional().nullable(),
  };

  return Object.entries(types).reduce((acc: NumberSchema, [key, value]) => {
    if (!!value && !(key === 'max' && isMinMax)) {
      if (key === 'min' && isMinMax) {
        return acc.concat(validations.minMax);
      }

      return acc.concat(validations[key as keyof typeof types]);
    }
    return acc;
  }, baseValidation);
};

export const custodianValidation = () =>
  lazy(value => {
    if (typeof value === 'object') {
      return object().shape({
        id: stringValidation({ required: true }),
        name: stringValidation({ required: true }),
        type: stringValidation({ required: true }),
      });
    }
    return stringValidation({ required: true });
  });
