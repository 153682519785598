import React from 'react';
import { PlayCircle, PauseCircle, Slash, Circle } from 'react-feather';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export function prmStatusColor(status: number | string | null): string {
  if (status === null) return '#9e9e9e';
  const numericStatus = parseInt(String(status));
  switch (numericStatus) {
    case 20:
    case 40:
    case 50:
      return '#5eba00';
    case 10:
    case 30:
      return '#fa4654';
    default:
      return '#9e9e9e';
  }
}

const Container = styled.span`
  width: 32px;
  height: 22px;
  position: relative;
  margin: 0;
`;

const Wrapper = styled.div`
  bottom: -6px;
  right: 6px;
  position: absolute;
`;

type status_color = {
  [key: string]: string;
};
const status_colors: status_color = {
  up: '#5eba00',
  down: '#fa4654',
  'turned off': '#9e9e9e',
};

type OperationalStatusIconProps = {
  operationalStatus: string;
  heartbeatStatus?: string | number;
};

export default function OperationalStatusIcon({
  operationalStatus = '',
  heartbeatStatus = '',
}: OperationalStatusIconProps) {
  if (operationalStatus === 'operational') {
    return (
      <Container>
        <PlayCircle size="22" className="mr-2" />
        <Wrapper>
          <FontAwesomeIcon
            icon={faCircle}
            size="xs"
            color={status_colors[heartbeatStatus] ?? '#9e9e9e'}
          />
        </Wrapper>
      </Container>
    );
  }
  if (operationalStatus === 'maintenance') {
    return (
      <Container>
        <PauseCircle size="22" className="mr-2" />
        <Wrapper>
          <FontAwesomeIcon
            icon={faCircle}
            size="xs"
            color={status_colors[heartbeatStatus] ?? '#9e9e9e'}
          />
        </Wrapper>
      </Container>
    );
  }
  if (operationalStatus === 'prm') {
    return (
      <Container>
        <Circle size="22" className="mr-2" />
        <Wrapper>
          <FontAwesomeIcon
            icon={faCircle}
            size="xs"
            color={prmStatusColor(heartbeatStatus)}
          />
        </Wrapper>
      </Container>
    );
  }
  return (
    <Container>
      <Slash size="22" className="mr-2" />
      <Wrapper>
        <FontAwesomeIcon
          icon={faCircle}
          size="xs"
          color={status_colors[heartbeatStatus] ?? '#9e9e9e'}
        />
      </Wrapper>
    </Container>
  );
}
