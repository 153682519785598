import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import {
  Slash,
  Check,
  Clock,
  AlertTriangle,
  Shield,
  Search,
  Lock,
} from 'react-feather';
import SyncOutlined from '@ant-design/icons/SyncOutlined';

const Icon = styled.span`
  font-size: 18px;
`;
type StatusIconProps = {
  status: string;
  label?: string | JSX.Element;
  color?: string;
};
export default function StatusIcon({
  status,
  label,
  color = 'inherit',
}: StatusIconProps) {
  label = label || status;

  const icons: any = {
    new: <Clock size={20} color={color} />,
    uploading: <SyncOutlined spin color={color} />,
    upload_complete: <Lock color={color} />,
    syncing: <SyncOutlined spin color={color} />,
    ready: <Check />,
    expired: <Slash size={20} color={color} />,
    error: <AlertTriangle size={20} color={color} />,
    quarantine: <Shield size={20} color={color} />,
    scanning: <Search size={20} color={color} />,
  };

  return (
    <Tooltip title={label}>
      <Icon>{icons[status]}</Icon>
    </Tooltip>
  );
}
