import Api from 'common/services/Api';
import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import { parseDateRange } from 'common/utils';
import { includeFilters } from '../../common/utils/elasticHelpers';

export async function fetchFieldAssets({
  assetId,
  index,
  pagination,
  sort,
  filter,
}) {
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'device_id',
        'device_tag',
        'category',
        'model',
        'communication_type',
        'vendor',
        '@timestamp',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'inventory',
            },
          },
          {
            range: {
              '@timestamp': {
                gte: 'now-49h',
                lte: 'now',
              },
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchFieldAssetCategories({ index, id }) {
  const query = {
    aggs: {
      device_tag: {
        terms: {
          field: 'device_tag.keyword',
          size: 15,
          order: {
            _key: 'asc',
          },
        },
      },
      vendor: {
        terms: {
          field: 'vendor.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      model: {
        terms: {
          field: 'model.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      communication_type: {
        terms: {
          field: 'communication_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      category: {
        terms: {
          field: 'category.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'inventory',
            },
          },
          {
            range: {
              '@timestamp': {
                gte: 'now-49h',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchDiagnosticEvents({
  assetId,
  index,
  start,
  end,
  pagination,
  sort,
  filter,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'msg_rank_str',
        'event_id',
        'event_type',
        'event_group',
        'device_tag',
        'message',
        'timestamp',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'diagnostic_events',
            },
          },
          {
            range: {
              timestamp: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchDiagnosticEventsCategories({
  index,
  id,
  start,
  end,
}) {
  const query = {
    aggs: {
      event_id: {
        terms: {
          field: 'event_id',
          size: 1000,
          order: {
            _key: 'asc',
          },
        },
      },
      msg_rank_str: {
        terms: {
          field: 'msg_rank_str.keyword',
          size: 15,
          order: {
            _key: 'asc',
          },
        },
      },
      event_type: {
        terms: {
          field: 'event_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      device_tag: {
        terms: {
          field: 'device_tag.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'diagnostic_events',
            },
          },
          {
            range: {
              timestamp: {
                gte: start,
                lte: end,
              },
            },
          },
        ],
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchDiagnosticEventsDetail({ id, event_id, index }) {
  const query = {
    size: 10000,
    sort: {
      timestamp: 'asc',
    },
    _source: {
      includes: [
        'msg_rank_str',
        'event_id',
        'event_type',
        'event_group',
        'device_tag',
        'message',
        'timestamp',
        'reason',
        'system',
        'user_id',
        'maint_alarm_details',
        'fe_id',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'diagnostic_events',
            },
          },
          // {
          //   match: {
          //     fe_id: '57',
          //   },
          // },
          {
            match: {
              event_id,
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchMaintenanceEvents({
  assetId,
  index,
  start,
  end,
  pagination,
  sort,
  filter,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'msg_rank_str',
        'event_id',
        'event_type',
        'event_group',
        'device_tag',
        'message',
        'timestamp',
        'mss',
        'system',
        'ack',
        'event-namur_status',
        'event-namur_status_script'
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'maintenance_events',
            },
          },
          {
            range: {
              timestamp: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchMaintenanceEventsCategories({
  index,
  id,
  start,
  end,
}) {
  const query = {
    aggs: {
      msg_rank_str: {
        terms: {
          field: 'msg_rank_str.keyword',
          size: 15,
          order: {
            _key: 'asc',
          },
        },
      },
      event_type: {
        terms: {
          field: 'event_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      device_tag: {
        terms: {
          field: 'device_tag.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      system: {
        terms: {
          field: 'system.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      event_id: {
        terms: {
          field: 'event_id',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      ack: {
        terms: {
          field: 'ack',
          size: 10,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'maintenance_events',
            },
          },
          {
            range: {
              timestamp: {
                gte: start,
                lte: end,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchMaintenanceEventsDetail({ id, event_id, index }) {
  const query = {
    size: 10000,
    sort: {
      timestamp: 'asc',
    },
    _source: {
      includes: [
        'msg_rank_str',
        'event_id',
        'event_type',
        'event_group',
        'device_tag',
        'message',
        'timestamp',
        'reason',
        'system',
        'user_id',
        'maint_alarm_details',
        'fe_id',
        'mss',
        'event-namur_status',
        'event-namur_status_script'
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'maintenance_events',
            },
          },
          // {
          //   match: {
          //     fe_id: feID,
          //   },
          // },
          {
            match: {
              event_id,
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchConfigurationEvents({
  assetId,
  index,
  start,
  end,
  pagination,
  sort,
  filter,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'user_id',
        'event_id',
        'event_type',
        'event_group',
        'device_tag',
        'message',
        'timestamp',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'configuration_events',
            },
          },
          {
            range: {
              timestamp: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchConfigurationEventsCategories({
  index,
  id,
  start,
  end,
}) {
  const query = {
    aggs: {
      event_type: {
        terms: {
          field: 'event_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      device_tag: {
        terms: {
          field: 'device_tag.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      user_id: {
        terms: {
          field: 'user_id.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      event_id: {
        terms: {
          field: 'event_id',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'configuration_events',
            },
          },
          {
            range: {
              timestamp: {
                gte: start,
                lte: end,
              },
            },
          },
        ],
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchConfiturationEventsDetail({ id, event_id, index }) {
  const query = {
    size: 10000,
    sort: {
      timestamp: 'asc',
    },
    _source: {
      includes: [
        'event_id',
        'event_type',
        'event_group',
        'device_tag',
        'message',
        'timestamp',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'configuration_events',
            },
          },
          {
            match: {
              event_id,
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchPASEvents({
  assetId,
  index,
  start,
  end,
  pagination,
  sort,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'event_id',
        'msg_no',
        'timestamp',
        'system',
        'source',
        'msg_rank_str',
        'message',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'pas_events',
            },
          },
          {
            range: {
              timestamp: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchActivityLogs({
  assetId,
  index,
  start,
  end,
  pagination,
  sort,
  filter,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'event_id',
        'event_type',
        'event_group',
        'device_tag',
        'user_id',
        'message',
        'timestamp',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'audit_log',
            },
          },
          {
            range: {
              timestamp: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchActivityLogsCategories({ index, id, start, end }) {
  const query = {
    aggs: {
      event_type: {
        terms: {
          field: 'event_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      user_id: {
        terms: {
          field: 'user_id.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      event_id: {
        terms: {
          field: 'event_id',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'audit_log',
            },
          },
          {
            range: {
              timestamp: {
                gte: start,
                lte: end,
              },
            },
          },
        ],
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchApplicationLogsDetail({ id, event_id, index }) {
  const query = {
    size: 10000,
    sort: {
      timestamp: 'asc',
    },
    _source: {
      includes: [
        'msg_rank_str',
        'event_id',
        'event_type',
        'event_group',
        'device_tag',
        'message',
        'timestamp',
        'reason',
        'system',
        'user_id',
        'maint_alarm_details',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'application_log',
            },
          },
          {
            match: {
              event_id,
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchApplicationLogs({
  assetId,
  index,
  start,
  end,
  pagination,
  filter,
  sort,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'msg_rank_str',
        'event_id',
        'event_type',
        'event_group',
        'message',
        'timestamp',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'application_log',
            },
          },
          {
            range: {
              timestamp: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchApplicationLogCategories({ index, id, start, end }) {
  const query = {
    aggs: {
      event_type: {
        terms: {
          field: 'event_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      msg_rank_str: {
        terms: {
          field: 'msg_rank_str.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      event_id: {
        terms: {
          field: 'event_id',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'application_log',
            },
          },
          {
            range: {
              timestamp: {
                gte: start,
                lte: end,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAuthenticationLogs({
  assetId,
  index,
  start,
  end,
  pagination,
  sort,
  filter,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'event_id',
        'event_type',
        'event_group',
        'message',
        'timestamp',
        'user_id',
        'mss_name',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'auth_log',
            },
          },
          {
            range: {
              timestamp: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAuthenticationLogsCategories({
  index,
  start,
  end,
  id,
}) {
  const query = {
    aggs: {
      event_type: {
        terms: {
          field: 'event_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      user_id: {
        terms: {
          field: 'user_id.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
          exclude: [''],
        },
      },
      'mss.name': {
        terms: {
          field: 'mss.name.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      event_id: {
        terms: {
          field: 'event_id',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': id,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'auth_log',
            },
          },
          {
            range: {
              timestamp: {
                gte: start,
                lte: end,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchApplicationUsers({
  assetId,
  index,
  pagination,
  sort,
}) {
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'user_id',
        'user_group',
        'full_name',
        'description',
        '@timestamp',
        'mss',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match_phrase: {
              'mss.collector_key': 'users',
            },
          },
          {
            range: {
              '@timestamp': {
                gte: 'now-49h',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchHeartbeatChartData({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            range: {
              '@timestamp': {
                gte: 'now-30d',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
    aggs: {
      state_over_time: {
        date_histogram: {
          field: '@timestamp',
          calendar_interval: '1d',
        },
        aggs: {
          state_aggregation: {
            terms: {
              field: 'state.keyword',
            },
          },
          up_percentage: {
            bucket_script: {
              buckets_path: {
                up: "state_aggregation['up']>_count",
                down: "state_aggregation['down']>_count",
              },
              gap_policy: 'insert_zeros',
              script: {
                source:
                  'if (params.down == null && params.up == null) {-1} else if (params.down == null) {1} else if (params.up == null) {0} else {params.up / (params.up + params.down)}',
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchDiagnosticStatus({ index, assetId }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
        ],
        must_not: [
          {
            match: {
              'mss.collector_key.keyword': 'parameter',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-49h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      field_device: {
        terms: {
          field: 'mss.device_id.keyword',
        },
        aggs: {
          diagnostic: {
            terms: {
              field: 'mss.collector_key.keyword',
            },
            aggs: {
              top_uids_hits: {
                top_hits: {
                  sort: [
                    {
                      timestamp: {
                        order: 'desc',
                      },
                    },
                  ],
                  size: 1,
                  _source: {
                    includes: [
                      'value',
                      'mss.device_tag',
                      'mss.collector_key',
                      'timestamp',
                    ],
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchParameterStatus({ index, assetId }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key.keyword': 'parameter',
            },
          },
        ],
        must_not: [
          {
            match: {
              'mss.parameter.keyword': '00',
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: 'now-49h',
              lte: 'now',
            },
          },
        },
      },
    },
    aggs: {
      field_device: {
        terms: {
          field: 'mss.device_id.keyword',
        },
        aggs: {
          diagnostic: {
            terms: {
              field: 'mss.parameter.keyword',
            },
            aggs: {
              top_uids_hits: {
                top_hits: {
                  sort: [
                    {
                      timestamp: {
                        order: 'desc',
                      },
                    },
                  ],
                  size: 1,
                  _source: {
                    includes: [
                      'value',
                      'mss.device_tag',
                      'mss.parameter',
                      'timestamp',
                    ],
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchAssetManagementApplication(
  assetId: string,
): Promise<any> {
  return Api.get(`/assets/${assetId}/`);
}

export async function fetchAssetManagementApplications(parameters: {
  site: string;
}): Promise<any> {
  parameters['type'] = 'asset_management';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}

export async function fetchAssetManagementApplicationsById(): Promise<any> {
  return Api.get(
    `/assets/autocomplete/name/?model_name=AssetManagementApplicationPRM`,
  );
}
