import { Fetch } from 'utils';
import { Site } from 'types';

export type SandboxAction = 'site-tab-filetransfer' | 'xterm';

export type SandboxApiType = {
  exec: (action: SandboxAction, params?: any) => any;
};

export const SandboxApi: (
  sessionId: string,
  site?: Site | undefined
) => SandboxApiType = (sessionId: string, site?: Site) => ({
  appContext: site ? 'center' : 'site',
  isCenter: !!site,
  isSite: !site,
  exec: async (action, params) => {
    const body = JSON.stringify({
      action,
      params,
      refresh: sessionStorage.getItem('refresh-token'),
    });
    const result = await Fetch(site?.id).post(
      `/api/remote-session/${sessionId}/sandbox_exec/`,
      { body }
    );
    if (result.status >= 400) throw Error(result.statusText);
    const data = await result.json();
    return data;
  },
});
