import React from 'react';

function Brave({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 112.4 131.9"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={-475.07}
          x2={-474.07}
          y1={675.83}
          y2={675.83}
          gradientTransform="matrix(112.31 0 0 -131.86 53355.92 89180.8)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f50" />
          <stop offset={0.41} stopColor="#f50" />
          <stop offset={0.58} stopColor="#ff2000" />
          <stop offset={1} stopColor="#ff2000" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={-474.21}
          x2={-473.23}
          y1={659.13}
          y2={659.13}
          gradientTransform="matrix(93.22 0 0 -23.76 44217.1 15672.73)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ff452a" />
          <stop offset={1} stopColor="#ff2000" />
        </linearGradient>
      </defs>
      <g data-name="Calque 2">
        <g data-name="Layer 1">
          <g data-name="build-icons/Stable">
            <path
              fill="url(#prefix__a)"
              d="M108.4 31.6l3.1-7.6s-3.9-4.2-8.7-9-14.9-2-14.9-2L76.4 0H35.9L24.4 13.1s-10.1-2.8-14.9 2-8.7 9-8.7 9l3.1 7.6L0 42.9s11.6 43.8 12.9 49.2c2.7 10.5 4.5 14.6 12.1 20s21.3 14.6 23.6 16 5.1 3.8 7.6 3.8 5.3-2.4 7.6-3.8 16-10.7 23.6-16 9.4-9.4 12.1-20c1.4-5.4 12.9-49.2 12.9-49.2z"
            />
            <path
              fill="#fff"
              d="M84.7 21.4s14.8 17.9 14.8 21.8-1.9 4.8-3.8 6.8-10.1 10.7-11.1 11.8-3.2 2.8-2 5.9 3.2 6.9 1.1 10.9-5.7 6.6-8 6.1-7.8-3.3-9.8-4.6-8.3-6.5-8.3-8.5 6.6-5.6 7.8-6.4 6.7-4 6.8-5.2.1-1.6-1.6-4.7-4.6-7.2-4.1-9.9 5.2-4.1 8.6-5.4 9.9-3.7 10.7-4.1.6-.7-1.9-1-9.4-1.2-12.6-.3-8.5 2.2-9 2.9-.8.7-.4 3.2 2.8 14.2 3 16.3.7 3.5-1.6 4a56.78 56.78 0 01-7.3 1.4 56.78 56.78 0 01-7.3-1.4c-2.2-.5-1.8-1.9-1.6-4s2.5-13.8 3-16.3.1-2.5-.4-3.2-5.8-2-9-2.9-10.1 0-12.6.3-2.7.6-1.9 1 7.3 2.8 10.7 4.1 8.1 2.7 8.6 5.4-2.4 6.8-4.1 9.9-1.7 3.4-1.6 4.7 5.6 4.4 6.8 5.2 7.8 4.4 7.8 6.4-6.3 7.2-8.3 8.5-7.4 4.1-9.8 4.6-5.9-2.2-8-6.1-.2-7.8 1.1-10.9-.9-4.7-2-5.9c-.8-1.2-8.9-9.9-10.8-11.8s-3.7-3-3.7-6.8 14.8-21.8 14.8-21.8 12.5 2.4 14.2 2.4 5.3-1.4 8.7-2.5a20.16 20.16 0 015.6-1.1 20.16 20.16 0 015.6 1.1c3.4 1.1 7 2.5 8.7 2.5s14.2-2.4 14.2-2.4zM73.6 89.9c.9.6.4 1.7-.5 2.2S61 101.4 60 102.4s-2.7 2.5-3.8 2.5-2.7-1.6-3.8-2.5-12.3-9.7-13.1-10.3-1.4-1.7-.5-2.2 3.8-2 7.7-4.1 8.9-3.8 9.7-3.8 5.7 1.7 9.7 3.8 6.7 3.6 7.7 4.1z"
            />
            <path
              fill="url(#prefix__b)"
              d="M87.9 13.1L76.4 0H35.9L24.4 13.1s-10.1-2.8-14.9 2c0 0 13.5-1.2 18.1 6.3 0 0 12.5 2.4 14.2 2.4s5.3-1.4 8.7-2.5a20.16 20.16 0 015.6-1.1 20.16 20.16 0 015.6 1.1c3.4 1.1 7 2.5 8.7 2.5s14.2-2.4 14.2-2.4c4.6-7.5 18.1-6.3 18.1-6.3-4.7-4.8-14.8-2-14.8-2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default Brave;
