import React from 'react';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageContent } from '../../../../containers';
import { Loading, Segment } from '../../../../components';
import { getAgentInstallerLink } from './getDownloadLink';

export default function Setup({ id }: { id?: string }) {
  const { t } = useTranslation(['common', 'asset']);

  return (
    <PageContent title={t('Setup Agent')}>
      <Segment border padding>
        {!id && <Loading />}
        {id && (
          <>
            <div>
              <strong>
                {t('common:Download')} &amp; {t('common:install')}
              </strong>
            </div>
            <div className="ml-6 mt-6">
              <Steps direction="vertical" size="small">
                <Steps.Step
                  status={'process'}
                  title={
                    <>
                      <a
                        className="text-primary"
                        href={`/api/assets/compute/agent/${id}/download/`}
                        onClick={async (e: any) => {
                          e.preventDefault();
                          const link = await getAgentInstallerLink(id);
                          window.location.href = link.url;
                        }}
                      >
                        {t('asset:Download')}
                      </a>{' '}
                      {t('asset:the MSS Agent installer and configuration')}
                    </>
                  }
                />
                <Steps.Step
                  status={'process'}
                  title={
                    <>{t('Install the MSS Agent on the selected asset')}</>
                  }
                />
                <Steps.Step
                  status={'process'}
                  title={
                    <>
                      {t(
                        'Validate if collected data is presented at the center component'
                      )}
                    </>
                  }
                />
              </Steps>
            </div>
          </>
        )}
      </Segment>
    </PageContent>
  );
}
