import React from 'react';

const IconWug = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M62 49.689c0 .764-.444 1.418-1.109 1.855l-15.304 8.62V26.666l-29.5-16.693L31.39 1.245a2.628 2.628 0 0 1 2.218 0L62 17.393V49.69ZM39.043 30.377 18.86 18.921a2.628 2.628 0 0 0-2.218 0L3 26.667l21.293 12.111V63l13.641-7.747c.666-.436.998-1.09 1.11-1.854V30.377ZM3 50.889l14.64 8.401V42.488L3 50.889Z"
    />
  </svg>
);

export default IconWug;
