import Fetch from 'common/services/_fetch';

type AddBody = {
  user: string;
  group: number;
};

export const add = async (body: AddBody) => {
  return Fetch.post(`/api/user/group/`, {
    body: JSON.stringify(body),
  });
};
