import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import {
  defaultAssetInputs,
  userNamePassDomainInputs,
} from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';
import { AssetConfig, MicrosoftHyperVApplication } from '../../../../types';
import { emptyAssetFactory } from '../../../../utils';
import React from 'react';
import { TrendingUp } from 'react-feather';
import { virtualizationSettings } from './center/virtualization-settings';
import { eventLogs } from './center/event-logs';
import { virtualSwitches } from './center/virtual-switches';
import { virtualMachines } from './center/virtual-machines';
import { metrics } from './center/metrics';

export const hyperVConfig: AssetConfig<MicrosoftHyperVApplication> = {
  name: 'Microsoft - Hyper-V',
  type: 'microsoft_hyper_v',
  description: 'Onboard Microsoft Hyper-V assets for monitoring.',
  icon: faWindows,
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultAssetInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    method: 'put',
    initialValues: emptyAssetFactory<MicrosoftHyperVApplication>({
      domain: true,
      connectionTypes: ['direct'],
      assetType: 'virtualization_type',
      assetSubType: 'microsoft_hyper_v',
    }),
    configure: {
      inputs: defaultAssetInputs,
      schema: inputToValidationReducer(defaultAssetInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassDomainInputs),
        inputs: userNamePassDomainInputs,
        testConnection: {
          method: 'post',
          valueKeys: [...inputsToKeys(userNamePassDomainInputs), 'ip_address'],
          customKeyMapping: { ip_address: 'host' },
          endpoint: 'connection_test',
          isWMI: true,
        },
      },
    },
    collectors: {},
  },
  center: {
    pages: [
      {
        slug: 'overview',
        title: 'Overview',
        icon: <TrendingUp size={14} />,
        type: 'overview',
        overviewConfig: {
          hasHeartbeatChart: true,
          hasUptimeChart: false,
          widgets: [],
        },
      },
      virtualMachines,
      metrics,
      eventLogs,
      virtualSwitches,
      virtualizationSettings,
    ],
  },
};
