import { AssetConfig, NetworkTimeServerAsset } from '../../../../types';
import { defaultConfig } from '../default';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';

export const timeserverConfig: AssetConfig<NetworkTimeServerAsset> = {
  ...defaultConfig,
  name: 'Time Server',
  type: 'timeserver',
  description: 'Onboard a NTP time server via the SNMP and Syslog protocol.',
  icon: faClock,
  form: {
    ...defaultConfig.form,
    initialValues: emptyAssetFactory<NetworkTimeServerAsset>({
      domain: true,
      connectionTypes: ['snmp'],
      assetType: 'network_type',
      assetSubType: 'timeserver',
      extraFields: {
        brand: '',
        series: '',
      },
    }),
  },
};
