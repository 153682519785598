import { userNamePassInputs } from '../../defaults/inputs';
import { commonInputConfigs } from '../../defaults/commonInputConfigs';
import {
  AssetConfig,
  NumberConfig,
  PasswordConfig,
  StringConfig,
  TextConfig,
} from '../../../../types';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';
import { inputsToKeys } from '../../defaults/configKeys';

const select = {
  key: 'network_config_mode',
  label: 'Connection Type Configuration',
  nullable: true,
  reset: true,
};

const telnetInputs = [
  ...userNamePassInputs.map(x => {
    if (x.fieldName === 'username')
      return { ...x, fieldName: 'telnet_username' };
    if (x.fieldName === 'password')
      return { ...x, fieldName: 'telnet_password' };
    return x;
  }),
  commonInputConfigs.port({
    fieldName: 'telnet_port',
    required: true,
    compactWidth: 6,
    width: 12,
  }),
];

const sshInputs: (StringConfig | TextConfig | PasswordConfig | NumberConfig)[] =
  [
    ...userNamePassInputs.map(x => {
      if (x.fieldName === 'username')
        return { ...x, fieldName: 'ssh_username', required: false };
      if (x.fieldName === 'password')
        return { ...x, fieldName: 'ssh_password', required: false };
      return x;
    }),
    commonInputConfigs.port({
      fieldName: 'ssh_port',
      required: false,
      compactWidth: 6,
      width: 12,
    }),
    {
      fieldName: 'ssh_passphrase',
      label: 'Passphrase',
      type: 'string',
      compactWidth: 6,
      width: 12,
    },
    {
      fieldName: 'ssh_public_key',
      required: false,
      type: 'text',
      rows: 2,
      label: 'Public Key',
      compactWidth: 6,
      width: 12,
    },
    {
      fieldName: 'ssh_private_key',
      required: false,
      type: 'text',
      rows: 2,
      label: 'Private Key',
      compactWidth: 6,
      width: 12,
    },
  ];

export const telnetSshConfig: AssetConfig<any>['form']['connect'] = {
  telnet: {
    schema: inputToValidationReducer(telnetInputs, select.key, 'telnet'),
    select,
    inputs: telnetInputs,
    testConnection: {
      valueKeys: [...inputsToKeys(telnetInputs), 'ip_address'],
      customKeyMapping: { ip_address: 'host' },
      endpoint: 'telnet_connection_check',
    },
  },
  ssh: {
    // TODO in 1.10: Create an extra option for ssh key vs username/password with validation
    schema: inputToValidationReducer([], select.key, 'ssh'),
    select,
    inputs: sshInputs,
    testConnection: {
      valueKeys: [...inputsToKeys(sshInputs), 'ip_address'],
      customKeyMapping: { ip_address: 'host' },
      endpoint: 'ssh_connection_check',
    },
  },
};
