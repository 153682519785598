import { defaultConfig } from '../default';
import { AssetConfig, PlcDcsWACAsset } from '../../../../types';

const baseWACConfig = defaultConfig as AssetConfig<PlcDcsWACAsset>;

export const wacConfig: AssetConfig<PlcDcsWACAsset> = {
  ...baseWACConfig,
  name: 'Yokogawa - Wide Area Communication Router (WAC)',
  type: 'wac',
  description:
    'Onboard a Yokogawa Wide Area Communication Router (WAC) via HIS or HIS/ENG.',
  form: {
    ...baseWACConfig.form,
    initialValues: { ...baseWACConfig.form.initialValues, dsc_plc_type: 'wac' },
  },
};
