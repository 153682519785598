import {
  defaultFrontendSort,
  filterSearchColumnElastic,
} from '../../../../../util/elasticColumns';
import { CenterTableColumnsFactory } from '../../../../../../types';
import {
  booleanFilterParser,
  BooleanRender,
} from '../../../../defaults/defaultQueryHelpers';
import get from 'lodash/get';
import React from 'react';
import { T } from 'hooks/useAssetTranslation';

const labels = { true: 'Enabled', false: 'Disabled' };

export const columns: CenterTableColumnsFactory = ({
  filtering,
  filterOptions,
}) => {
  if (!filtering || filterOptions === undefined) return [];
  return [
    filterSearchColumnElastic({
      title: <T>Name</T>,
      key: 'json.columns.element_name',
      filtering,
      filterOptions,
      sorter: defaultFrontendSort('json.columns.element_name'),
    }),
    filterSearchColumnElastic({
      title: <T>Recovery Action</T>,
      key: 'json.columns.automatic_recovery_action',
      filtering,
      filterOptions,
      sorter: defaultFrontendSort('json.columns.automatic_recovery_action'),
    }),
    filterSearchColumnElastic({
      title: <T>Shutdown Action</T>,
      key: 'json.columns.automatic_shutdown_action',
      filtering,
      filterOptions,
      sorter: defaultFrontendSort('json.columns.automatic_shutdown_action'),
    }),
    filterSearchColumnElastic({
      title: <T>Incremental Backup</T>,
      key: 'json.columns.incremental_backup_enabled',
      filtering,
      filterOptions,
      render: value => <BooleanRender value={value} labels={labels} />,
      sorter: a =>
        get(a, 'json.columns.incremental_backup_enabled', false) ? 1 : -1,
      customFilterParser: booleanFilterParser(labels),
    }),
    filterSearchColumnElastic({
      title: <T>Version</T>,
      key: 'json.columns.version',
      filtering,
      filterOptions,
      sorter: defaultFrontendSort('json.columns.version'),
    }),
  ];
};
