import React, { useRef, useEffect, useState } from 'react';
import Flex from 'components/Flex';
import Button, { ColorVariants } from 'components/Button';
import Guacamole, { SessionRecording } from 'guacamole-common-js';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RemoteSession } from '../types';
import styled from 'styled-components';
import { Slider as BaseSlider, Spin } from 'antd';
import debounce from 'lodash/debounce';
// import { openNotificationWithIcon } from 'utils';
// import { notification } from 'antd';
// import { GUACAMOLE_STATUS } from '../utils/guacamole/constants';
/* ------------------------------
 * Helper functions
 * ------------------------------*/
const zeroPad = (num: number, minLength = 2) => {
  /**
   * Add leading zeroes until string is long enough
   */
  let str = num.toString();
  while (str.length < minLength) str = '0' + str;
  return str;
};

const formatTime = (value: number) => {
  /**
   * Format seconds and minutes as MM:SS
   */
  const totalSeconds = Math.floor(value / 1000);
  const seconds = totalSeconds % 60;
  const minutes = Math.floor(totalSeconds / 60);

  return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
};

/* ------------------------------
 * Components
 * ------------------------------*/
type PlayState = 'play' | 'pause';

type PlayButtonProps = {
  state: PlayState;
  onClick: () => void;
};

const PlayButton = ({ state, onClick }: PlayButtonProps) => {
  const icon = state === 'pause' ? faPlay : faPause;
  return (
    <Button theming="site" ghost={false} onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

type PositionDurationProps = {
  position: number;
  duration: number;
};

const PositionDuration = ({ position, duration }: PositionDurationProps) => {
  return (
    <div>
      <span>{formatTime(position)}</span>
      <span>/</span>
      <span>{formatTime(duration)}</span>
    </div>
  );
};

type Props = {
  theming: ColorVariants;
  session: RemoteSession;
  bearer?: string | undefined;
  onDuration?: (duration: number) => void;
  onResize?: (width: number, height: number) => void;
};

export default function RemoteRecordingPlayer({
  session,
  onDuration,
  onResize,
  bearer = sessionStorage.getItem('token') || undefined,
}: Props) {
  // const [t] = useTranslation(['remoteAccess', 'common']);

  const url = `/api/remote-session/${session.id}/recording/`;
  const [state, setState] = useState<PlayState>('pause');
  const [loading, setLoading] = useState<boolean>(false);
  // const [error, setError] = useState<string | null>(null);
  const [recording, setRecording] = useState<SessionRecording>();
  const [duration, setDuration] = useState<number>(0);
  const [position, setPosition] = useState<number>(0);

  // const player = useRef<HTMLDivElement | null>(null);
  const display = useRef<HTMLDivElement | null>(null);
  const onDurationDebounced = onDuration
    ? debounce(onDuration, 300)
    : undefined;
  useEffect(() => {
    const tunnel = new Guacamole.StaticHTTPTunnel(url, false, {
      Authorization: `Bearer ${bearer}`,
    });
    const recording = new Guacamole.SessionRecording(tunnel);
    const recordingDisplay = recording.getDisplay();

    if (display.current === null) return;

    display.current.appendChild(recordingDisplay.getElement());
    recording.connect();
    setRecording(recording);
    recording.onplay = () => {
      setState('play');
    };
    recording.onpause = () => {
      setState('pause');
    };
    recording.onseek = (millis: number) => {
      setPosition(millis);
    };
    // Update slider and status when duration changes
    recording.onprogress = (millis: number) => {
      if (millis === 0) return;
      const first = millis < 2000;
      setDuration(millis);
      if (first) recording.seek(0);
      if (onDurationDebounced) setTimeout(() => onDurationDebounced(millis), 0);
    };
    recordingDisplay.onresize = (width: number, height: number) => {
      // Do not scale if display has no width
      if (!width) return;

      // Scale display to fit width of container
      if (display.current)
        recordingDisplay.scale(display.current.offsetWidth / width);

      // call optional eventhandler
      if (onResize) onResize(width, height);
    };
  }, [url]);

  const playButtonClick = () => {
    if (!recording) {
      return;
    }

    if (!recording.isPlaying()) recording.play();
    else recording.pause();
  };

  const sliderPositionChanged = (value: number | [number, number]) => {
    if (!recording) {
      console.warn('Recording not ready');
      return;
    }
    if (Array.isArray(value)) value = value[0];
    recording.seek(value, () => setLoading(false));
    setLoading(true);
  };

  return (
    <Player>
      {/* @ts-ignore */}
      <Spin spinning={loading || duration === 0}>
        <Display ref={display} />
      </Spin>

      <Flex h="between">
        <PlayButton state={state} onClick={playButtonClick} />
        <Slider
          onChange={sliderPositionChanged}
          min={0}
          value={position}
          max={duration}
          tipFormatter={millies => <span>{formatTime(millies || 0)}</span>}
          disabled={duration === 0}
        />
        <PositionDuration position={position} duration={duration} />
      </Flex>
    </Player>
  );
}

const Player = styled.div`
  width: 100%;
`;
const Display = styled.div`
  position: relative;
  min-height: 480px;
  overflow-y: hidden;
`;
const Slider = styled(BaseSlider)`
  width: 100%;
  margin: 10px 20px;
`;
