import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2rem;
  color: #adb5bd;
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fafbfd no-repeat 50% / cover;
  border-radius: 50%;
`;

type Props = {
  firstName: string;
  lastName: string;
};

const initials = (firstName: string, lastName?: string) => {
  if (lastName) return `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`;
  return `${firstName.substr(0, 2)}`;
};

export default function Avatar({ firstName, lastName }: Props) {
  return <Container>{initials(firstName, lastName)}</Container>;
}
