import {
  fetchEnvAssets,
  fetchEnvAsset,
} from 'envAssets/services/EnvAssetService';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchEnvAssetsCompleted,
  fetchEnvAssetsFailed,
  fetchEnvAssetsRequested,
  fetchEnvAssetCompleted,
  fetchEnvAssetFailed,
  fetchEnvAssetRequested,
} from './slice';

function* fetchEnvAssetsEffectSaga(action) {
  try {
    let { data } = yield call(fetchEnvAssets, action.payload);
    yield put(fetchEnvAssetsCompleted(data));
  } catch (e) {
    yield put(fetchEnvAssetsFailed());
  }
}

export function* fetchEnvAssetsWatcherSaga() {
  yield takeLatest(
    fetchEnvAssetsRequested.toString(),
    fetchEnvAssetsEffectSaga,
  );
}

function* fetchEnvAssetEffectSaga(action) {
  try {
    let { data } = yield call(fetchEnvAsset, action.payload);
    yield put(fetchEnvAssetCompleted(data));
  } catch (e) {
    yield put(fetchEnvAssetFailed());
  }
}

export function* fetchEnvAssetWatcherSaga() {
  yield takeLatest(fetchEnvAssetRequested.toString(), fetchEnvAssetEffectSaga);
}

export default function* envAssetsRootSaga() {
  yield all([fetchEnvAssetsWatcherSaga(), fetchEnvAssetWatcherSaga()]);
}
