import 'core-js/stable/object/has-own';

import React, { useRef, ChangeEvent } from 'react';
import { Upload } from 'react-feather';
import { Button } from 'components';
import { TFunction } from 'i18next';
import Uppy from '@uppy/core';

type Props = {
  t: TFunction;
  uppy: Uppy;
  theming: 'site' | 'center';
  className?: string;
};

const UploadButton = ({ t, uppy, ...props }: Props) => {
  const uploadRef = useRef<HTMLInputElement | null>(null);

  const onChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;
    const files = Array.from(target.files);
    files.forEach((file: any) => {
      try {
        uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (err) {
        /* @ts-ignore */
        if (err.isRestriction) {
          // handle restrictions
          console.log('Restriction error:', err);
        } else {
          // handle other errors
          console.error(err);
        }
      }
    });
  };
  // it’s probably a good idea to clear the `<input>`
  // after the upload or when the file was removed
  // (see https://github.com/transloadit/uppy/issues/2640#issuecomment-731034781)
  uppy.on('file-removed', () => {
    if (uploadRef.current) uploadRef.current.value = '';
  });

  uppy.on('complete', () => {
    if (uploadRef.current) uploadRef.current.value = '';
  });

  return (
    <>
      <input
        type="file"
        ref={uploadRef}
        onChange={onChange}
        style={{ display: 'none' }}
        multiple
      />
      <Button
        ghost={false}
        variant={'primary'}
        onClick={() => uploadRef.current?.click()}
        {...props}
      >
        <Upload size={16} className={'mr-2'} />
        <>{t('Upload file')}</>
      </Button>
    </>
  );
};
export default UploadButton;
