import { AssetPageConfig, VirtualizationApplication } from '../../../types';
import columns from './columns';
import { hyperVConfig } from './hyperv';

export const virtualizationConfiguration: AssetPageConfig<VirtualizationApplication> =
  {
    name: 'Virtualization Application',
    licenseModule: 'virtualizationapplications',
    permissions: ['virtualizationapplicationmicrosofthyperv'],
    listView: {
      assetType: 'virtualization',
      subTypeKey: 'virtualization_type',
      columns,
    },
    assets: {
      microsoft_hyper_v: hyperVConfig,
    },
  };
