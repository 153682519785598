import { AnalyzerManagementApplication, AssetPageConfig } from '../../../types';
import { aaimsConfig } from './aaims';
import columns from './columns';
import { prmConfig } from './calsys';

export const analyzerManagementConfiguration: AssetPageConfig<AnalyzerManagementApplication> =
  {
    name: 'Analyzer Management Application',
    licenseModule: 'analyzermanagementapplications',
    permissions: [
      'analyzermanagementapplicationaaims',
      'analyzermanagementapplicationcalsys',
    ],
    listView: {
      assetType: 'analyzer_management',
      subTypeKey: 'analyzermanagement_type',
      columns,
    },
    assets: {
      aaims: aaimsConfig,
      calsys: prmConfig,
    },
  };
