import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

const Chevron = styled(FontAwesomeIcon)`
  transition: transform 0.2s ease-out;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
`;

const FilterSidebar = ({ children }: React.PropsWithChildren<any>) => {
  return <FilterContainer>{children}</FilterContainer>;
};

const FilterHeader = styled.div`
  cursor: pointer;
  margin-top: 1rem;
  font-style: italic;
  font-weight: 200;
  text-transform: uppercase;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
`;

function Header({ title, isOpen, ...props }: any) {
  return (
    <FilterHeader {...props}>
      {title}
      <Chevron icon={faChevronDown} rotation={isOpen ? undefined : 90} />
    </FilterHeader>
  );
}

const FilterItem = styled.div`
  word-break: break-all;
  white-space: normal;
  padding: 0.5rem 1rem;
  background: none;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;

  ${(p: any) =>
    p.isActive
      ? css`
          color: ${(p: any) => p.theme.colors[p.theming].primary.main};
          background: ${(p: any) => p.theme.colors[p.theming].primary.main}0f;
        `
      : css`
          &:hover,
          &:focus {
            color: ${(p: any) => p.theme.colors[p.theming].primary.main};
            background: ${(p: any) => p.theme.colors[p.theming].primary.main}29;
          }
        `}
`;

function Item({
  title,
  onClick,
  active = false,
  theming = 'center',
  ...props
}: any) {
  const [isActive, setIsActive] = useState(active);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    setIsActive(!isActive);
  }, [isActive, onClick]);

  return (
    <FilterItem
      {...props}
      onClick={handleOnClick}
      data-ta={`filterItem-${title}`}
      theming={theming}
      isActive={isActive}
    >
      {title}
    </FilterItem>
  );
}

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
`;

function Group({ children, title, isOpen: IsOpenProp = false }: any) {
  const [isOpen, setIsOpen] = useState(IsOpenProp);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      {title && (
        <Header
          title={title}
          onClick={toggle}
          isOpen={isOpen}
          data-ta={`filterHeader-${title}`}
        />
      )}
      <FilterGroup data-ta={`filter-${title}`}>
        <div>{children}</div>
      </FilterGroup>
    </>
  );
}

FilterSidebar.Header = Header;
FilterSidebar.Item = Item;
FilterSidebar.Group = Group;

export default FilterSidebar;
