import React from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { HistoricalMessage } from './HistoricalMessages';
import { get } from 'lodash';
import moment from 'moment';

export type HistoricalMessagesModalProps = {
  historicalMessage: HistoricalMessage;
  closeModal: () => void;
};

function HistoricalMessagesModal({
  historicalMessage,
  closeModal,
}: HistoricalMessagesModalProps) {
  const [t] = useTranslation(['assetsCompute', 'assetManagement']);

  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        {`${t('Event')} ${historicalMessage.message_number}`}
      </ModalHeader>
      <ModalBody>
        <div className="border p-3">
          <div>
            <div className="form-group row mt-4">
              <label
                htmlFor="source"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Source')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="source"
                  value={get(historicalMessage, 'source')}
                />
              </div>
              <label
                htmlFor="dateAndTime"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Date & Time')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="dateAndTime"
                  value={moment(
                    get(historicalMessage, 'time_stamp_utc'),
                  ).format('LLLL')}
                />
              </div>
            </div>
            <div className="form-group row mt-4">
              <label
                htmlFor="type"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Type')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="type"
                  value={get(historicalMessage, 'message_major_cat_mapped')}
                />
              </div>
              <label
                htmlFor="category"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Category')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="category"
                  value={get(historicalMessage, 'message_medium_cat_mapped')}
                />
              </div>
            </div>
            <div className="form-group row mt-4">
              <label
                htmlFor="eventId"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Event ID')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="eventId"
                  value={get(historicalMessage, 'message_number')}
                />
              </div>
              <label
                htmlFor="subcategory"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Subcategory')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="subcategory"
                  value={get(historicalMessage, 'message_small_cat_mapped')}
                />
              </div>
            </div>
            <div className="form-group row mt-5">
              <label
                htmlFor="eventMessage"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('assetManagement:Event Message')}
              </label>
              <div className="col-sm-12">
                <Input
                  type="textarea"
                  name="text"
                  disabled
                  value={get(historicalMessage, 'message')}
                  className="text-black"
                  rows="10"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default HistoricalMessagesModal;
