import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { theme } from 'styles';
import styled from 'styled-components';

type Props = {
  variant?: 'green' | 'red' | 'grey' | 'yellow';
  label?: string;
  blink?: boolean;
};
const BlinkIcon = styled(FontAwesomeIcon)`
  &.blink {
    animation: blink 2.2s ease infinite;
  }

  @keyframes blink {
    0% {
      color: ${theme.colors.common.gray300};
      filter: drop-shadow(0px 0px 0px transparent);
    }
    10% {
      color: ${p => p.color};
      filter: drop-shadow(0px 0px 3px ${p => p.color});
    }
    80% {
      color: ${p => p.color};
      filter: drop-shadow(0px 0px 3px ${p => p.color});
    }
    100% {
      color: ${theme.colors.common.gray300};
      filter: drop-shadow(0px 0px px transparent);
    }
  }
`;

export default function StatusIndicator({
  variant,
  label,
  blink = false,
}: Props) {
  const color = () => {
    switch (variant) {
      case 'green':
        return theme.colors.common.green;
      case 'red':
        return theme.colors.common.red;
      case 'yellow':
        return theme.colors.common.yellow;
      case 'grey':
      default:
        return theme.colors.common.gray300;
    }
  };

  return (
    <>
      <BlinkIcon
        icon={faCircle}
        color={color()}
        size="1x"
        style={{ marginRight: 4 }}
        className={blink ? 'blink' : ''}
      />
      {label}
    </>
  );
}
