import Grid from 'components/Grid';
import { useElasticQuery } from 'hooks/useElasticQuery';
import React from 'react';
import { ExpandedContentProps } from 'types';
import { AssetParams, useAssetParams } from 'hooks/useAssetParams';
import GaugeCard from '../../../../../../components/GaugeCard';
import { useAssetTranslation } from '../../../../../../hooks';
import { insertArrayIf, insertObjIf } from 'utils/arrayHelpers';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import Flex from '../../../../../../components/Flex';

const mssIdParam = (params: AssetParams) => ({
  elasticQueryKey: 'mss.id',
  value: params.assetId!,
});

type Props = Pick<ExpandedContentProps, 'datePickerSettings'> & {
  data: {
    json: {
      columns: {
        name: string;
        dynamic_memory: boolean;
        assigned_memory: string;
        memory_demand: string;
        startup_memory: string;
        minimum_memory: string;
        maximum_memory: string;
      };
    };
  };
};

const baseQuery = (aggs: any) => ({
  size: 0,
  query: {},
  aggs: {
    vm_aggregation: {
      terms: {
        field: 'json.columns.name.keyword',
        size: 5000,
      },
      aggs,
    },
  },
});

type NumberCardProps = {
  value: string | number;
  title: string;
};
const NumberCard = ({ value, title }: NumberCardProps) => {
  const { t } = useAssetTranslation();
  return (
    <Card className={`d-flex justify-content-center align-items-center h-full`}>
      <CardHeader>
        <CardTitle className="m-0">{t(title)}</CardTitle>
      </CardHeader>
      <CardBody>
        <Flex h="center" v="center">
          <span
            style={{
              fontSize: 24,
              marginTop: 16,
            }}
          >
            {value}
          </span>
        </Flex>
      </CardBody>
    </Card>
  );
};

export function ExpandedContent(props: Props) {
  const params = useAssetParams();
  const { t } = useAssetTranslation();

  const baseParameters = (collector_key: string) => [
    mssIdParam(params),
    {
      elasticQueryKey: 'mss.collector_key',
      value: collector_key,
    },
    {
      elasticQueryKey: 'json.columns.name.keyword',
      value: props.data.json.columns.name,
    },
  ];

  const [avgCPU, avgMemory, memoryOverTime] = useElasticQuery(
    [
      {
        key: 'hyperv-cpu-per-vm',
        dataParser: data => ({
          total: 0,
          data: parseInt(
            data.aggregations.vm_aggregation.buckets[0].cpu_usage.value?.toFixed() ??
              '0'
          ),
        }),
        index: 'virtualization-microsoft-hyper-v-metrics*',
        dateRange: { ...props.datePickerSettings, key: '@timestamp' },
        baseQuery: baseQuery({
          cpu_usage: {
            avg: {
              field: 'json.columns.average_cpu_usage',
            },
          },
        }),
        parameters: baseParameters('cpu'),
      },
      {
        key: 'hyperv-mem-per-vm',
        dataParser: data => ({
          total: 0,
          data: {
            assigned_memory: parseInt(
              data.aggregations.vm_aggregation.buckets[0].assigned_memory.value?.toFixed() ??
                0
            ),
            memory_demand: props.data.json.columns.dynamic_memory
              ? parseInt(
                  data.aggregations.vm_aggregation.buckets[0].memory_demand.value?.toFixed() ??
                    0
                )
              : 'N/A',
          },
        }),
        index: 'virtualization-microsoft-hyper-v-metrics*',
        dateRange: { ...props.datePickerSettings, key: '@timestamp' },
        baseQuery: baseQuery({
          ...insertObjIf(props.data.json.columns.dynamic_memory, {
            memory_demand: {
              avg: {
                field: 'json.colums.memory_demand',
              },
            },
          }),
          assigned_memory: {
            avg: {
              field: 'json.columns.assigned_memory',
            },
          },
        }),
        parameters: baseParameters('memory'),
      },
      {
        key: 'hyperv-mem-per-vm-over-time',
        dataParser: data => {
          const buckets = data?.aggregations.avg_memory.buckets.filter(
            (x: any) => x.vm_aggregation.buckets.length > 0
          );
          const timestamps = buckets.map((bucket: any) => bucket.key_as_string);
          const series = [
            {
              name: 'Assigned Memory',
              data: buckets.map(
                (bucket: any) =>
                  bucket.vm_aggregation.buckets[0]?.average_assigned_memory
                    .value ?? null
              ),
            },
            {
              name: 'Minimum Memory',
              data: buckets.map(
                (bucket: any) =>
                  bucket.vm_aggregation.buckets[0]?.minimum.value ?? null
              ),
            },
            {
              name: 'Maximum Memory',
              data: buckets.map(
                (bucket: any) =>
                  bucket.vm_aggregation.buckets[0]?.maximum.value ?? null
              ),
            },
            ...insertArrayIf(props.data.json.columns.dynamic_memory, [
              {
                name: 'Memory Demand',
                data: buckets.map(
                  (bucket: any) =>
                    bucket.vm_aggregation.buckets[0]?.average_demand.value ??
                    null
                ),
              },
            ]),
          ];
          return {
            total: 0,
            data: {
              series,
              categories: timestamps,
            },
          };
        },
        index: 'virtualization-microsoft-hyper-v-metrics*',
        dateRange: { ...props.datePickerSettings, key: '@timestamp' },
        parameters: baseParameters('memory'),
        baseQuery: {
          size: 0,
          query: {
            bool: {
              must_not: [
                {
                  match: {
                    'json.columns.name': 'Default settings',
                  },
                },
                {
                  match: {
                    'json.columns.name': 'Limit settings',
                  },
                },
              ],
            },
          },
          aggs: {
            avg_memory: {
              auto_date_histogram: {
                field: '@timestamp',
                buckets: 300,
                minimum_interval: 'minute',
              },
              aggs: {
                vm_aggregation: {
                  terms: {
                    field: 'json.columns.name.keyword',
                  },
                  aggs: {
                    average_assigned_memory: {
                      avg: {
                        field: 'json.columns.assigned_memory',
                      },
                    },
                    average_demand: {
                      avg: {
                        field: 'json.columns.memory_demand',
                      },
                    },
                    maximum: {
                      avg: {
                        field: 'json.columns.maximum_memory',
                      },
                    },
                    minimum: {
                      avg: {
                        field: 'json.columns.minimum_memory',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    ],
    Array.from({ length: 3 }, () => ({
      refetchInterval: props.datePickerSettings.isPaused
        ? false
        : props.datePickerSettings.refreshInterval,
    }))
  );
  return (
    <Grid.Row>
      <Grid.Col>
        <p
          style={{
            marginTop: 2,
            marginLeft: 2,
            textAlign: 'right',
            fontStyle: 'italic',
          }}
        >
          *
          {t(
            'asset:Note - These graphs are impacted by the settings in the date picker'
          )}
        </p>
      </Grid.Col>
      <Grid.Col width={4}>
        <GaugeCard
          title={t('Average CPU Usage')}
          value={avgCPU.data?.data.toFixed() ?? 0}
          loading={avgCPU.isLoading}
        />
      </Grid.Col>
      <Grid.Col width={4}>
        <NumberCard
          value={`${avgMemory.data?.data.assigned_memory ?? 0} MB`}
          title={t('Average Assigned Memory')}
        />
      </Grid.Col>
      <Grid.Col width={4}>
        <NumberCard
          value={
            props.data.json.columns.dynamic_memory
              ? `${avgMemory.data?.data.memory_demand ?? 0} MB`
              : 'N/A'
          }
          title={t('Average Memory Demand')}
        />
      </Grid.Col>
      <Grid.Col className={'my-4'}>
        <h5 className="mt-4">{t('asset:Memory usage')}</h5>
        <div style={{ backgroundColor: 'white' }} className="my-4">
          <Chart
            options={{
              chart: {
                animations: {
                  enabled: true,
                },
                toolbar: {
                  show: true,
                  tools: { download: false },
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'straight',
                width: 2,
              } as ApexStroke,
              xaxis: {
                type: 'datetime',
                categories: memoryOverTime.data?.data.categories ?? [],
              } as ApexXAxis,
              yaxis: {
                logarithmic: true,
                labels: {
                  formatter: (val: number) => `${val.toFixed(0)} MB`,
                },
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm',
                },
              },
              legend: {
                show: true,
              },
            }}
            series={memoryOverTime.data?.data.series ?? []}
            type="line"
            height="400"
          />
        </div>
      </Grid.Col>
    </Grid.Row>
  );
}
