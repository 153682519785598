import { FormikProps } from 'formik';
import { AnyAssetApplication, FormikNewFormValues } from '../../../types';

export function defaultStepDirectConnectDisabledNext<
  AssetType extends AnyAssetApplication
>(formikContext: FormikProps<FormikNewFormValues<AssetType>>) {
  return formikContext.values.assets.some(
    asset => !asset.test_direct.result.status.query
  );
}
