import { uniq } from './arrayHelpers';
import get from 'lodash/get';

export const getNested = (item: any, key: string) =>
  key.includes('.') ? get(item, key) : item[key];

export const createFilters = (
  keys: string[],
  dataSet: any[],
  customLabel?: (key: string, value: string) => string,
  customHeading?: (heading: string) => string
) => {
  const filterValues: any = {};

  keys.forEach(key => {
    filterValues[key] = {
      label: customHeading?.(key) ?? key,
      options: uniq(dataSet.map(item => getNested(item, key))).reduce(
        (acc: any[], item: any) => {
          if (!item) return acc;
          return [
            ...acc,
            {
              label:
                (customLabel ? customLabel(key, item) : item) || 'Undefined',
              value: item,
            },
          ].sort((a, b) => a.label.localeCompare(b.label));
        },
        []
      ),
    };
    if (filterValues[key].options.length <= 1) delete filterValues[key];
  });

  return filterValues;
};
