export function antdToEsSort(sort?: string | null) {
  switch (sort) {
    case 'ascend':
      return 'asc';
    case 'descend':
      return 'desc';
    default:
      return undefined;
  }
}

export function esToAntdSort(sort: string | undefined) {
  switch (sort) {
    case 'asc':
      return 'ascend';
    case 'desc':
      return 'descend';
    default:
      return undefined;
  }
}
