import get from 'lodash/get';

type SortValue = string | number;

// default sorting function, parameters a & b must b strings or numbers
export function defaultSort(a: SortValue, b: SortValue) {
  if (a === null) return 1;
  if (b === null) return -1;
  let comparison = 0;
  if (a > b) {
    comparison = 1;
  } else if (a < b) {
    comparison = -1;
  }
  return comparison;
}

// extension of the default sorting function for usage with arrays of object literals
// either single or multiple object literal properties can be sent as a parameter
// multiple properties need to be passed in as an array and then will be joined to a string so defaultSort can be used
export function objectArraySort(a: any, b: any, props: string | string[]) {
  let propA;
  let propB;

  if (typeof props === 'string') {
    propA = a[props];
    propB = b[props];

    if (a[props] === null || b[props] === null) {
      return defaultSort(propA, propB);
    }
    if (props.includes('.')) {
      propA = `${get(a, props)}`?.toUpperCase() ?? '';
      propB = `${get(b, props)}`?.toUpperCase() ?? '';
    } else {
      propA = a[props]?.toUpperCase() ?? '';
      propB = b[props]?.toUpperCase() ?? '';
    }
  } else {
    propA = props.map(prop => a[prop]?.toUpperCase() ?? '').join();
    propB = props.map(prop => b[prop]?.toUpperCase() ?? '').join();
  }

  return defaultSort(propA.toUpperCase(), propB.toUpperCase());
}

export function sortByCustomOrder(a: any, b: any, order: any[], key: string) {
  const _a = order.indexOf(a[key]);
  const _b = order.indexOf(b[key]);
  return defaultSort(_a, _b);
}
