import React from 'react';

const IconWindows = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...rest}>
    <path
      d="M0 6.61l19.67-2.716v19.001H0V6.61zm0 34.77l19.67 2.71V25.324H0V41.38zm21.836 3.01L48 48V25.323H21.836v19.066zm0-40.769v19.293H48V0L21.836 3.621z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default IconWindows;
