import get from 'lodash/get';
import React from 'react';
import { Trans } from 'react-i18next';
import { SearchColumnElasticProps } from '../../util/elasticColumns';

export function defaultFilterBaseQuery(
  filterKeys: string[],
  keywords?: string[]
) {
  return {
    size: 0,
    query: {},
    aggs: filterKeys.reduce((acc, key) => {
      acc[key] = {
        terms: {
          field: `${key}${(keywords ?? []).includes(key) ? '.keyword' : ''}`,
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      };
      return acc;
    }, {} as Record<string, any>),
  };
}

export function defaultFilterDataParser(data: any) {
  return {
    total: data?.hits?.total?.value ?? 0,
    data: data.aggregations,
  };
}

export function defaultAggsDataParser(
  data: any,
  bucketsPath?: string,
  bucketsDataPath?: string
) {
  const buckets = get(
    data,
    bucketsPath ?? 'aggregations["top-hits"].buckets',
    []
  );
  return {
    data: buckets.map((bucket: Record<string, any>) =>
      get(bucket, bucketsDataPath ?? 'top_uids_hits.hits.hits[0]._source', {})
    ),
    total: buckets.length,
  };
}

type BooleanLabels = { true: string; false: string };
const defaultBooleanLabels: BooleanLabels = { true: 'Yes', false: 'No' };

export function booleanFilterParser(
  labels?: BooleanLabels
): SearchColumnElasticProps<any>['customFilterParser'] {
  return (acc: any, data) => [
    ...(acc ?? []),
    {
      text: (
        <BooleanRender
          value={typeof data.key === 'boolean' ? data.key : data.key === 'true'}
          labels={labels}
        />
      ),
      value: data.key,
    },
  ];
}

type BooleanRenderProps = {
  value: boolean;
  labels?: BooleanLabels;
};

export function BooleanRender({ value, labels }: BooleanRenderProps) {
  const labelMap: BooleanLabels = labels ?? defaultBooleanLabels;
  return (
    <Trans ns={['common']}>
      {labelMap[String(value) as keyof BooleanLabels]}
    </Trans>
  );
}
