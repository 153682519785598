import React from 'react';
import {
  AnyAssetApplication,
  DefaultAssetColumnProps,
  SecuritySubType,
} from '../../../types';
import {
  assetTypeColumn,
  assetTypeLogoColumn,
  custodianColumn,
  ipAddressColumn,
  locationColumn,
  nameColumn,
  operationalStatusColumn,
  roleColumn,
} from 'components/DefaultAssetColumns';
import Flex from 'components/Flex';
import MssIcon from 'components/MssIcon';
import { applicationTypeTableIcon } from '../../../utils';

function columns<Asset extends AnyAssetApplication>(
  props: DefaultAssetColumnProps,
  subTypeKey: string
) {
  return [
    operationalStatusColumn<any>(props),
    assetTypeLogoColumn<Asset>(
      {
        ...props,
        render: (application_type: SecuritySubType) => (
          <Flex h="center" v="center" style={{ maxWidth: 14, maxHeight: 14 }}>
            <MssIcon
              className=""
              icon={applicationTypeTableIcon(application_type)}
            />
          </Flex>
        ),
      },
      subTypeKey
    ),
    nameColumn<Asset>(props),
    assetTypeColumn<Asset>(props, subTypeKey),
    roleColumn<Asset>(props),
    custodianColumn<Asset>(props),
    locationColumn<Asset>(props),
    ipAddressColumn<Asset>(props),
  ];
}

export default columns;
