import React from 'react';
import { FileText } from 'react-feather';
import { columns } from './columns';
import { Query, TablePage } from '../../../../../../types';
import {
  defaultFilterBaseQuery,
  defaultFilterDataParser,
} from '../../../../defaults/defaultQueryHelpers';
import { Modal } from 'antd';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { ModalContent } from './Modal';

const index = 'virtualization-microsoft-hyper-v-logs*';

const filterKeys = ['log.level', 'winlog.keywords', 'winlog.provider_name'];

const parameters: Query['parameters'] = [
  {
    elasticQueryKey: 'mss.id',
    sourceParser: ({ params }) => params.assetId!,
  },
];

export const eventLogs: TablePage = {
  slug: 'event-logs',
  title: 'Event Logs',
  icon: <FileText size={14} />,
  type: 'table',
  tableConfig: {
    onRow: (record: any) => ({
      onClick: () =>
        Modal.info({
          title: (
            <div
              style={{
                fontWeight: 'bold',
                borderBottom: `1px solid rgba(0, 0, 0, 0.09)`,
              }}
              className="p-4"
            >{`Event ID: ${record.winlog.event_id} @ ${moment(
              record.event.created
            ).format('LLLL')}`}</div>
          ),
          icon: null,
          bodyStyle: { padding: 0 },
          content: <ModalContent record={record} />,
          okText: <Trans ns={['common']}>Close</Trans>,
          okButtonProps: {
            style: { marginRight: '1rem', marginBottom: '1rem' },
          },
          width: 800,
          closable: true,
          maskClosable: true,
        }),
    }),
    columns,
    filterKeys,
    rowKey: 'winlog.record_id',
    contentQueries: [
      {
        key: 'event-logs',
        index,
        parameters,
        defaultSort: {
          columnKey: 'event.created',
          order: 'descend',
        },
        dateRange: { key: 'event.created' },
        baseQuery: {
          size: 10000,
          _source: {
            includes: [
              'log.level',
              'winlog.event_id',
              'winlog.computer_name',
              'winlog.channel',
              'winlog.keywords',
              'winlog.provider_name',
              'winlog.record_id',
              'event.provider_name',
              'event.created',
              'event.action',
              'message',
            ],
          },
        },
        dataParser: (data: any) => ({
          total: data.hits.total.value,
          data: data.hits.hits.map((x: any) => x._source),
        }),
      },
    ],
    filterQuery: {
      baseQuery: defaultFilterBaseQuery(filterKeys),
      key: 'filter',
      parameters,
      index,
      dataParser: defaultFilterDataParser,
    },
  },
};
