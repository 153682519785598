/* eslint-disable @typescript-eslint/camelcase */

import { NetworkSubType } from 'types';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import {
  faClock,
  faEthernet,
  faFire,
  faNetworkWired,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

export function networkTypeTableLabel(network_type: NetworkSubType): string {
  switch (network_type) {
    case 'switch':
      return 'Switch';
    case 'router':
      return 'Router';
    case 'firewall':
      return 'Firewall';
    case 'timeserver':
      return 'Time Server';
    default:
      return 'To be implemented';
  }
}

export function networkTypeTableIcon(
  network_type: NetworkSubType
): IconDefinition {
  switch (network_type) {
    case 'switch':
      return faEthernet;
    case 'firewall':
      return faFire;
    case 'router':
      return faNetworkWired;
    case 'timeserver':
      return faClock;
    default:
      return faQuestionCircle;
  }
}

export function networkConfigurationSubType(category: string): string {
  switch (category) {
    case 'running-configs':
      return 'Running Configuration';
    case 'version':
      return 'Version';
    case 'sysinfo':
      return 'System Information';
    case 'ip route':
      return 'IP Route';
    case 'route':
      return 'Route';
    default:
      return category;
  }
}
