import React from 'react';
import ReactDOM from 'react-dom';
import Keycloak from 'keycloak-js';
import { removeSearchParam } from '../utils';

export type Tokens = {
  access?: string;
  refresh?: string;
  oneTimeToken?: string;
};

const emitTokenRefreshEvent = (rootId: string) => {
  /**
   * Emit a custom `token-refresh` event on the root element.
   * Triggerred only when token is refreshed.
   */
  const root = document.getElementById(rootId);
  if (!root) {
    console.warn(`Root element not found by id ${rootId}`);
    return;
  }
  root.dispatchEvent(new CustomEvent('token-refresh', {}));
};
export default async function keycloakEntry(
  url: string,
  { access, refresh, oneTimeToken }: Tokens,
  RootElement: any,
  rootId = 'root'
) {
  let tokens = { access, refresh };

  if (!access && oneTimeToken) {
    // try to get access token from one-time-token
    try {
      const response = await fetch('/api/auth/ott/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: oneTimeToken }),
      });
      if (response.status > 210) {
        console.warn('One-time-token authentication failed. Discarding token.');
        return removeSearchParam('token', true);
      }
      const result = await response.json();
      tokens = { ...result };
      sessionStorage.setItem('token', tokens.access || '');
      sessionStorage.setItem('refresh-token', tokens.refresh || '');
    } catch (e) {
      console.warn('One-time-token auth failed.', e);
      return removeSearchParam('token', true);
    }
  }
  return fetch(url).then(async response => {
    const config = await response.json();
    const keycloak = new Keycloak(config);

    const keycloakRefresh = () => {
      keycloak
        .updateToken(70)
        .then((refreshed: boolean) => {
          if (refreshed) {
            if (keycloak.token) {
              sessionStorage.setItem('token', keycloak.token);
              emitTokenRefreshEvent(rootId);
            }
            if (keycloak.refreshToken)
              sessionStorage.setItem('refresh-token', keycloak.refreshToken);
          }
          removeSearchParam('token', false);
        })
        .catch(() => {
          console.error('Failed to refresh token');
        });
    };

    keycloak
      .init({
        onLoad: 'login-required',
        token: tokens.access,
        refreshToken: tokens.refresh,
        checkLoginIframe: false,
        responseMode: 'query',
        redirectUri: removeSearchParam('token', null),
      })
      .then(auth => {
        if (!auth) {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('refresh-token');

          window.location.reload();
          return;
        }
        if (keycloak.token) sessionStorage.setItem('token', keycloak.token);
        if (keycloak.refreshToken)
          sessionStorage.setItem('refresh-token', keycloak.refreshToken);

        const rootEl = document.getElementById(rootId);
        if (rootEl)
          ReactDOM.render(<RootElement keycloak={keycloak} />, rootEl);

        window.setInterval(keycloakRefresh, 30000);
      })
      .catch(() => {
        console.error('Authenticated Failed');
      });
  });
}
