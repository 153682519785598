import { Marker } from 'react-simple-maps';
import React from 'react';
import { SiteMarker } from '../views/SiteMap';

type Props = {
  marker: SiteMarker;
  zoom: number;
  handleSiteClick?: () => void;
};

export function StyledMarker({ marker, zoom, handleSiteClick }: Props) {
  const zoomModifier = zoom * 0.3;
  const transformModifier = zoom - zoomModifier;
  const textScale = Math.max(1 / transformModifier, 0.3);

  return (
    <Marker
      key={marker.id}
      onClick={handleSiteClick}
      coordinates={marker.coordinates}
      style={{
        default: {
          fill: marker.color,
        },
        hover: { fill: '#FFFFFF' },
        pressed: { fill: marker.color },
      }}
    >
      <defs>
        <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="solid">
          <feFlood floodColor="rgba(236,239,241,0.5)" result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>

      <circle
        cx={0}
        cy={0}
        r={1}
        fill={marker.color}
        stroke={marker.color}
        style={{
          cursor: handleSiteClick ? 'pointer' : 'default',
          transform: `scale(${3 / transformModifier})`,
        }}
      />
      <text
        textAnchor="middle"
        y={-9}
        filter="url(#solid)"
        style={{
          fontFamily: 'Roboto, sans-serif',
          fill: 'var(--gray-dark)',
          fontSize: '.5rem',
          fontWeight: 800,
          transform: `scale(${textScale}`,
        }}
      >
        {marker.name}
      </text>
    </Marker>
  );
}
