import { AssetConfig, NetworkRouterAsset } from '../../../../types';
import { defaultConfig } from '../default';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';

export const routerConfig: AssetConfig<NetworkRouterAsset> = {
  ...defaultConfig,
  name: 'Router',
  type: 'router',
  icon: faNetworkWired,
  description: 'Onboard a router via the SNMP and Syslog protocol.',
  form: {
    ...defaultConfig.form,
    initialValues: emptyAssetFactory<NetworkRouterAsset>({
      domain: true,
      connectionTypes: ['snmp'],
      assetType: 'network_type',
      assetSubType: 'router',
      extraFields: {
        brand: '',
        series: '',
      },
    }),
  },
};
