import { centumvpConfigureInputs } from './settingsInputs';
import { userNamePassInputs } from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';
import { AssetConfig, ControlCentumVPApplication } from '../../../../types';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';

export const centumVpConfig: AssetConfig<ControlCentumVPApplication> = {
  name: 'Yokogawa - Centum VP',
  type: 'centum_vp',
  description: 'Onboard Yokogawa Centum VP DCS as control application.',
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          centumvpConfigureInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    fetchDataParser: (data: ControlCentumVPApplication) => ({
      ...data,
      ip_addresses:
        (Array.isArray(data.ip_addresses)
          ? data.ip_addresses
          : data.ip_addresses
              ?.split(/[,;:\/\\]/)
              .map(x => x.replace(/[^0-9.]/g, ''))
        )?.filter(Boolean) ?? [],
    }),
    submitDataParser: (data: ControlCentumVPApplication) => ({
      ...data,
      ip_addresses: Array.isArray(data.ip_addresses)
        ? data.ip_addresses.join?.(';')
        : data.ip_addresses,
    }),
    initialValues: emptyAssetFactory<ControlCentumVPApplication>({
      domain: true,
      connectionTypes: ['direct'],
      assetType: 'control_type',
      assetSubType: 'centum_vp',
      extraFields: {
        ip_addresses: [],
        timezone: '',
      },
    }),
    configure: {
      inputs: centumvpConfigureInputs,
      schema: inputToValidationReducer(centumvpConfigureInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassInputs),
        inputs: userNamePassInputs,
        testConnection: {
          valueKeys: [...inputsToKeys(userNamePassInputs), 'ip_address'],
          customKeyMapping: { ip_address: 'host' },
          jsonBody: true,
        },
      },
    },
    collectors: {
      tooltips: {
        project_inventory: 'Project Inventory',
        historical_messages: 'Historical Messages',
      },
      extraOptions: {
        historical_messages: {
          BSYS: 'System Alarm',
          BPRO: 'Process Alarm',
          BSEQ: 'Sequence Message',
          BOPE: 'Operation Message',
          BFFB: 'Fieldbus',
        },
      },
    },
  },
};
