import Fetch from 'common/services/_fetch';

type AddBody = {
  name: string;
};

export const add = async (body: AddBody) => {
  return Fetch.post(`/api/group/`, {
    body: JSON.stringify({ ...body, permissions: [] }),
  });
};
