import { keyframes } from 'styled-components';

export const bounceDelay = keyframes`
0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;
