import { PlcDcsSubType } from '../../types';

export function plcDcsTypeTableLabel(dsc_plc_type: PlcDcsSubType): string {
  switch (dsc_plc_type) {
    case 'scs':
      return 'SCS';
    case 'avr':
      return 'AVR';
    case 'bcv':
      return 'BCV';
    case 'wac':
      return 'WAC';
    case 'fcs':
      return 'FCS';
    case 'fcn':
      return 'FCN';
    default:
      return 'To be implemented';
  }
}
