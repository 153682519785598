import snakeCase from 'lodash/snakeCase';

export const parseFlatJsonToForm = (
  body: Record<string, string | number | boolean>
): string => {
  return Object.entries(body).reduce((acc, [key, value], index, array) => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    const pair = `${encodedKey}=${encodedValue}`;
    return acc + (index < array.length - 1 ? `${pair}&` : pair);
  }, '');
};

/**
 * Return a copy of `obj` with all CamelCase keys converted into snake_case.
 * @param obj
 */
export const snake = (obj: any) =>
  Object.assign(
    {},
    ...Object.entries(obj).map(([key, value]: [string, any]) => ({
      [snakeCase(key)]: value,
    }))
  );
