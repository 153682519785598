import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import { userNamePassInputs } from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { defaultPlcInputs } from './settingsInputs';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { AssetConfig, PlcDcsAsset } from '../../../../types';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';

export const defaultConfig: Omit<
  AssetConfig<PlcDcsAsset>,
  'name' | 'label' | 'description' | 'type'
> = {
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultPlcInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    initialValues: emptyAssetFactory<PlcDcsAsset>({
      connectionTypes: ['direct'],
      assetType: 'dsc_plc_type',
      assetSubType: 'avr',
      extraFields: {
        domain_number: null,
        station_number: null,
      },
    }),
    configure: {
      inputs: defaultPlcInputs,
      schema: inputToValidationReducer(defaultPlcInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassInputs),
        inputs: userNamePassInputs,
        testConnection: {
          valueKeys: [
            ...inputsToKeys(userNamePassInputs),
            'ip_address',
            'station_number',
            'domain_number',
          ],
          customKeyMapping: { ip_address: 'host' },
        },
      },
    },
    collectors: {
      tooltips: {
        cpu: 'Central Processing Unit',
        hku: 'House Keeping Unit',
        ecc: 'Error Correction Code',
        vnet: 'Proprietary control network of Yokogawa',
        wac: 'Wide Area Communication Router',
        rev_info: 'Revision Info',
      },
    },
  },
};
