import { notification } from 'antd';
import { TFunction } from 'react-i18next';
import { openNotificationWithIcon } from './openNotificationWithIcon';

export function getAuthToken() {
  /**
   * Get current openid 'Bearer' token from sessionStorage
   */
  return sessionStorage.getItem('token');
}

export function getAuthHeader() {
  /**
   * Get current auth header object.
   *
   * eg. {Authorization: Bearer <token>}
   */
  return { Authorization: `Bearer ${getAuthToken()}` };
}

export function getSiteHeader(siteId?: string) {
  /**
   * Get current auth header object.
   *
   * eg. {Authorization: Bearer <token>}
   */
  return siteId ? { 'X-Site-Id': siteId } : undefined;
}

export const defaultParams: any = () => ({
  headers: {
    'Content-Type': 'application/json',
    ...getAuthHeader(),
  },
});

export const _fetch = (input: RequestInfo, init?: RequestInit | undefined) => {
  const params = init || { headers: {} };
  return fetch(input, {
    ...defaultParams(),
    ...params,
    headers: { ...defaultParams().headers, ...params?.headers },
  });
};

export const _fetchSite = (
  input: RequestInfo,
  init?: RequestInit | undefined,
  siteId?: string | undefined
) => {
  init = {
    ...init,
    headers: { ...getSiteHeader(siteId), ...init?.headers },
  };
  return _fetch(input, init);
};

export const Fetch = (siteId?: string) => ({
  get: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetchSite(input, { ...init, method: 'GET' }, siteId),
  post: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetchSite(input, { ...init, method: 'POST' }, siteId),
  patch: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetchSite(input, { ...init, method: 'PATCH' }, siteId),
  put: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetchSite(input, { ...init, method: 'PUT' }, siteId),
  delete: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetchSite(input, { ...init, method: 'DELETE' }, siteId),
});

const defaultErrorMessage =
  "Due to technical issues we couldn't complete the request. Please try again later or contact a administrator.";

export const defaultErrorMessages = {
  GET: {
    title: "Couldn't retrieve the data requested",
    message: defaultErrorMessage,
  },
  POST: {
    title: "Couldn't create the data requested",
    message: defaultErrorMessage,
  },
  PATCH: {
    title: "Couldn't update the data requested",
    message: defaultErrorMessage,
  },
  PUT: {
    title: "Couldn't update the data requested",
    message: defaultErrorMessage,
  },
  DELETE: {
    title: "Couldn't delete the data requested",
    message: defaultErrorMessage,
  },
};

type ResponseHandler = {
  request: Promise<Response>;
  parser?:
    | 'json'
    | 'text'
    | 'blob'
    | 'formData'
    | 'arrayBuffer'
    | 'clone'
    | 'skip';
  t: TFunction;
  errorTitle?: string;
  errorMessage?: string;
  customErrorHandling?(error: any): void;
};

export const defaultResponseHandler = async <T>({
  request,
  parser = 'json',
  t,
  errorTitle,
  errorMessage,
  customErrorHandling,
}: ResponseHandler): Promise<{
  data: T;
  error: Record<string, any> | string | null;
}> => {
  try {
    const response = await request;
    const data: T = parser === 'skip' ? response : await response[parser]();

    if (!response.status.toString().startsWith('2')) {
      throw data;
    }
    return { data, error: null };
  } catch (error: any) {
    if (customErrorHandling) {
      customErrorHandling(error);
    } else {
      const errorMethod: keyof typeof defaultErrorMessages =
        error?.method ?? 'GET';
      openNotificationWithIcon(
        notification.error,
        t(errorTitle ?? defaultErrorMessages[errorMethod].title),
        t(errorMessage || defaultErrorMessages[errorMethod].message)
      );
    }
    return { error } as any;
  }
};
