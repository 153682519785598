import { commonInputConfigs } from './commonInputConfigs';
import { BaseInputConfig, PasswordConfig, StringConfig } from '../../../types';

export const defaultAssetInputs: BaseInputConfig[] = [
  commonInputConfigs.name(),
  commonInputConfigs.role(),
  commonInputConfigs.custodian(),
  commonInputConfigs.ip_address(),
  commonInputConfigs.criticality(),
  commonInputConfigs.location(),
  commonInputConfigs.description(),
];

export const userNamePassInputs: (StringConfig | PasswordConfig)[] = [
  {
    label: 'Username',
    fieldName: 'username',
    required: true,
    type: 'string',
    compactWidth: 6,
    width: 12,
  },
  {
    label: 'Password',
    fieldName: 'password',
    required: true,
    type: 'password',
    maxLength: 50,
    compactWidth: 6,
    width: 12,
  },
];

export const userNamePassDomainInputs: (StringConfig | PasswordConfig)[] = [
  {
    label: 'Domain',
    maxLength: 100,
    fieldName: 'domain',
    type: 'string',
    compactWidth: 4,
    width: 12,
  },
  ...userNamePassInputs.map(
    input => ({ ...input, compactWidth: 4 } as StringConfig | PasswordConfig)
  ),
];
