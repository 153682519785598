/* eslint-disable react/display-name */
import React from 'react';
import { objectArraySort } from 'utils';
import { Tooltip } from 'antd';
import { CheckSquare, Square } from 'react-feather';

import { TFunction } from 'i18next';
import humanizeDuration from 'humanize-duration';
import { Flex, Initials, PermissionGuard } from 'components';
import { FileTransfer } from 'types';
import {
  DownloadAction,
  FileTransferActionMenu,
  StatusIcon,
} from './components';
import { FileTransferApiType } from './services';
import moment from 'moment';

const DATE_FORMAT = 'MMMM Do YYYY @ h:mm:ss A';

export const fileTransferFilterKeys = [
  'file_type',
  'status_site_str',
  'status_center_str',
  'is_public',
];

export const fileTransferSearchKeys = [
  'file_name',
  'user_name',
  'status_site_str',
  'status_center_str',
  'file_type',
];
export const fileTransferCustomOptionLabel =
  (t: TFunction) => (key: string, value: any) => {
    if (key === 'is_public') {
      switch (value) {
        case true:
          return t('Shared');
        default:
          return t('Private');
      }
    }
    return value;
  };

export const fileTransferCustomHeadingLabel =
  (t: TFunction) => (key: string) => {
    if (key === 'file_type') return t('fileTransfer:File Type');
    if (key === 'is_public') return t('fileTransfer:Shared');
    if (key === 'status_site_str') return t('common:Site');
    if (key === 'status_center_str') return t('common:Center');
    return key;
  };

function statusIcon(t: TFunction, app: 'site' | 'center') {
  return (col: any, record: any) => {
    const label = !['ok', 'skip'].includes(record.scan_status) ? (
      <>
        {t(`fileTransfer:${record.scan_status?.toUpperCase() || col}`)}
        {record.scan_result?.trim()?.length ? (
          <>
            <hr className={'mb-1 mt-1'} />
            <pre>{record.scan_result}</pre>
          </>
        ) : null}
      </>
    ) : (
      <>{t(col)}</>
    );

    return (
      <>
        <StatusIcon
          status={record[`status_${app}`]}
          label={label}
          color="#c70210"
        />
      </>
    );
  };
}

export const fileTransferColumns = (
  t: TFunction,
  api: FileTransferApiType,
  user: any,
  updateFileTransfer: (fileTransfer: FileTransfer) => void,
  setDetailModel: (fileTransfer: FileTransfer | null) => void,
  isUploading?: boolean
) => [
  {
    title: '',
    dataIndex: 'user_name',
    key: 'user_name',
    width: 30,
    render: (col: any) => (
      <>
        <Flex h="center" v="center">
          <Tooltip title={col}>
            <span>
              <Initials name={col} />
            </span>
          </Tooltip>
        </Flex>
      </>
    ),
  },
  {
    title: t('File name'),
    dataIndex: 'file_name',
    key: 'file_name',
    sorter: (_: any) => 0,
    render: (col: any, row: any) => (
      <div>
        <Tooltip title={row.file_type}>{col || '-'}</Tooltip>
      </div>
    ),
  },

  {
    title: t('Size'),
    dataIndex: 'file_size',
    key: 'file_size',
    sorter: (_: any) => 0,
    render: (col: any, record: any) => (
      <div style={{ whiteSpace: 'nowrap' }} title={col}>
        {record.file_size_str}
      </div>
    ),
  },
  {
    title: t('Site'),
    dataIndex: 'status_site_str',
    key: 'status_site_str',
    render: statusIcon(t, 'site'),
  },
  {
    title: t('Center'),
    dataIndex: 'status_center_str',
    key: 'status_center_str',
    render: statusIcon(t, 'center'),
  },
  {
    title: t('Shared'),
    dataIndex: 'is_public',
    key: 'is_public',
    render: (col: any) =>
      col ? <CheckSquare size={20} /> : <Square size={20} />,
  },
  {
    title: t('Created'),
    dataIndex: 'created_at',
    key: 'created_at',
    // width: 200,
    sorter: (a: any, b: any) => objectArraySort(a, b, 'created_at'),
    render: (col: string) => {
      const created = moment(col).format(DATE_FORMAT);
      const createdShort = new Date(col).toLocaleDateString(user.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      const createdDiff = new Date().getTime() - new Date(col).getTime();
      const createdIn = humanizeDuration(createdDiff, {
        round: true,
        language: user.language,
        units: ['mo', 'd', 'h', 'm', 's'],
        largest: 1,
      });
      return (
        <Tooltip title={created}>
          <div>
            <p className={'mb-0'} style={{ whiteSpace: 'nowrap' }}>
              {createdShort}
            </p>
            <small className={'text-muted'}>
              <>
                {createdIn} {t('ago')}
              </>
            </small>
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: t('Expires'),
    dataIndex: 'expires_at',
    key: 'expires_at',
    // width: 200,
    sorter: (_: any) => 0,
    render: (col: string) => {
      const expireDiff = new Date().getTime() - new Date(col).getTime();
      const expiresIn = humanizeDuration(expireDiff, {
        round: true,
        language: user.language,
        units: ['d'],
      });

      const expires = moment(col).format(DATE_FORMAT);
      const expiresShort = new Date(col).toLocaleDateString(user.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return (
        <Tooltip title={expires}>
          <div>
            <p className={'mb-0'} style={{ whiteSpace: 'nowrap' }}>
              {expiresShort}
            </p>
            <small className={'text-muted'}>
              <>
                {expiresIn} {t('left')}
              </>
            </small>
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    render: (col: string, record: any) => {
      const status = api.isCenter ? record.status_center : record.status_site;
      const canDownload =
        status === 'ready' && ['ok', 'skip'].includes(record.scan_status);
      const viewFileTransfer = api.isCenter
        ? 'view_center_filetransfer'
        : 'view_filetransfer';
      return (
        <Flex h={'end'} key={col}>
          <PermissionGuard codename={viewFileTransfer}>
            {(canDownload && (
              <DownloadAction t={t} api={api} fileTransfer={record} />
            )) ||
              null}
          </PermissionGuard>

          <FileTransferActionMenu
            t={t}
            api={api}
            fileTransfer={record}
            updateFileTransfer={updateFileTransfer}
            setDetailModel={setDetailModel}
            isUploading={isUploading}
          />
        </Flex>
      );
    },
  },
];
