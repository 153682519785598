import React from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import Loading from './Loading';

type Props = {
  loading?: boolean;
  title: string;
  value: number | string;
  className?: string;
  colors?: string[];
};

const GaugeCard: React.FC<Props> = ({
  loading,
  title,
  children,
  value,
  className,
  colors,
}) => {
  return (
    <Card
      className={`d-flex justify-content-center align-items-center h-full ${className}`}
    >
      <CardHeader>
        <CardTitle className="m-0">{title}</CardTitle>
      </CardHeader>
      {children && <CardBody>{children}</CardBody>}
      <CardBody>
        {loading && <Loading />}
        {/* @ts-ignore */}
        {!loading && (
          <Chart
            width={150}
            height={150}
            options={{
              chart: {
                width: '100%',
                type: 'radialBar',
              },

              colors: colors ?? [
                function ({ value }: { value: number | string }) {
                  if (typeof value === 'string') {
                    return '#bdbdbd';
                  } else if (value > 80) {
                    return '#fa4654';
                  } else if (value > 50) {
                    return '#f1c40f';
                  } else {
                    return '#5eba00';
                  }
                },
              ],
              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  track: {
                    background: '#e7e7e7',
                    strokeWidth: '97%',
                    margin: 5,
                  },
                  dataLabels: {
                    name: {
                      show: false,
                    },
                    value: {
                      offsetY: -2,
                    },
                  },
                },
              },
            }}
            // @ts-ignore
            series={[value]}
            type="radialBar"
          />
        )}
      </CardBody>
    </Card>
  );
};

export default GaugeCard;
