import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Guacamole, { Client } from 'guacamole-common-js';
import useResizeObserver from 'use-resize-observer/polyfilled';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Flex from 'components/Flex';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { SandboxStatusType, SandboxType, Site } from 'types';
import StatusIndicator from 'components/StatusIndicator';
import debounce from 'lodash/debounce';
import { useFetch } from 'hooks/useFetch';
import { openNotificationWithIcon } from 'utils/openNotificationWithIcon';
import { notification, Modal, Button as AntButton } from 'antd';
import {
  GUACAMOLE_CLIENT_STATES,
  GUACAMOLE_STATUS,
} from '../utils/guacamole/constants';
import { ClipboardMenu } from 'apps/remoteAccess/components';
import SandboxLoader from 'components/remote/SandboxLoader';
import FileTransferMenu from 'apps/remoteAccess/components/FileTransferMenu';
import { SandboxApi, SandboxApiType } from 'apps/services';

const SubTitle = styled.span`
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  margin-left: 0.75rem;
  font-size: 1.25rem;
`;

type Props = {
  logo: React.ReactNode;
  subtitle: string | React.ReactNode;
};

const getSite = (siteId: string | undefined) =>
  siteId ? ({ id: siteId, name: '', timezone: '' } as Site) : undefined;

const stateLabel = (s: number) => {
  switch (s) {
    case 0:
      return 'Idle';
    case 1:
      return 'Connecting';
    case 2:
      return 'Waiting';
    case 3:
      return 'Connected';
    case 4:
      return 'Disconnecting';
    case 5:
      return 'Disconnected';
  }
};

const Brand = ({ logo, subtitle }: Props) => (
  <Flex v="center">
    {/* @ts-ignore */}
    <Link to="/">{logo}</Link>
    <SubTitle>{subtitle}</SubTitle>
  </Flex>
);

const sendClipboard = function (client: Client, value: string) {
  const stream = client.createClipboardStream(
    'text/plain'
    // 'clipboard'
  );
  setTimeout(() => {
    // remove '\r', because on pasting it becomes two new lines (\r\n -> \n\n)
    stream?.sendBlob(btoa(unescape(encodeURIComponent(value))));
    stream?.sendEnd();
  }, 200);
};

function RemoteClient({
  component,
  siteId,
  readonly,
  containerWidth,
}: {
  component: string;
  port?: string;
  siteId?: string;
  readonly?: boolean;
  containerWidth?: number;
}) {
  const [t] = useTranslation(['common', 'remoteAccess']);
  const [[width, height], setDimensions] = useState<[number, number]>([0, 0]);
  const [authCookieReady, setAuthCookieReady] = useState<boolean>(false);
  const [sandboxLoading, setSandboxLoading] = useState<SandboxType | false>(
    false
  );
  const [site, setSite] = useState<Site | undefined>(getSite(siteId));
  const [sandboxApi, setSandboxApi] = useState<SandboxApiType>();
  const [terminate, setTerminate] = useState<string | null>(null);
  const [sandboxStatus, setSandboxStatus] = useState<SandboxStatusType>({
    name: '',
    status: 'Pending',
    condition: '',
    container_status: {
      status: '',
    },
    ready: false,
  });
  const isInitialStatus = sandboxStatus.name === '';
  const [clipboardValue, setClipboardValue] = useState<string>('');
  const [state, setState] = useState<number>(
    GUACAMOLE_CLIENT_STATES.STATE_IDLE
  );
  const displayRef = useRef<HTMLDivElement | null>(null);

  const downloadLink = useRef<HTMLAnchorElement | null>(null);

  const { sessionId } = useParams<{ sessionId: string }>();
  const guacRef = useRef<Client | null>(null);
  const connectParamsRef = useRef<any>({});
  const scale = useRef(1);
  const typedComponent: any = component;

  useEffect(() => {
    const site = getSite(siteId);
    setSite(site);
    setSandboxApi(SandboxApi(sessionId, site));
  }, [sessionId, siteId]);
  const getConnectionString = useCallback(() => {
    const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const host = window.location.host;
    const wsPath =
      wsScheme + '://' + host + '/ws/remote-session/' + sessionId + '/';

    const params: any = connectParamsRef.current;

    if (siteId) params['site_id'] = siteId;

    const newParams: any = Object.keys(params)
      .map(key => {
        if (Array.isArray(params[key])) {
          return params[key]
            .map(
              (item: any) =>
                encodeURIComponent(key) + '=' + encodeURIComponent(item)
            )
            .join('&');
        }
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
    const url = new URL(wsPath);
    return { url: url.href, params: newParams };
  }, [sessionId]);

  // get remote session object
  const [, fetchSandbox] = useFetch<any>({
    options: {
      method: 'POST',
      ...(component === 'center' && {
        headers: {
          'X-Site-Id': siteId || '',
        },
      }),
    },
    url: `/api/remote-session/${sessionId}/sandbox/?verbose=${isInitialStatus}`,
    defaultValue: {},
    initialFetch: false,
    onSucces: data => {
      setSandboxStatus({
        ...data.data?.pod,
      });
      if (data.data.pod.ready) {
        setTimeout(() => {
          setAuthCookieReady(true);
          setSandboxLoading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          fetchSandbox();
        }, 1500);
      }
    },
    onFail: ({ error }) => {
      let msg = '';
      if (error === 412) {
        msg = t(
          'The maximum amount of concurrent WEB remote sessions is reached.'
        );
        // quota exceeded
        openNotificationWithIcon(
          notification.error,
          t('Sandbox quota exceeded'),
          <>
            <p className="mt-2">{msg}</p>
            <p>{t('Please try again later.')}</p>
          </>
        );
      } else {
        msg = t('Error while trying to run sandbox.');
        openNotificationWithIcon(notification.error, t('Sandbox failure'), msg);
      }
      setTerminate(msg);
    },
  });

  // get remote session object
  const [fetchedSession] = useFetch<any>({
    options:
      component === 'center'
        ? {
            headers: {
              'X-Site-Id': siteId || '',
            },
          }
        : {},
    url: `/api/remote-session/${sessionId}/?auth_cookie`,
    defaultValue: {},
    onSucces: data => {
      if (data.data.protocol === 'web') {
        setSandboxLoading('firefox');
        fetchSandbox();
      } else {
        setAuthCookieReady(true);
      }
    },
    onFail: () => {
      openNotificationWithIcon(
        notification.error,
        t('Failed to get session details'),
        t(
          'We could not retrieve the data for this session. Please try again later.'
        )
      );
    },
  });

  // Focuses Guacamole Client Display element if it's parent element has been clicked,
  // because div with GuacamoleClient inside otherwise does not focus.
  const parentOnClickHandler = () => {
    if (displayRef.current) displayRef.current.focus();
  };
  // updates scale factor given new actual display width/height
  const rescaleDisplay = useCallback(() => {
    if (!guacRef.current || readonly) return;
    // get current width/height of connection
    const remoteDisplayWidth = guacRef.current?.getDisplay().getWidth();
    const remoteDisplayHeight = guacRef.current?.getDisplay().getHeight();

    if (!displayRef.current) {
      return;
    }

    const newWidth = displayRef.current.clientWidth;
    const newHeight = displayRef.current.clientHeight;

    // calculate which scale should we use - width or height, in order to see all of remote display
    const newScale = Math.min(
      newWidth / remoteDisplayWidth,
      newHeight / remoteDisplayHeight,
      1
    );

    guacRef.current?.getDisplay().scale(newScale);
    scale.current = newScale;
  }, []);

  // Display size update handler, currently implement onli logging to console
  const updateDisplaySize = useCallback(
    (width: number, height: number) => {
      if (!guacRef.current) return;

      // save new width/height for reconnect purposes
      connectParamsRef.current.width = width;
      connectParamsRef.current.height = height;
      if (width === 0 || height === 0) {
        console.warn('Not sending this dimentions!!', { width, height });
        return;
      }
      if (!readonly) guacRef.current.sendSize(width, height);
    },
    [rescaleDisplay]
  );
  // Main effect which constructs GuacamoleClient
  // should reaaaly be run only once
  useEffect(() => {
    if (!authCookieReady) {
      // sessionData not fetched, auth_cookie not set, yet
      return;
    }
    if (sandboxLoading) {
      // waiting for sandbox to be initialized
      return;
    }

    // Determine websocket URI
    const { url } = getConnectionString();
    const tunnel = new Guacamole.WebSocketTunnel(url);

    guacRef.current = new Guacamole.Client(tunnel);

    guacRef.current.onaudio = function clientAudio(stream, mimetype) {
      const context = Guacamole.AudioContextFactory.getAudioContext();
      context.resume();
      const player = Guacamole.AudioPlayer.getInstance(stream, mimetype);
      return player;
    };

    guacRef.current.onstatechange = (newState: number) => {
      if (guacRef.current === null) return;
      setState(newState);
      switch (newState) {
        case 3:
          guacRef.current.onclipboard = (stream: any, mimetype: any) => {
            // don't do anything if this is not active element
            // if (document.activeElement !== displayRef.current || readonly) return;
            let reader;
            if (/^text\//.exec(mimetype)) {
              reader = new Guacamole.StringReader(stream);

              // Assemble received data into a single string
              let data = '';
              reader.ontext = function textReceived(text) {
                data += text;
              };

              // Set clipboard contents once stream is finished
              reader.onend = () => {
                if (data.trim() !== '') {
                  // put data received form server to client's clipboard
                  navigator.clipboard.writeText(data);
                  setClipboardValue(data);
                }
              };
            }
          };

          break;
      }
    };
    guacRef.current.onerror = error => {
      const msg = error.message;

      // if (GUACAMOLE_STATUS[error.code]) {
      //   msg = (
      //     <p>
      //       {error.message}
      //       <br />
      //       {GUACAMOLE_STATUS[error.code].name}
      //       <br />
      //       {GUACAMOLE_STATUS[error.code].text}
      //     </p>
      //   );
      // }
      openNotificationWithIcon(notification.error, t('Error'), msg);
    };
    tunnel.onerror = (error: any) => {
      console.error('TUNNEL ERROR', error);
      let msg = <p>Connection handshake failed</p>;
      if (GUACAMOLE_STATUS[error.code]) {
        msg = (
          <p>
            {error.message}
            <br />
            {GUACAMOLE_STATUS[error.code].name}
            <br />
            {GUACAMOLE_STATUS[error.code].text}
          </p>
        );
      }
      openNotificationWithIcon(notification.error, t('Error'), msg);
    };
    // Setup connection parameters, like resolution and supported audio types
    const connectionParams: any = {
      audio: [],
    };

    // if current instance is allowed to control remote display size -
    // include window size in connection info
    connectionParams.width = displayRef.current?.clientWidth;
    connectionParams.height = displayRef.current?.clientHeight;
    // eslint-disable-next-line @typescript-eslint/camelcase
    if (siteId) connectionParams.site_id = siteId;

    connectionParams.audio = Guacamole.AudioPlayer.getSupportedTypes() || [];

    // Set connection parameters as we will use them later to reconnect
    connectParamsRef.current = connectionParams;

    // Everything has been setup - we can initiate connection
    const { params } = getConnectionString();

    try {
      guacRef.current?.connect(params);
    } catch (e) {
      console.error('Error connecting: ', e);
      openNotificationWithIcon(
        notification.error,
        t('Error'),
        <p>{String(e)}</p>
      );
    }

    guacRef.current.onfile = (stream, mimetype, filename) => {
      const blobReader = new Guacamole.BlobReader(stream, mimetype);
      blobReader.onend = () => {
        const blobUrl = URL.createObjectURL(blobReader.getBlob());
        if (downloadLink.current) {
          const link = downloadLink.current;
          link.href = blobUrl;
          link.download = filename;
          link.click();
        }
      };
      stream.sendAck('OK', 0x000);
    };

    // This effect creates Guacamole.Keyboard / Guacamole.Mouse on current display element and binds callbacks
    // to current guacamole client
    if (guacRef.current && guacRef && !readonly) {
      // Keyboard
      const keyboard = new Guacamole.Keyboard(displayRef.current);

      const fixKeys = (keysym: number) => {
        // 65508 - Right Ctrl
        // 65507 - Left Ctrl
        // somehow Right Ctrl is not sent, so send Left Ctrl instead
        if (keysym === 65508) return 65507;

        return keysym;
      };

      keyboard.onkeydown = function (keysym) {
        // if (state !== GUACAMOLE_CLIENT_STATES.STATE_CONNECTED) return false;
        guacRef.current?.sendKeyEvent(1, fixKeys(keysym));
        return true;
      };

      keyboard.onkeyup = function (keysym) {
        // if (state !== GUACAMOLE_CLIENT_STATES.STATE_CONNECTED) return;
        guacRef.current?.sendKeyEvent(0, fixKeys(keysym));
      };

      // Mouse
      const mouse = new Guacamole.Mouse(displayRef.current);

      mouse.onmousemove = function (mouseState) {
        // if (state !== GUACAMOLE_CLIENT_STATES.STATE_CONNECTED) return;
        mouseState.x = mouseState.x / scale.current;
        mouseState.y = mouseState.y / scale.current;
        guacRef.current?.sendMouseState(mouseState);
      };

      mouse.onmousedown = mouse.onmouseup = function (mouseState) {
        // if (state !== GUACAMOLE_CLIENT_STATES.STATE_CONNECTED) return;
        guacRef.current?.sendMouseState(mouseState);
      };
    }
    if (guacRef.current && displayRef.current) {
      displayRef.current.appendChild(
        guacRef.current?.getDisplay().getElement()
      );
      displayRef.current.focus();

      // Specify how to clean up after this effect:
      return function cleanup() {
        // Disconnect Guacamole Client, so server know'w we don't need any updates and teminates connection
        // to server
        if (guacRef.current) guacRef.current?.disconnect();
      };
    }
  }, [authCookieReady, sandboxLoading]);

  const onResize = debounce<
    (data: { width: number | undefined; height: number | undefined }) => void
  >((data: { width: number | undefined; height: number | undefined }) => {
    setDimensions([data?.width || width, data?.height || height]);
    updateDisplaySize(data?.width || width, data?.height || height);
  }, 500);

  const { ref: containerRef } = useResizeObserver({
    onResize,
  });
  const preventDefault = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <div
      style={
        readonly
          ? {
              opacity:
                state === GUACAMOLE_CLIENT_STATES.STATE_DISCONNECTED ? 0.5 : 1,
              transformOrigin: 'top left',
              transform: `scale(${
                !!guacRef.current?.getDisplay().getWidth() && !!containerWidth
                  ? Math.min(
                      containerWidth / guacRef.current?.getDisplay().getWidth(),
                      1
                    )
                  : '0.1'
              }`,
            }
          : {
              height: '100vh',
              padding: 0,
              margin: 0,
              border: 0,
              overflow: 'hidden',
            }
      }
    >
      <style>{'body {overflow: auto !important};'}</style>
      {!readonly && (
        <ActionBar component={component}>
          <FlexBar h="between" v="center">
            <div>
              {fetchedSession.loading ? (
                <Loading />
              ) : (
                <Brand
                  logo={<></>}
                  subtitle={fetchedSession.data.asset_object.name}
                />
              )}
            </div>
            <div>{(sandboxLoading && null) || stateLabel(state)}</div>
            <FlexBarRight h="end" v="center">
              {fetchedSession.data.is_recorded && (
                <StatusIndicator
                  variant={
                    state === GUACAMOLE_CLIENT_STATES.STATE_CONNECTED
                      ? 'red'
                      : 'grey'
                  }
                  label={t('This session is being recorded')}
                  blink={state === GUACAMOLE_CLIENT_STATES.STATE_CONNECTED}
                />
              )}
              {state >= GUACAMOLE_CLIENT_STATES.STATE_CONNECTED && (
                <div>
                  {(sandboxStatus.ready && sandboxApi && (
                    <FileTransferMenu api={sandboxApi} />
                  )) ||
                    null}{' '}
                  <ClipboardMenu
                    clipboardValue={clipboardValue}
                    onClipboardChanged={x => {
                      // setClipboardValue(x);
                      guacRef.current && sendClipboard(guacRef.current, x);
                    }}
                  />
                </div>
              )}
              <div>
                {state >= GUACAMOLE_CLIENT_STATES.STATE_CONNECTED && (
                  <Button
                    ghost={false}
                    variant={'primary'}
                    theming={typedComponent}
                    colorOverrides={{ border: '#fff' }}
                    onClick={() => guacRef.current?.disconnect()}
                    disabled={
                      state > GUACAMOLE_CLIENT_STATES.STATE_DISCONNECTED
                    }
                    loading={
                      state === GUACAMOLE_CLIENT_STATES.STATE_DISCONNECTING
                    }
                  >
                    {t('Disconnect')}
                  </Button>
                )}
                <a href="#" download="" ref={downloadLink}></a>
              </div>
            </FlexBarRight>
          </FlexBar>
        </ActionBar>
      )}
      <div ref={containerRef} style={{ height: 'calc(100vh - 3.5rem)' }}>
        {(sandboxLoading && (
          <SandboxLoader sandboxType={sandboxLoading} pod={sandboxStatus} />
        )) ||
          null}
        {authCookieReady && !sandboxLoading ? (
          <div
            onClick={parentOnClickHandler}
            onKeyDown={preventDefault}
            onKeyPress={preventDefault}
            onKeyUp={preventDefault}
            ref={displayRef}
            style={
              readonly
                ? undefined
                : {
                    overflow: 'hidden',
                    cursor: 'none',
                    width: 'calc(100% - 0px)',
                    height: 'calc(100vh - 3.5rem)',
                    outline: 'none',
                    position: 'relative',
                    zIndex: 1,
                    boxSizing: 'content-box',
                  }
            }
            tabIndex={0}
          />
        ) : (
          <></>
        )}
      </div>
      <Modal
        title={`${t('Disconnected')}`}
        cancelText={t('Close')}
        onCancel={() => window.close()}
        // onCancel={closeModal}
        okButtonProps={{ style: { display: 'none' } }}
        visible={
          state === GUACAMOLE_CLIENT_STATES.STATE_DISCONNECTED || !!terminate
        }
      >
        <p>{terminate || t('Remote session closed.')}</p>
      </Modal>
    </div>
  );
}

const ActionBar = styled.div`
  height: 3.5rem;
  padding: 13px;
  background: ${(p: { theme: any; component: string }) =>
    p.theme.colors[p.component].primary.main};
  color: white;
`;
const FlexBar = styled(Flex)`
  height: 100%;
`;
const FlexBarRight = styled(Flex)`
  > div {
    margin-left: 10px;
  }
`;

export default RemoteClient;
