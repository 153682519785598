import { defaultAssetInputs } from '../../defaults/inputs';
import React from 'react';
import { SyslogTooltip } from './SyslogTooltip';
import { inputOrderSorter } from '../../../util/inputOrderSorter';

export const defaultNetworkInputs = inputOrderSorter([
  ...defaultAssetInputs,
  {
    fieldName: 'brand',
    type: 'brand',
    required: true,
  },
  {
    fieldName: 'series',
    type: 'series',
    required: true,
  },
  {
    fieldName: 'syslog_host',
    type: 'string',
    width: 12,
    label: <SyslogTooltip />,
  },
]);
