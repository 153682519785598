import React, { ReactNode, PropsWithChildren, useState } from 'react';

import Footer from 'common/components/Footer';
import Header from 'common/components/Layout/Headers/Default';
import Navbar from 'common/components/Navbar';

import QuestionDrawer from 'common/components/QuestionDrawer';

type Props = {
  navbarItems: ReactNode;
};

const Default = (props: PropsWithChildren<Props>) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [drawerHasContent, setDrawerHasContent] = useState(false);

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header
        drawerHasContent={drawerHasContent}
        onInfoClick={() => setIsDrawerVisible(!isDrawerVisible)}
      />
      <Navbar>{props.navbarItems}</Navbar>
      <QuestionDrawer
        isDrawerVisible={isDrawerVisible}
        setDrawerHasContent={setDrawerHasContent}
        handleClose={() => setIsDrawerVisible(false)}
      />
      <main id="page-content" className="my-4">
        {props.children}
      </main>
      <Footer />
    </div>
  );
};

export default Default;
