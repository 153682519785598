import { AssetPageConfig, PlcDcsAsset } from '../../../types';
import columns from './columns';
import { fcsConfig } from './fcs';
import { avrConfig } from './avr';
import { scsConfig } from './scs';
import { bcvConfig } from './bcv';
import { fcnConfig } from './fcn';
import { wacConfig } from './wac';

export const plcdcsConfig: AssetPageConfig<PlcDcsAsset> = {
  name: 'PLC/DCS Asset',
  licenseModule: 'plcdcsassets',
  permissions: [
    'dcsplcassetbcv',
    'dcsplcassetavr',
    'dcsplcassetfcn',
    'dcsplcassetfcs',
    'dcsplcassetwac',
    'dcsplcassetscs',
  ],
  listView: {
    assetType: 'dcs_plc',
    subTypeKey: 'dsc_plc_type',
    columns,
  },
  assets: {
    avr: avrConfig,
    wac: wacConfig,
    fcn: fcnConfig,
    fcs: fcsConfig,
    scs: scsConfig,
    bcv: bcvConfig,
  },
};
