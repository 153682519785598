import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Grid from 'components/Grid';
import Flex from 'components/Flex';
import { TFunction } from 'i18next';

const Container = styled.footer`
  padding: 1rem 0;
  background: ${p => p.theme.colors.common.grey200};
`;

type MSSVersions = {
  mss_site_api: string;
  mss_asset_worker: string;
  mss_shared: string;
  celery: string;
  django: string;
};

type Props = {
  t: TFunction;
  mssVersions?: MSSVersions;
};

function Footer({ t, mssVersions }: Props) {
  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <Grid.Container>
        <Flex h="between" v="center">
          <div>
            <small>
              <>
                {t('breadcrumbs:Copyright')} &copy; {currentYear}{' '}
                {t('breadcrumbs:Yokogawa Electric Corporation')}.{' '}
                {t('breadcrumbs:All rights reserved')}.
              </>
            </small>
          </div>
          <div>
            {/* @ts-ignore */}
            <Link to="/release-notes">
              <small>
                <>{t('breadcrumbs:Release Notes')}</>
              </small>
            </Link>
            {mssVersions !== undefined ? (
              <small>
                {' | '}
                {`v${mssVersions.mss_site_api}`}
              </small>
            ) : null}
          </div>
        </Flex>
      </Grid.Container>
    </Container>
  );
}

export default Footer;
