import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import { wsusConfigureInputs } from './settingsInputs';
import { userNamePassDomainInputs } from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { AssetConfig, SecurityWsusApplication } from '../../../../types';
import { applicationTypeTableIcon } from '../../../../utils';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';

export const wsusConfig: AssetConfig<SecurityWsusApplication> = {
  name: 'Windows Server Update Services',
  type: 'wsus',
  description:
    'Onboard Windows Server Update Services (WSUS) as security application.',
  mssIcon: applicationTypeTableIcon('wsus'),
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          wsusConfigureInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    initialValues: emptyAssetFactory<SecurityWsusApplication>({
      connectionTypes: ['direct'],
      domain: true,
      assetType: 'application_type',
      assetSubType: 'wsus',
      extraFields: {
        database_port: '',
        database_ip: '',
      },
    }),
    configure: {
      inputs: wsusConfigureInputs,
      schema: inputToValidationReducer(wsusConfigureInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassDomainInputs),
        inputs: userNamePassDomainInputs,
        testConnection: {
          jsonBody: true,
          valueKeys: [
            ...inputsToKeys(userNamePassDomainInputs),
            'database_ip',
            'database_port',
          ],
        },
      },
    },
    collectors: {},
  },
};
