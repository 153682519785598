import { useParams } from 'react-router-dom';
import { AnyAssetSubType, AssetTypeURI } from '../types';

export type AssetParams = {
  category: 'assets' | 'applications';
  assetType: AssetTypeURI;
  assetSubType: AnyAssetSubType;
  assetId?: string;
  siteId?: string;
  slug?: string;
};

export const useAssetParams = (overrides?: Partial<AssetParams>) => {
  const params = useParams<AssetParams>();
  return { ...params, ...overrides };
};
