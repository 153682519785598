import React from 'react';
import { Share2 } from 'react-feather';
import { columns } from './columns';
import { Query, TablePage } from '../../../../../../types';
import { defaultAggsDataParser } from '../../../../defaults/defaultQueryHelpers';
import { NetworkMetrics } from './NetworkMetrics';
import { ExpandedContent } from './ExpandedContent';

const index = 'virtualization-microsoft-hyper-v-metrics*';

const parameters: Query['parameters'] = [
  {
    elasticQueryKey: 'mss.id',
    sourceParser: ({ params }) => params.assetId!,
  },
  { elasticQueryKey: 'mss.collector_key', sourceParser: () => 'memory' },
  {
    matcher: 'range',
    elasticQueryKey: '@timestamp',
    sourceParser: () => ({
      gte: 'now-49M',
      lte: 'now',
    }),
  },
];

export const metrics: TablePage = {
  slug: 'metrics',
  title: 'Metrics',
  icon: <Share2 size={14} />,
  type: 'table',

  tableConfig: {
    header: 'Metrics per VM',
    customElements: [
      {
        order: 1,
        element: props => <NetworkMetrics {...props} />,
      },
    ],
    ExpandedContent: props => <ExpandedContent {...props} />,
    columns,
    showDatePicker: true,
    rowKey: 'json.columns.name',
    sortingStyle: 'frontend',
    contentQueries: [
      {
        key: 'metrics',
        parameters,
        index,
        queryType: 'aggregation',
        paginationStyle: 'frontend',
        defaultSort: {
          columnKey: '@timestamp',
          order: 'descend',
        },
        dataParser: defaultAggsDataParser,
        baseQuery: {
          size: 0,
          query: {
            bool: {
              must_not: [
                {
                  match: {
                    'json.columns.name': 'Default settings',
                  },
                },
                {
                  match: {
                    'json.columns.name': 'Limit settings',
                  },
                },
              ],
            },
          },
          aggs: {
            'top-hits': {
              terms: {
                field: 'json.columns.name.keyword',
                size: 5000,
              },
              aggs: {
                top_uids_hits: {
                  top_hits: {
                    sort: [
                      {
                        '@timestamp': {
                          order: 'desc',
                        },
                      },
                    ],
                    _source: {
                      includes: [
                        'json.columns.name',
                        'json.columns.dynamic_memory',
                        'json.columns.assigned_memory',
                        'json.columns.memory_demand',
                        'json.columns.startup_memory',
                        'json.columns.minimum_memory',
                        'json.columns.maximum_memory',
                      ],
                    },
                    size: 1,
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
};
