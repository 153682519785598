import { PredefinedConfig, StandardInputConfigs } from '../../types';

export const inputOrderSorter = (
  items: (StandardInputConfigs | PredefinedConfig)[]
): (StandardInputConfigs | PredefinedConfig)[] => {
  const ordered = items
    .filter(item => item.order !== undefined)
    .sort((a, b) => a.order! - b.order!);
  const unordered = items.filter(item => item.order === undefined);

  return items.reduce<(StandardInputConfigs | PredefinedConfig)[]>(
    (acc, _, index) => {
      const found = ordered.find(item => item.order === index);
      if (found) {
        acc.push(found);
      } else if (unordered.length > 0) {
        acc.push(unordered.shift()!); // Remove the first element from unordered and push it to acc
      }
      return acc;
    },
    []
  );
};
