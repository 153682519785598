import React, { useContext, useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';
import { UseFetchState, useFetch } from 'hooks/useFetch';
import { ApiObjectPermissions, Codename, Me, Permission } from 'types';
import get from 'lodash/get';
import { AccessDenied } from 'containers/AccessDenied';

type Permissions = {
  global: Permission[];
  object: ApiObjectPermissions;
  all_codenames: string[];
};

type MeWithObjectPermissions = Me & {
  permissions: Permissions;
};

type Context = {
  user: MeWithObjectPermissions | null;
  accessDenied: boolean;
  fetchMe: () => void;
  path: string;
};

export const UserContext = React.createContext<Context>({
  user: null,
  accessDenied: false,
  fetchMe: () => {
    return;
  },
  path: '',
});

type UserProviderProps = {
  url?: string;
  opUrl?: string;
  onFail?: (
    args: UseFetchState<Me>,
    setAccessDenied?: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  path?: string;
  variant: 'site' | 'center';
  keycloak: any;
};

export default function UserProvider({
  children,
  url = '/api/me/',
  onFail,
  path = 'permissions',
  variant,
  keycloak,
}: React.PropsWithChildren<UserProviderProps>) {
  const [meWithObjPerms, setMeWithObjPerms] =
    useState<MeWithObjectPermissions>();
  const [accessDenied, setAccessDenied] = useState<boolean>(false);
  const [me, fetchMe] = useFetch<MeWithObjectPermissions>({
    url,
    defaultValue: null,
    initialFetch: false,
    onFail: async x => {
      if (x.error === 403) {
        setAccessDenied(true);
      } else {
        onFail && onFail(x, setAccessDenied);
      }
    },
    onSucces: async ({ data }) => {
      setMeWithObjPerms(data);
    },
  });

  useEffect(() => {
    url && fetchMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (accessDenied)
    return <AccessDenied variant={variant} keycloak={keycloak} />;
  return meWithObjPerms ? (
    <UserContext.Provider
      value={{ user: meWithObjPerms, fetchMe, path, accessDenied }}
    >
      {me.data ? children : null}
    </UserContext.Provider>
  ) : null;
}

export function checkAnyPermission(
  codename: Codename | Codename[],
  permissions: string[],
  all?: boolean
) {
  if (Array.isArray(codename)) {
    const methodName = all ? 'every' : 'some';
    return codename[methodName](cn => permissions.includes(cn));
  }
  return permissions.includes(codename);
}

export function checkGlobalPermission(
  codename: Codename | Codename[],
  permissions: Permission[],
  all?: boolean
) {
  if (Array.isArray(codename)) {
    const methodName = all ? 'every' : 'some';
    return codename[methodName](
      cn => !!permissions.find(p => p.codename === cn)
    );
  }
  return !!permissions.find(p => p.codename === codename);
}

const checkObjectPermission = (
  codename: string | string[],
  objectId: string,
  permissions: ApiObjectPermissions | null
) => {
  if (
    !permissions ||
    Object.keys(permissions).length === 0 ||
    !permissions[objectId]
  )
    return false;
  const permissionsForObject = Object.entries(permissions[objectId]).map(
    x => x[1]
  );

  const hasPermission = (cn: string) =>
    permissionsForObject.findIndex(pfo => pfo.codename === cn) > -1;

  if (Array.isArray(codename)) {
    return codename.some(c => {
      return hasPermission(c);
    });
  } else {
    return hasPermission(codename);
  }
};

type PermissionCheckProps = {
  codename: Codename | Codename[];
  objectId?: string;
  redirect?: boolean;
  any?: boolean;
};

export function PermissionGuard({
  codename,
  children,
  redirect,
  objectId,
  any = false,
}: React.PropsWithChildren<PermissionCheckProps>) {
  const { user } = useContext(UserContext);
  // @ts-ignore
  const redirection = <Redirect to="/" />;
  const success = <>{children}</>;
  const fail = null;

  if (user?.is_superuser) return success;
  if (!user && redirect) return redirection;
  if (!user) return fail;
  const hasGlobalPermission = any
    ? checkAnyPermission(codename, get(user, 'permissions.all_codenames', []))
    : checkGlobalPermission(codename, get(user, 'permissions.global', [])) ||
      checkGlobalPermission(
        codename,
        get(user, 'permissions.center.global', [])
      );

  if (hasGlobalPermission) return success;
  if (!hasGlobalPermission && !objectId && redirect) return redirection;
  if (!hasGlobalPermission && !objectId) return fail;
  if (!objectId) return fail;

  const hasObjectPermission = checkObjectPermission(
    codename,
    objectId,
    get(user, 'permissions.object', null)
  );

  if (redirect && !hasObjectPermission) return redirection;
  if (!hasObjectPermission) return fail;
  return success;
}

export function useUserinfo(
  objectId?: string
): [
  (codename: Codename | Codename[], all?: boolean) => boolean,
  () => void,
  Me | null,
  (codename: Codename | Codename[]) => boolean
] {
  const { user, fetchMe } = useContext(UserContext);

  const check = (codename: Codename | Codename[], all?: boolean) => {
    if (user?.is_superuser) return true;
    if (!user) return false;

    const hasGlobalPermission = checkGlobalPermission(
      codename,
      get(user, 'permissions.global', []),
      all
    );
    if (hasGlobalPermission) return true;

    const hasCenterPermission = checkGlobalPermission(
      codename,
      get(user, 'permissions.center.global', []),
      all
    );
    if (hasCenterPermission) return true;

    if (!objectId) return false;
    return checkObjectPermission(
      codename,
      objectId,
      get(user, 'permissions.object', {})
    );
  };
  const checkAny = (codename: Codename | Codename[], all?: boolean) => {
    if (user?.is_superuser) return true;
    if (!user) return false;

    return checkAnyPermission(
      codename,
      user?.permissions.all_codenames || [],
      all
    );
  };

  return [check, fetchMe, user, checkAny];
}
