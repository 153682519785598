import React from 'react';

const IconClaroty = ({ ...rest }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="42.000000pt"
    height="42.000000pt"
    viewBox="0 0 42.000000 42.000000"
    preserveAspectRatio="xMidYMid meet"
    {...rest}
  >
    <g
      transform="translate(0.000000,42.000000) scale(0.005250,-0.005310)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M3728 7889 c-256 -48 -527 -183 -708 -353 l-52 -49 431 -431 431
-432 -90 -12 c-117 -16 -304 -78 -431 -143 -106 -55 -269 -170 -329 -233 l-34
-36 99 -100 c55 -55 95 -100 90 -100 -82 0 -292 -43 -395 -81 -81 -30 -238
-114 -295 -157 l-50 -38 3 538 2 538 -42 0 c-24 0 -79 -5 -123 -11 -304 -42
-538 -161 -751 -381 -136 -141 -233 -303 -294 -487 -29 -90 -60 -268 -60 -346
l0 -45 530 0 c492 0 555 -3 520 -25 -33 -21 -148 -224 -189 -337 -38 -101 -81
-311 -81 -393 0 -5 -46 36 -103 93 l-103 102 -36 -37 c-138 -145 -263 -363
-325 -568 -21 -70 -53 -227 -53 -259 0 -6 -195 184 -433 422 l-434 434 -57
-63 c-123 -137 -238 -333 -297 -510 -93 -279 -93 -630 2 -912 30 -90 96 -230
145 -309 36 -57 145 -197 178 -228 l27 -24 434 434 c272 272 436 429 438 420
2 -8 12 -58 22 -110 31 -165 101 -338 202 -495 47 -72 179 -235 191 -235 4 0
50 43 103 96 l96 96 6 -84 c17 -220 103 -457 228 -623 l48 -65 -536 0 -536 0
7 -96 c14 -190 69 -376 157 -529 70 -122 119 -186 218 -285 153 -153 344 -263
561 -324 48 -13 265 -46 306 -46 12 0 14 76 14 545 l0 545 68 -49 c88 -65 225
-133 343 -170 94 -31 253 -61 319 -61 l35 0 -94 -93 -93 -92 53 -48 c196 -174
473 -312 699 -348 41 -7 77 -14 80 -16 2 -2 -186 -194 -419 -427 -233 -233
-422 -428 -420 -433 2 -6 36 -38 74 -72 183 -163 419 -279 671 -331 121 -25
407 -28 525 -5 174 33 335 91 474 171 86 50 221 152 270 203 l29 32 -427 427
-428 428 93 17 c278 53 533 178 721 353 l49 47 -87 87 -88 88 69 6 c211 20
422 94 592 207 l72 49 0 -530 0 -530 48 0 c75 0 239 28 331 56 337 103 619
344 771 659 79 163 116 299 127 467 l6 88 -531 2 -531 3 44 64 c130 187 225
458 225 645 l0 51 98 -98 c53 -53 103 -97 109 -97 15 0 117 121 183 216 67 97
141 249 179 366 31 99 61 245 61 302 0 28 37 -7 445 -414 244 -245 449 -446
454 -448 11 -4 133 140 191 227 61 91 140 262 167 361 47 174 65 313 59 465
-6 169 -9 191 -42 325 -58 233 -158 422 -317 599 -28 31 -55 58 -60 60 -5 2
-209 -196 -452 -439 l-443 -443 -7 49 c-39 275 -172 561 -349 750 l-65 69
-115 -115 -116 -115 0 56 c0 77 -32 248 -65 344 -38 110 -114 256 -179 343
l-55 72 556 0 556 0 -7 73 c-18 188 -55 331 -120 467 -28 57 -30 61 -81 145
-53 88 -172 222 -260 293 -217 174 -442 262 -737 288 l-78 7 0 -552 c0 -303
-2 -551 -5 -551 -2 0 -28 18 -57 40 -147 113 -424 214 -621 227 l-99 6 107
107 c59 59 106 112 104 117 -9 23 -142 132 -238 194 -159 105 -382 189 -565
215 l-48 7 437 435 438 436 -44 39 c-101 89 -237 181 -356 241 -82 41 -259 97
-372 117 -138 25 -375 24 -513 -2z m165 -2667 c-156 -14 -316 -57 -455 -120
-68 -31 -198 -110 -233 -142 -20 -19 -20 -18 -20 491 l0 509 368 -368 c334
-333 365 -367 340 -370z m867 238 l0 -500 -85 56 c-101 66 -200 112 -313 148
-97 30 -235 56 -301 56 l-46 0 370 370 c203 204 371 370 372 370 2 0 3 -225 3
-500z m-1806 -752 c-5 -7 -28 -44 -52 -81 -59 -92 -117 -219 -147 -324 -26
-89 -59 -308 -49 -324 3 -5 4 -9 2 -9 -2 0 -172 169 -378 375 l-375 375 503 0
c400 0 502 -3 496 -12z m2683 -376 l-372 -372 -11 102 c-14 128 -35 222 -74
330 -32 85 -95 209 -134 263 -13 17 -25 35 -28 40 -4 7 163 10 493 10 l499 0
-373 -373z m-1503 109 c162 -57 294 -204 335 -375 19 -76 15 -196 -8 -274 -48
-159 -174 -287 -339 -344 -94 -32 -230 -32 -324 0 -175 60 -310 208 -347 380
-14 66 -14 182 0 242 32 140 140 275 273 340 44 22 101 45 126 50 70 14 216 4
284 -19z m-1427 -569 c14 -249 129 -557 266 -709 l20 -23 -534 0 -534 0 390
390 c214 215 390 390 390 390 0 0 1 -21 2 -48z m3127 -551 l192 -191 -527 0
-528 0 62 93 c130 194 199 385 222 607 l6 65 191 -191 c104 -106 277 -278 382
-383z m-2534 -456 c169 -106 389 -179 598 -197 31 -3 22 -13 -343 -378 l-375
-375 0 518 0 519 38 -30 c20 -16 57 -42 82 -57z m1480 -432 l0 -518 -375 375
c-341 341 -373 375 -348 378 172 16 344 63 476 128 88 43 221 126 232 145 4 5
8 9 11 9 2 0 4 -233 4 -517z"
      />
    </g>
  </svg>
);

export default IconClaroty;
