import React from 'react';
import styled, { css } from 'styled-components';

type BackdropProps = {
  showHeader?: boolean;
  showFooter?: boolean;
};

const Backdrop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ showHeader }: BackdropProps) =>
    showHeader &&
    css`
      top: 3.5rem;
      height: calc(100vh - 3.5rem);
    `}

  ${({ showFooter }: BackdropProps) =>
    showFooter &&
    css`
      bottom: 50px;
      height: calc(100vh - 50px);
    `}

    ${(p: BackdropProps) =>
    p.showHeader &&
    p.showFooter &&
    css`
      height: calc(100vh - 3.5rem - 50px);
    `}
`;

const Container = styled.div`
  width: 100vw;
  margin: 0.5rem;
  pointer-events: none;

  @media (min-width: 800px) {
    max-width: 800px;
    margin: 1.75rem auto;
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  outline: 0;
`;

const StaticModal: React.FC<{}> = ({ children, ...rest }) => (
  <Backdrop {...rest}>
    <Container>
      <Modal>{children}</Modal>
    </Container>
  </Backdrop>
);

export default StaticModal;
