import { useTranslation } from 'react-i18next';
import React from 'react';
import StatusIndicator from './StatusIndicator';

const RequestStatus = ({ status }: any) => {
  const [t] = useTranslation(['common']);
  if (typeof status !== 'string') return null;
  switch (status.toLowerCase()) {
    case 'awaiting_approval':
      return (
        <>
          <StatusIndicator variant="yellow" /> {t('Awaiting approval')}
        </>
      );
    case 'allow':
      return (
        <>
          <StatusIndicator variant="green" /> {t('Approved')}
        </>
      );
    case 'denied':
      return (
        <>
          <StatusIndicator variant="red" /> {t('Denied')}
        </>
      );
    case 'revoked':
      return (
        <>
          <StatusIndicator variant="red" /> {t('Revoked')}
        </>
      );
    case 'wait_for_start_date':
      return (
        <>
          <StatusIndicator variant="green" /> {t('Future approved')}
        </>
      );
    case 'expired':
      return (
        <>
          <StatusIndicator variant="red" /> {t('Expired')}
        </>
      );
    default:
      return null;
  }
};

export default RequestStatus;
