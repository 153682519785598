import { AssetConfig, NetworkSwitchAsset } from '../../../../types';
import { defaultConfig } from '../default';
import { telnetSshConfig } from '../default/telnetSshConfig';
import { faEthernet } from '@fortawesome/free-solid-svg-icons';
import { submitDataParser } from '../default/submitDataParser';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';

export const switchConfig: AssetConfig<NetworkSwitchAsset> = {
  ...defaultConfig,
  name: 'Switch',
  type: 'switch',
  description: 'Onboard a switch via the SNMP and Syslog protocol.',
  icon: faEthernet,
  form: {
    ...defaultConfig.form,
    submitDataParser,
    initialValues: emptyAssetFactory<NetworkSwitchAsset>({
      domain: true,
      connectionTypes: ['snmp', 'telnet', 'ssh'],
      assetType: 'network_type',
      assetSubType: 'switch',
      extraFields: {
        brand: '',
        series: '',
        network_config_mode: '',
      },
    }),
    connect: { ...defaultConfig.form.connect, ...telnetSshConfig },
  },
};
