import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import MssIcon from './MssIcon';
import styled from 'styled-components';
import { IconStartConnection } from '../icons';
import StyledBadge from './StyledBadge';
import Flex from './Flex';
import { RowActionButton } from './RowActionButton';

type AssetRemoteActionsProps = {
  id: string;
  openConnectModal: (id: string) => void;
  approvedRequests?: number;
  visible?: boolean;
};

const EmptyActions = styled.div`
  width: 32px;
`;

export function AssetRemoteActionsSite({
  id,
  openConnectModal,
  approvedRequests,
  visible,
}: AssetRemoteActionsProps) {
  const [t] = useTranslation(['asset']);
  return visible ? (
    <Flex onClick={e => e.stopPropagation()}>
      <Tooltip title={t('Connect to asset')}>
        <RowActionButton
          onClick={() => openConnectModal(id)}
          shape="circle"
          style={{ border: 'none' }}
        >
          <StyledBadge count={approvedRequests} overflowCount={9}>
            <MssIcon icon={IconStartConnection} className="mr-1" />
          </StyledBadge>
        </RowActionButton>
      </Tooltip>
    </Flex>
  ) : (
    <EmptyActions />
  );
}
