import React from 'react';
import { TooltipLabel } from '../../../../components';

export const SyslogTooltip = () => (
  <TooltipLabel
    label={'Syslog host'}
    tooltipLabel={
      '(Optional) IP address or hostname to identify syslog messages from this asset'
    }
  />
);
