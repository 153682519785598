const spacing = {
  1: '.25rem',
  2: '.50rem',
  3: '.75rem',
  4: '1rem',
  5: '1.5rem',
  6: '2rem',
  7: '2.5rem',
  8: '3rem',
  9: '4rem',
  10: '5rem',
  11: '6rem',
  12: '8rem',
  13: '10rem',
  14: '12rem',
  15: '14rem',
  16: '16rem',
  17: '18rem',
  18: '20rem',
  auto: 'auto',
  px: '1px',
  full: '100%',
};

export type ThemeSpacing = typeof spacing;
export default spacing;
