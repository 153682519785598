import React from 'react';
import { Button as AntdButton } from 'antd';
import styled, { css, DefaultTheme } from 'styled-components';
import { darken } from 'polished';
import { ButtonProps } from 'antd/es/button';

export type ColorVariants = 'site' | 'center';
export type ButtonTypes = 'primary' | 'secondary';

export type MssButtonProps = ButtonProps & {
  theming: ColorVariants;
  variant?: ButtonTypes;
  ghost: boolean;
  colorOverrides?: {
    bg?: string;
    text?: string;
    border?: string;
    disabledBg?: string;
  };
};

type StyledButtonProps = MssButtonProps & {
  theme: DefaultTheme;
};

const Filtered = (props: MssButtonProps) => {
  const filteredProps: any = { ...props };

  delete filteredProps.colorOverrides;
  delete filteredProps.variant;
  delete filteredProps.ghost;
  delete filteredProps.theming;

  return <AntdButton {...filteredProps} />;
};

const Button = styled(Filtered)<MssButtonProps>`
  background: ${p =>
    p.colorOverrides?.bg ||
    p.theme.colors[p.theming].button[p.variant || 'default'].bg};
  color: ${p =>
    p.colorOverrides?.text ||
    p.theme.colors[p.theming].button[p.variant || 'default'].text};
  border: 1px solid
    ${p =>
      p.colorOverrides?.border ||
      p.theme.colors[p.theming].button[p.variant || 'default'].border};

  &:hover,
  &:focus {
    background: ${p =>
      p.colorOverrides?.bg
        ? darken(0.1, p.colorOverrides.bg)
        : p.theme.colors[p.theming].button[p.variant || 'default'].hoverBg};
    color: ${p =>
      p.theme.colors[p.theming].button[p.variant || 'default'].hoverText};
    border-color: ${p =>
      p.colorOverrides?.border
        ? p.colorOverrides.border !== 'transparent'
          ? darken(0.1, p.colorOverrides.border)
          : 'transparent'
        : p.theme.colors[p.theming].button[p.variant || 'default'].hoverBorder};
  }

  &:disabled {
    background: ${p => p.theme.colors.common.grey100} !important;
    border-color: ${p => p.theme.colors.common.grey200} !important;
    color: ${p => p.theme.colors.common.grey300} !important;
  }

  ${(p: StyledButtonProps) =>
    p.ghost &&
    css`
      box-shadow: none;
      background: transparent;
      color: ${(p: StyledButtonProps) =>
        p.colorOverrides?.text ||
        p.theme.colors[p.theming].button[p.variant || 'default'].bg};

      &:hover,
      &:focus {
        background: ${(p: StyledButtonProps) =>
          p.colorOverrides?.text ||
          p.theme.colors[p.theming].button[p.variant || 'default'].bg};
        border-color: ${(p: StyledButtonProps) =>
          p.colorOverrides?.text ||
          p.theme.colors[p.theming].button.primary.bg};
      }

      &:disabled {
        background: ${p =>
          p.colorOverrides?.disabledBg ??
          p.theme.colors.common.grey100} !important;
        border-color: ${p => p.theme.colors.common.grey200} !important;
        color: ${p => p.theme.colors.common.grey300} !important;
      }
    `};

  ${(p: StyledButtonProps) =>
    p.loading &&
    css`
      svg.anticon-spin {
        margin-top: -6px;
      }
    `}
`;

export default Button;
