import { windowsWMIConfig } from './windows_wmi';
import columns from './columns';
import { windowsAgentConfig } from './windows_agent';
import { AssetPageConfig, ComputeAsset } from '../../../types';

export const computeConfiguration: AssetPageConfig<ComputeAsset> = {
  name: 'Compute Asset',
  licenseModule: 'computeassets',
  permissions: ['computeassetwindowswmi', 'computeassetwindowsagent'],
  listView: {
    assetType: 'compute',
    subTypeKey: 'compute_type',
    columns,
  },
  assets: {
    windows_wmi: windowsWMIConfig,
    windows_agent: windowsAgentConfig,
  },
};
