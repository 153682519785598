import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import {
  defaultAssetInputs,
  userNamePassDomainInputs,
} from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { AssetConfig, AssetManagementPRMApplication } from '../../../../types';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';

export const prmConfig: AssetConfig<AssetManagementPRMApplication> = {
  name: 'Yokogawa - Plant Resource Manager (PRM)',
  type: 'prm',
  description:
    'Add a Yokogawa PRM server as field assets collector. MSS will use Yokogawa PRM to monitor the connected field assets.',
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultAssetInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    initialValues: emptyAssetFactory({
      domain: true,
      connectionTypes: ['direct'],
      assetType: 'assetmanagement_type',
      assetSubType: 'prm',
    }),
    configure: {
      inputs: defaultAssetInputs,
      schema: inputToValidationReducer(defaultAssetInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassDomainInputs),
        inputs: userNamePassDomainInputs,
        testConnection: {
          valueKeys: [...inputsToKeys(userNamePassDomainInputs), 'ip_address'],
          customKeyMapping: { ip_address: 'host' },
        },
      },
    },
    collectors: {},
  },
};
