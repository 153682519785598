import React, { useState } from 'react';
import { SwitchProps, SwitchChangeEventHandler } from 'antd/es/switch';
import { Switch as AntSwitch } from 'antd';
import styled from 'styled-components';

type Props = SwitchProps & {
  [key: string]: any;
  id?: string;
  isChecked?: boolean;
  label?: string;
};

const Label = styled.label`
  padding-left: 8px;
  cursor: pointer;
  margin-bottom: 1rem;
`;

function Switch({ isChecked = false, onChange, label, ...props }: Props) {
  const [checked, setChecked] = useState<boolean>(isChecked);

  const onChangeHandler: SwitchChangeEventHandler = (val, e) => {
    if (onChange) {
      onChange(val, e);
    }

    setChecked(!checked);
  };

  return (
    <>
      <AntSwitch checked={checked} onChange={onChangeHandler} {...props} />
      {label && props.id && <Label htmlFor={props.id}>{label}</Label>}
    </>
  );
}

export default Switch;
