import React from 'react';

const YokoLogo = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      d="M23.49 47.993A56.808 56.808 0 0147 23.61C47 23.643 23.51.028 23.51 0 11.75 11.846 4.803 18.739 0 23.616c-.006 0 .042.027 0 0C9.942 29.054 17.669 36.958 23.49 48"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default YokoLogo;
