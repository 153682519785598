import React from 'react';
import { defaultSort, Flex, objectArraySort } from '@mss/mss-component-library';
import { Button, Dropdown, Menu } from 'antd';
import { TFunction } from 'i18next';
import dateFormat from 'dateformat';
import styled from 'styled-components';
import { priorityToString, stateToString } from 'alerts/util/labels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import SubMenu from 'antd/lib/menu/SubMenu';

export const MenuItem = styled(Menu.Item)`
  padding: 8px 24px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

export const StyledSubMenu = styled(SubMenu)`
  padding: 6px 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const RowAction = styled(Button)`
  justify-content: center;
  border: none;

  &:hover,
  &:active {
    background: ${p => p.theme.colors.common.grey400};
  }
`;

const columns = (
  t: TFunction,
  updateAlert: (id: string, state: string) => () => void,
  disabled: boolean,
) => [
  {
    title: t('automations:Alert'),
    dataIndex: 'title',
    key: 'title',
    sorter: (a: any, b: any) => objectArraySort(a, b, 'title'),
  },
  {
    title: t('automations:External ID'),
    dataIndex: 'sys_id',
    key: 'sys_id',
    sorter: (a: any, b: any) => objectArraySort(a, b, 'number'),
    render: (sys_id: any, row: any) =>
      sys_id && (
        <span>
          <span>{row.number}</span>
          <br />
          <small className="muted">{row.servicenow_config_name}</small>
        </span>
      ),
  },
  {
    title: t('automations:Asset / Application'),
    dataIndex: 'asset_name',
    key: 'asset_name',
    width: 165,
    sorter: (a: any, b: any) => objectArraySort(a, b, 'asset_name'),
  },
  {
    title: t('Criticality'),
    dataIndex: 'priority_display',
    key: 'priority_display',
    width: 40,
    sorter: (a: any, b: any) => objectArraySort(a, b, 'priority_display'),
    render: (priority_display: string) => priorityToString(t, priority_display),
  },
  {
    title: t('Custodian'),
    dataIndex: 'custodian_name',
    key: 'custodian_name',
    sorter: (a: any, b: any) => objectArraySort(a, b, 'custodian_name'),
  },
  {
    title: t('automations:State'),
    dataIndex: 'state_display',
    key: 'state_display',
    width: 40,
    sorter: (a: any, b: any) => objectArraySort(a, b, 'state_display'),
    render: (state_display: string) => stateToString(t, state_display),
  },
  {
    title: t('automations:Count'),
    dataIndex: 'count',
    key: 'count',
    width: 20,
    sorter: (a: any, b: any) => defaultSort(a.count, b.count),
  },
  {
    title: t('automations:Start date'),
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: (a: any, b: any) => objectArraySort(a, b, 'created_at'),
    render: (record: any) =>
      record && dateFormat(record || '', 'mmmm dS, yyyy @ h:MM:ss TT'),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    width: 40,
    render: (id: any, record: any) => {
      return (
        !record.sys_id &&
        !disabled && (
          <Flex onClick={e => e.stopPropagation()}>
            <Dropdown
              trigger={['click']}
              disabled={disabled}
              overlay={
                <Menu style={{ minWidth: 192 }}>
                  <StyledSubMenu title={t('automations:Set status')}>
                    <Menu.Item
                      onClick={updateAlert(id, 'closed')}
                      data-ta={'status-Closed'}
                    >
                      {t('automations:Closed')}
                    </Menu.Item>
                    <Menu.Item
                      onClick={updateAlert(id, 'acknowledged')}
                      data-ta={'status-Acknowledged'}
                    >
                      {t('automations:Acknowledged')}
                    </Menu.Item>
                    <Menu.Item
                      onClick={updateAlert(id, 'scheduled')}
                      data-ta={'status-Scheduled'}
                    >
                      {t('automations:Scheduled')}
                    </Menu.Item>
                    <Menu.Item
                      onClick={updateAlert(id, 'awaiting_evidence')}
                      data-ta={'status-AwaitingEvidence'}
                    >
                      {t('automations:Awaiting Evidence')}
                    </Menu.Item>
                    <Menu.Item
                      onClick={updateAlert(id, 'resolved')}
                      data-ta={'status-Resolved'}
                    >
                      {t('automations:Resolved')}
                    </Menu.Item>
                  </StyledSubMenu>
                </Menu>
              }
            >
              <div style={{ width: 32, textAlign: 'center' }}>
                <FontAwesomeIcon icon={faEllipsisV} size="sm" />
              </div>
            </Dropdown>
          </Flex>
        )
      );
    },
  },
];

export default columns;
