import colors from './colors';
import spacing from './spacing';
import grid from './grid';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors,
  spacing,
  grid,
};

export default theme;
