export const defaultParams: any = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${window.sessionStorage.getItem('token')}`,
  },
});

export const _fetch = (input: RequestInfo, init?: RequestInit | undefined) => {
  const params = init || { headers: {} };
  return fetch(input, {
    ...defaultParams(),
    ...params,
    headers: { ...defaultParams().headers, ...params?.headers },
  });
};

const Fetch = {
  get: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetch(input, { ...init, method: 'GET' }),
  post: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetch(input, { ...init, method: 'POST' }),
  patch: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetch(input, { ...init, method: 'PATCH' }),
  put: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetch(input, { ...init, method: 'PUT' }),
  delete: (input: RequestInfo, init?: RequestInit | undefined) =>
    _fetch(input, { ...init, method: 'DELETE' }),
};

export default Fetch;
