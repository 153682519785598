import Api from 'common/services/Api';
import { CreateReportAPI } from 'reports/components/Modal/ReportCreateForm';
import { ReportEntityAPI } from 'reports/views/List';

const baseUrl = '/apr/report/';

export function fetchCategories(reportId: string) {
  return Api.get(`${baseUrl}${reportId}/phase/`);
}

export function createReport(report: CreateReportAPI) {
  return Api.post(`${baseUrl}`, report);
}

export function editReport(reportId: string, report: ReportEntityAPI) {
  return Api.put(`${baseUrl}${reportId}/`, report);
}

export function generateReport(reportId: string) {
  return Api.post(`${baseUrl}${reportId}/generate/`);
}

export function publishReport(reportId: string) {
  return Api.post(`${baseUrl}${reportId}/publish/`);
}

export function unpublishReport(reportId: string) {
  return Api.post(`${baseUrl}${reportId}/unpublish/`);
}

export function deleteReport(reportId: string) {
  return Api.delete(`${baseUrl}${reportId}/`);
}
