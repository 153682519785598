import { createAction, createSlice } from '@reduxjs/toolkit';
import { arrayToObject } from 'common/utils';

// List compute asset
export const fetchSecurityAppsRequested = createAction<{ site: string }>(
  'securityApps/fetchSecurityAppsRequested',
);
export const fetchSecurityAppsFailed = createAction(
  'securityApps/fetchSecurityAppsFailed',
);

// Single compute asset
export const fetchSecurityAppRequested = createAction<{
  id: string;
  site: string;
}>('securityApps/fetchSecurityAppRequested');

export const fetchSecurityAppFailed = createAction(
  'securityApps/fetchSecurityAppFailed',
);

const securityAppsSlice = createSlice({
  name: 'securityApps',
  initialState: {
    securityApps: [],
    securityApp: {},
    securityAppsById: {},
  },
  reducers: {
    fetchSecurityAppsCompleted(state, action) {
      state.securityApps = action.payload;
      state.securityAppsById = arrayToObject(action.payload);
    },
    fetchSecurityAppCompleted(state, action) {
      state.securityApp = action.payload;
    },
  },
});

export const { fetchSecurityAppCompleted, fetchSecurityAppsCompleted } =
  securityAppsSlice.actions;

export default securityAppsSlice.reducer;
