import React from 'react';
import { Brave, Chrome, Chromium, Firefox, Opera, Edge } from 'icons/browsers';

import { SandboxType } from 'types';

type Props = {
  sandboxType: SandboxType;
  className?: string;
  height?: number | string;
  width?: number | string;
  style?: React.CSSProperties;
};
type SandboxIconMapping = {
  [key in SandboxType]?: any;
};

const ICONS: SandboxIconMapping = {
  brave: Brave,
  chrome: Chrome,
  chromium: Chromium,
  edge: Edge,
  firefox: Firefox,
  opera: Opera,
};

function SandboxIcon({ sandboxType, ...props }: Props) {
  const Icon = ICONS[sandboxType];
  return <Icon {...props} />;
}

export default SandboxIcon;
