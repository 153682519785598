import { userNamePassInputs } from '../../defaults/inputs';
import {
  BaseInputConfig,
  NumberConfig,
  PasswordConfig,
  StringConfig,
} from '../../../../types';
import { commonInputConfigs } from '../../defaults/commonInputConfigs';

const sharedProps: Pick<BaseInputConfig, 'compactWidth' | 'width'> = {
  compactWidth: 6,
  width: 12,
};
export const connectInputs: (StringConfig | PasswordConfig | NumberConfig)[] = [
  ...userNamePassInputs.map(x => {
    if (x.fieldName === 'username') return { ...x, label: 'FCN Username' };
    if (x.fieldName === 'password') return { ...x, label: 'FCN Password' };
    return x;
  }),
  {
    label: 'SSH Hostname',
    fieldName: 'ssh_hostname',
    type: 'string',
    isIP: true,
    required: true,
    ...sharedProps,
  },
  {
    label: 'SSH Username',
    fieldName: 'ssh_username',
    type: 'string',
    required: true,
    ...sharedProps,
  },
  {
    label: 'SSH Password',
    fieldName: 'ssh_password',
    type: 'password',
    required: true,
    ...sharedProps,
  },
  commonInputConfigs.port({
    label: 'SSH Port',
    fieldName: 'ssh_port',
    required: true,
    ...sharedProps,
  }),
];
