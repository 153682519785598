import React from 'react';

const IconVeeam = ({ ...rest }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="42.000000pt"
    height="42.000000pt"
    viewBox="0 0 42.000000 42.000000"
    preserveAspectRatio="xMidYMid meet"
    {...rest}
  >
    <g
      transform="translate(0.000000,42.000000) scale(0.018667,-0.018667)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M295 2041 c-48 -22 -69 -44 -90 -94 -13 -32 -15 -137 -15 -824 0
-743 1 -790 19 -828 22 -48 44 -69 94 -90 32 -13 137 -15 824 -15 745 0 790 1
829 19 47 22 80 59 94 110 8 24 10 296 8 827 l-3 790 -27 41 c-16 24 -44 49
-70 62 -44 21 -50 21 -834 21 -744 0 -791 -1 -829 -19z m365 -667 c0 -283 4
-419 12 -446 16 -56 78 -121 140 -149 l53 -24 458 -3 457 -3 0 -140 0 -140
-512 3 -513 3 -67 33 c-73 36 -133 96 -168 168 -48 98 -50 120 -50 627 l0 477
95 0 95 0 0 -406z m516 111 c67 -159 126 -296 131 -303 5 -9 53 93 138 292
l129 306 104 0 c79 0 103 -3 99 -12 -3 -7 -78 -191 -167 -408 -89 -217 -165
-401 -167 -408 -4 -9 -36 -12 -131 -10 l-126 3 -172 418 -171 418 104 -3 105
-3 124 -290z"
      />
    </g>
  </svg>
);

export default IconVeeam;
