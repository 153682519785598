const REGEXP_LONGITUDE = new RegExp(/^(-?\d{1,3}(\.|,)(\d{6,20})?)$/);
const REGEXP_LATITUDE = new RegExp(/^(-?\d{1,2}(\.|,)(\d{6,20})?)$/);
const REGEXP_EMPTY = new RegExp(/^$|\s$/);
const REGEXP_IP = new RegExp(
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
);
const REGEXP_UUID = new RegExp(
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
);

const REGEXP_TIMEPERIOD = new RegExp(
  /^(0[0-9]{1}|[1-9]{1}[0-9]{1,2}|[0]{2})\:(0[0-9]{1}|[1-5]{1}[0-9]{1})\:(0[0-9]{1}|[1-5]{1}[0-9]{1})/
);

export default {
  longitude: REGEXP_LONGITUDE,
  latitude: REGEXP_LATITUDE,
  empty: REGEXP_EMPTY,
  ip: REGEXP_IP,
  uuid: REGEXP_UUID,
  timeperiod: REGEXP_TIMEPERIOD,
};
