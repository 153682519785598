import { TFunction } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import usePageQuery from './usePageQuery';
import useDebounce from './useDebounce';

type DateRangeType = {
  to?: string | undefined;
  from?: string | undefined;
};

type Pagination = {
  current: number;
  showQuickJumper: boolean;
  showSizeChanger: boolean;
  total: number;
  showTotal: (total: number, range: any[]) => string;
  onShowSizeChange: (_: any, size: number) => void;
};

export type FilterApi = {
  setPageSize: (size: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
  onChangeAssetTable: (pagination: any, filters: any, sorter: any) => void;
  updateDateRange: (range: DateRangeType) => void;
  setSearch: (query: string) => void;
  goToPage: (page: number) => void;
  updateFilters: (filters: FiltersType) => void;
  toggleFilter: (key: any, value: any) => void;
  filters: FiltersType;
  selectedFilters: SelectedFiltersType;
  params: UrlParamsType;
  urlParams: string;
  dateRangeUrlParams: string;
  pagination: Pagination;
  search: string;
};

type UrlParamsType = {
  [key: string]: string | number;
};

type SelectedFiltersType = {
  [key: string]: string[];
};

type FiltersType = {
  [key: string]: {
    label: string;
    options: {
      label: string;
      value: string;
      type?: string;
    }[];
  };
};

export const useApiFilters = (
  t: TFunction,
  totalCount: number,
  defaultPageSize?: number,
  debounceSearchDelay?: number
): FilterApi => {
  const [pageSize, setPageSize] = useState(defaultPageSize || 10);
  const [page, goToPage] = usePageQuery(totalCount / pageSize);
  const [ordering, setOrdering] = useState<string | null>(null);
  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>(
    {}
  );
  const [filters, setFilters] = useState<FiltersType>({});
  const debouncedSearchTerm = useDebounce<string>(
    search,
    debounceSearchDelay ?? 400
  );

  useEffect(() => {
    goToPage(1);
  }, [debouncedSearchTerm]);

  const [dateRange, setDateRange] = useState<DateRangeType | null>(null);
  const [params, setParams] = useState<UrlParamsType>({});
  useEffect(() => {
    const shouldResetToPage1 =
      dateRange?.from !== params['created_at_after'] ||
      dateRange?.to !== params['created_at_before'] ||
      debouncedSearchTerm !== (params['search'] ?? '');

    const newParams: UrlParamsType = {
      page: shouldResetToPage1 ? 1 : page,
      page_size: pageSize,
    };

    if (dateRange?.from) newParams['created_at_after'] = dateRange.from;
    if (dateRange?.to) newParams['created_at_before'] = dateRange.to;
    if (ordering) newParams['ordering'] = ordering;
    if (!!debouncedSearchTerm) newParams['search'] = debouncedSearchTerm;

    if (selectedFilters)
      Object.entries(selectedFilters).forEach(([x, y]) => {
        if (y?.length) newParams[x] = y.join(',');
      });

    setParams(newParams);
  }, [
    page,
    pageSize,
    dateRange?.to,
    dateRange?.from,
    ordering,
    debouncedSearchTerm,
    selectedFilters,
  ]);

  const urlParams: string = useMemo(() => {
    return Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');
  }, [params]);

  const dateRangeUrlParams: string = useMemo(() => {
    const params = [];

    if (dateRange?.from) params.push(`created_at_after=${dateRange.from}`);
    if (dateRange?.to) params.push(`created_at_before=${dateRange.to}`);

    const urlParams = params.join('&');
    return urlParams || '';
  }, [dateRange]);

  const onShowSizeChange = (_: any, size: number) => {
    setPageSize(size);
  };

  const onChange = (pagination: any, _filters: any, sorter: any) => {
    if (page !== pagination.current) goToPage(pagination.current);
    let field = String(sorter.field).split(',').join('__');
    const ordering = [sorter.order === 'descend' ? '-' : '', field].join('');
    setOrdering(sorter.order ? ordering : null);
  };

  const onChangeAssetTable = (pagination: any, _filters: any, sorter: any) => {
    if (page !== pagination.current) goToPage(pagination.current);
    let field = String(sorter.field).split(',');
    if (!['created_at'].includes(sorter.field)) field = ['data', ...field];
    const ordering = [
      sorter.order === 'descend' ? '-' : '',
      field.join('__'),
    ].join('');
    setOrdering(sorter.order ? ordering : null);
  };

  const toggleFilter = (key: any, value: any) => {
    goToPage(1);
    const selected = selectedFilters[key] || [];
    if (selected.includes(value))
      setSelectedFilters({
        ...selectedFilters,
        [key]: selected.filter((x: any) => x !== value),
      });
    else
      setSelectedFilters({
        ...selectedFilters,
        [key]: [...selected, value],
      });
  };

  const updateDateRange = useCallback(
    (range: DateRangeType) => {
      if (dateRange?.to === range.to && dateRange?.from === range.from) {
        return;
      }
      setDateRange(range);
    },
    [dateRange]
  );
  const pagination = useMemo(
    () => ({
      current: page,
      showQuickJumper: true,
      showSizeChanger: true,
      total: totalCount,
      showTotal: (total: number, range: any[]) =>
        t('common:(RANGE1)-(RANGE2) of (TOTAL) items', {
          range1: range[0],
          range2: range[1],
          TOTAL: total,
        }),
      onShowSizeChange: onShowSizeChange,
    }),
    [page, totalCount]
  );

  return useMemo<FilterApi>(
    () => ({
      updateFilters: setFilters,
      selectedFilters,
      filters,
      setPageSize,
      setSearch,
      onChange,
      onChangeAssetTable,
      updateDateRange,
      goToPage,
      toggleFilter,
      pagination,
      urlParams,
      params,
      dateRangeUrlParams,
      search,
    }),
    [
      page,
      pageSize,
      totalCount,
      filters,
      selectedFilters,
      ordering,
      search,
      dateRange,
      urlParams,
    ]
  );
};

export default useApiFilters;
