import React from 'react';
import styled from 'styled-components';

type Props = {
  vertical?: boolean;
};

const Vertical = styled.div`
  width: 1px;
  height: 38px;
  background: #d8d8d8;
  margin-right: 1.4rem;
  margin-left: 0rem;
  align-self: center;
`;

function Divider({ vertical }: Props) {
  if (vertical) return <Vertical />;
  return <div className="my-3 border border-top" />;
}

export default Divider;
