import { Button, Modal } from 'antd';
import SetupLogs from './SetupLogs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../../components';

export default function SetupLogsModal() {
  const { t } = useTranslation(['asset', 'common']);
  const [setupModal, openSetupModal] = useState<boolean>(false);
  return (
    <>
      <Flex h="end" v="center" className="mt-4">
        <Button onClick={() => openSetupModal(true)} type={'link'}>
          {t('Show log configuration settings')}
        </Button>
      </Flex>
      {setupModal && (
        <Modal
          width={600}
          title={t('Setup logs')}
          okText={t('common:OK')}
          onCancel={() => openSetupModal(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          onOk={() => openSetupModal(false)}
          visible
        >
          <SetupLogs />
        </Modal>
      )}
    </>
  );
}
