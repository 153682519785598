import React, { FormEvent, useState } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import { useRouteMatch } from 'react-router-dom';
import Flex from 'components/Flex';
import Loading from 'components/Loading';
import { useFetch } from 'hooks/useFetch';
import { Switch } from 'antd';
import { Fetch } from '../utils/_fetch';

const text = {
  heading: 'Terms & Conditions',
  acceptText: "I've read the terms & conditions",
  acceptButton: 'Accept',
  declineButton: 'Decline',
};

type Props = {
  showHeader?: boolean;
  showFooter?: boolean;
};

const Backdrop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ showHeader }: Props) =>
    showHeader &&
    css`
      top: 3.5rem;
      height: calc(100vh - 3.5rem);
    `}

  ${({ showFooter }: Props) =>
    showFooter &&
    css`
      bottom: 50px;
      height: calc(100vh - 50px);
    `}

    ${(p: Props) =>
    p.showHeader &&
    p.showFooter &&
    css`
      height: calc(100vh - 3.5rem - 50px);
    `}
`;

const ModalContainer = styled.div`
  width: 100vw;
  margin: 0.5rem;
  pointer-events: none;

  @media (min-width: 800px) {
    max-width: 800px;
    margin: 1.75rem auto;
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  outline: 0;
`;
// @ts-ignore
const StaticModal = ({ children, ...rest }) => (
  <Backdrop {...rest}>
    <ModalContainer>
      <Modal>{children}</Modal>
    </ModalContainer>
  </Backdrop>
);

type ModalBodyContentProps = {
  hasContent: boolean;
};

const ModalHeader = styled.div`
  border: none;
  padding: 32px;
  padding-bottom: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  line-height: 1.375;
  font-size: 1.5rem;
  font-weight: 500;
`;

const ModalBody = styled.div`
  padding: 0 32px;
  position: relative;
`;

const BodyBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: min-height ease 0.2s;
  min-height: 100px;

  @media (min-width: 576px) {
    min-height: 150px;
  }

  ${(p: ModalBodyContentProps) =>
    p.hasContent
      ? css`
          min-height: 0px !important;
          align-items: flex-start;

          justify-content: flex-start;
        `
      : null}
`;

const ModalFooter = styled.div`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Label = styled.span`
  font-weight: 600;
  cursor: pointer;
  margin-left: 8px;
`;

const SwitchContainer = styled(Flex)`
  margin: 1rem 0;
`;

type KeycloakButtonProps = {
  variant: 'site' | 'center';
  theme: DefaultTheme;
};

const KeycloakButton = styled.button`
  margin-top: 0;
  padding: 0.375rem 0.75rem;
  line-height: 2.25;
  border-radius: 3px;
  transition: 0.2s ease;
  min-width: 200px;
  font-size: 14px;

  ${p =>
    p.color === 'primary' &&
    css`
      color: white;
      background: ${(p: KeycloakButtonProps) =>
        p.theme.colors[p.variant].primary.main};
      border: 2px solid
        ${(p: KeycloakButtonProps) => p.theme.colors[p.variant].primary.main};

      &:not(:disabled):hover {
        background: ${(p: KeycloakButtonProps) =>
          p.theme.colors[p.variant].primary.dark};
        border: 2px solid
          ${(p: KeycloakButtonProps) => p.theme.colors[p.variant].primary.dark};
      }
    `}
  ${p =>
    p.color === 'grey' &&
    css`
      color: rgba(0, 0, 0, 0.67);
      background: white;
      border: 2px solid transparent;

      &:not(:disabled):hover {
        background: #f7f7f7;
        border: 2px solid #f7f7f7;
      }
    `}
    &:disabled {
    filter: saturate(0.6);
    opacity: 0.4;
    cursor: default;
  }
`;

type TermsConditionsProps = {
  termsUrl?: string;
  logout: Function;
  variant: 'site' | 'center';
};

const TermsConditions = ({
  termsUrl = '/api/center/terms.html/',
  logout,
  variant = 'site',
}: TermsConditionsProps) => {
  const { url } = useRouteMatch();
  const [readTerms, setReadTerms] = useState(false);
  const [terms] = useFetch<string>({ url: termsUrl, type: 'html' });

  const handleTermsClick = async (event: FormEvent, accepted: boolean) => {
    event.preventDefault();
    try {
      await Fetch().post('/api/me/terms/', {
        body: JSON.stringify({ agree: accepted }),
      });
    } catch (error) {
      console.log('Could not POST terms and conditions', error);
    } finally {
      if (accepted) {
        window.location.href = url;
      } else {
        logout();
      }
    }
  };

  const toggleReadTerms = () => setReadTerms(!readTerms);
  // @ts-ignore
  return (
    <StaticModal>
      <ModalHeader>{text.heading}</ModalHeader>
      <ModalBody>
        <BodyBlock hasContent={Boolean(terms.data)}>
          {terms.data && (
            <>
              <span dangerouslySetInnerHTML={{ __html: terms.data }}></span>
              <SwitchContainer v="center">
                <Switch
                  size="small"
                  checked={readTerms}
                  onChange={toggleReadTerms}
                />
                <Label onClick={toggleReadTerms}>{text.acceptText}</Label>
              </SwitchContainer>
            </>
          )}
          {!terms.data && <Loading />}
        </BodyBlock>
      </ModalBody>
      <ModalFooter>
        <KeycloakButton
          color="grey"
          disabled={!terms.data}
          onClick={event => handleTermsClick(event, false)}
          variant={variant}
        >
          {text.declineButton}
        </KeycloakButton>
        <KeycloakButton
          disabled={!readTerms}
          color="primary"
          variant={variant}
          onClick={event => handleTermsClick(event, true)}
        >
          {text.acceptButton}
        </KeycloakButton>
      </ModalFooter>
    </StaticModal>
  );
};

export default TermsConditions;
