import React from 'react';
import { AnyAssetApplication, DefaultAssetColumnProps } from '../../../types';
import {
  actionsColumn,
  assetTypeColumn,
  assetTypeLogoColumn,
  custodianColumn,
  ipAddressColumn,
  locationColumn,
  nameColumn,
  operationalStatusColumn,
  roleColumn,
} from '../../../components/DefaultAssetColumns';
import { YokoLogo } from '../../../icons';

function columns<Asset extends AnyAssetApplication>(
  props: DefaultAssetColumnProps,
  subTypeKey: string
) {
  return [
    operationalStatusColumn<any>(props),
    assetTypeLogoColumn<Asset>(props, subTypeKey, <YokoLogo />),
    nameColumn<Asset>(props),
    assetTypeColumn<Asset>(props, subTypeKey),
    roleColumn<Asset>(props),
    custodianColumn<Asset>(props),
    locationColumn<Asset>(props),
    ipAddressColumn<Asset>(props),
    actionsColumn<Asset>(props),
  ];
}

export default columns;
