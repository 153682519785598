import { SearchResponse } from 'elasticsearch';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

function useFetchElasticQuery<TParameters extends object, TResult>(
  fetch: any,
  queryKey: string,
  parameters?: TParameters,
  options?: Omit<
    UseQueryOptions<SearchResponse<TResult>>,
    'queryKey' | 'queryFn'
  >,
): [boolean, SearchResponse<TResult>, any] {
  const { data, isFetching, error } = useQuery<SearchResponse<TResult>>({
    queryKey: [queryKey],
    queryFn: async () => fetch(parameters),
    refetchInterval: false,
    ...options,
  });

  return [isFetching, data, error];
}

export default useFetchElasticQuery;
