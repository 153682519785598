export function humanBits(
  bits: string | number,
  unit: 'bit' | 'bps' | 'packets' = 'bit'
) {
  /**
   * Convert number of bits to human-readable string.
   *
   * Example:
   *
   * < humanizaBits(10000000)
   * > "10 Mbit"
   *
   */
  if (!bits) return '';
  if (typeof bits === 'string') bits = parseInt(bits);
  if (bits === 0) return '0';
  const k = 1000;
  const sizes = ['', 'K', 'M', 'G', 'T', 'P'];
  const i = Math.floor(Math.log(bits) / Math.log(k));
  if (sizes[i] === undefined) {
    console.warn('Unable to translate into humanBits:', bits);
    return '0';
  }
  return parseFloat((bits / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i] + unit;
}
