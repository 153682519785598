import React from 'react';
import styled from 'styled-components';

type MssIconProps = {
  size?: number;
  icon: any;
  className?: string;
  style?: React.CSSProperties;
};

function IconWrapper({ size, icon: Icon, ...rest }: MssIconProps) {
  return <Icon size={size} {...rest} />;
}

const MssIcon = styled(IconWrapper)<MssIconProps>`
  height: ${p => (p.size ? `${p.size}px` : '18px')};
  width: ${p => (p.size ? `${p.size}px` : '18px')};
`;

export default MssIcon;
