import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import {
  defaultAssetInputs,
  userNamePassDomainInputs,
} from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';
import { AssetConfig, ComputeWindowsWMIAsset } from '../../../../types';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';

export const windowsWMIConfig: AssetConfig<ComputeWindowsWMIAsset> = {
  name: 'Windows Asset - WMI',
  type: 'windows_wmi',
  description: 'WMI based data collection for Windows Clients and Servers.',
  icon: faWindows,
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          defaultAssetInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    method: 'put',
    initialValues: emptyAssetFactory<ComputeWindowsWMIAsset>({
      assetType: 'compute_type',
      assetSubType: 'windows_agent',
      connectionTypes: ['direct'],
      domain: true,
    }),
    configure: {
      inputs: defaultAssetInputs,
      schema: inputToValidationReducer(defaultAssetInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassDomainInputs),
        inputs: userNamePassDomainInputs,
        testConnection: {
          method: 'post',
          valueKeys: [...inputsToKeys(userNamePassDomainInputs), 'ip_address'],
          customKeyMapping: { ip_address: 'host' },
          isWMI: true,
          endpoint: 'connection_test',
        },
      },
    },
    collectors: {
      labels: {
        dns_server: 'DNS Server Only',
        directory_service: 'Domain Controller Only',
      },
      tooltips: {
        dns_server:
          'DNS Server data collection needs extra configuration. Make sure you run the onboarding tool with the required settings before enabling this collector.',
        trusted_platform_module:
          'Trusted Platform Module data collection needs extra configuration. Make sure Enable Account and Remote Enable permissions are set for the user selected for data collection. Ask your administrator for more information.',
        drive_encryption:
          'Drive Encryption data collection needs extra configuration. Make sure Enable Account and Remote Enable permissions are set for the user selected for data collection. Ask your administrator for more information.',
      },
    },
  },
};
