import { Fetch, snake } from 'utils';
import pickBy from 'lodash/pickBy';
import { FileTransfer, Site } from 'types';

type DateRangeProps = {
  dateRange?: {
    to?: string | undefined;
    from?: string | undefined;
  };
};
type CreateProps = {
  fileName?: string;
  fileType?: string;
  fileSize?: number;
};

type UpdateProps = {
  id: string;
  expiresAt?: string;
  isPublic?: boolean;
  deleted?: boolean;
};

const baseUrl = '/api/file-transfer/';

export type DownloadLink = {
  url: string;
  token: string;
};

export type AppContext = 'site' | 'center';

export type FileTransferApiType = {
  // find better solution for appContext?
  appContext: AppContext;
  isCenter: boolean;
  isSite: boolean;
  fetchFileTransfers: (urlParams: string) => Promise<any>;
  fetchFileTransferFilters: (urlParams: string) => Promise<any>;
  get: (id: string) => Promise<any>;
  create: (data: CreateProps) => Promise<any>;
  update: (data: UpdateProps) => Promise<FileTransfer>;
  getDownloadLink: (fileTransfer: FileTransfer) => Promise<DownloadLink>;
  destroy: (fileTransfer: FileTransfer) => Promise<boolean>;
  scan: (fileTransfer: FileTransfer) => Promise<any>;
};

export const FileTransferApi: (
  site?: Site | undefined
) => FileTransferApiType = (site?: Site) => ({
  appContext: site ? 'center' : 'site',
  isCenter: !!site,
  isSite: !site,
  fetchFileTransfers: async (urlParams: string) => {
    const url = urlParams.length ? `${baseUrl}?${urlParams}` : baseUrl;

    const result = await Fetch(site?.id).get(url);
    if (result.status >= 400) throw Error(result.statusText);

    return await result.json();
  },
  fetchFileTransferFilters: async (urlParams: string) => {
    const baseUrlFilters = `${baseUrl}filters/`;

    const url = urlParams.length
      ? `${baseUrlFilters}?${urlParams}`
      : baseUrlFilters;

    const result = await Fetch(site?.id).get(url);
    if (result.status >= 400) throw Error(result.statusText);

    const data = await result.json();
    return data;
  },
  get: async (id: string) => {
    const url = `${baseUrl}${id}/`;
    const response = await Fetch(site?.id).get(url);
    if (response.status !== 200) throw new Error(response.statusText);
    const result: FileTransfer = await response.json();
    return result;
  },
  create: async (data: CreateProps) => {
    const cleanedData = snake(pickBy(data, x => x !== undefined));
    const body = JSON.stringify(cleanedData);
    const response = await Fetch(site?.id).post(baseUrl, { body });
    if (response.status !== 201) throw new Error(response.statusText);
    return await response.json();
  },
  update: async (data: UpdateProps) => {
    const { id } = data;
    const url = `${baseUrl}${id}/`;
    const cleanedData = snake(pickBy(data, x => x !== undefined));
    const body = JSON.stringify(cleanedData);
    const response = await Fetch(site?.id).patch(url, { body });
    if (response.status !== 200) throw new Error(response.statusText);

    const result: FileTransfer = await response.json();
    return result;
  },
  getDownloadLink: async (fileTransfer: FileTransfer) => {
    const url = `${baseUrl}${fileTransfer.id}/download_link/`;
    const response = await Fetch(site?.id).get(url);
    if (response.status !== 200) throw new Error(response.statusText);
    const result: DownloadLink = await response.json();
    return result;
  },
  destroy: async (fileTransfer: FileTransfer) => {
    const url = `${baseUrl}${fileTransfer.id}/`;
    const response = await Fetch(site?.id).delete(url);
    if (response.status !== 204) throw new Error(response.statusText);
    return true;
  },
  scan: async (fileTransfer: FileTransfer) => {
    const response = await Fetch(site?.id).post(
      `${baseUrl}${fileTransfer.id}/scan/`
    );
    if (response.status !== 201) throw new Error(response.statusText);
    return await response.json();
  },
});
