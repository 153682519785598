import React, { PropsWithChildren } from 'react';
import Default from './Layouts/Default';
import Empty from './Layouts/Empty';
import SiteNavbar from './Navbar/Site';
import CenterNavbar from './Navbar/Center';

type Layouts = 'Site' | 'Center' | 'Empty';

type Props = {
  layout: Layouts;
};

function Layout(props: PropsWithChildren<Props>) {
  const { layout, ...rest } = props;

  if (layout === 'Site')
    return <Default navbarItems={<SiteNavbar />} {...rest} />;
  if (layout === 'Center')
    return <Default navbarItems={<CenterNavbar />} {...rest} />;
  if (layout === 'Empty') return <Empty {...rest} />;
  return null;
}

export default Layout;
