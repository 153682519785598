import Fetch from 'common/services/_fetch';

type PermissionBody = {
  permission: number | number[];
  user?: string;
  group?: string;
};

export const permission = {
  toggle: async (permission: PermissionBody, user_permission_id?: string) => {
    if (!Array.isArray(permission.permission)) {
      let val = permission.permission;
      permission.permission = [val];
    }
    if (user_permission_id)
      return Fetch.delete(`/api/user/permission/${user_permission_id}/`, {
        body: JSON.stringify(permission),
      });
    return Fetch.post(`/api/user/permission/`, {
      body: JSON.stringify(permission),
    });
  },
  get: async (userId: string) => {
    return Fetch.get(`/api/user/${userId}/permissions/`);
  },
};
