import React, { ReactNode, useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import { Alert, Modal, Timeline, Card } from 'antd';
import { Loading } from 'components';
import { FileTransfer, FileTransferLog } from 'types';
import Table from 'antd/es/table';
import styled from 'styled-components';
import { TFunction } from 'i18next';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import StatusTag from './StatusTag';
import { FileTransferApiType } from '../services';
import { Col, Row } from 'components/Grid';

/**
 * TODO currently showing log for filetransfer not implemented, yet
 */
const TimelineHeader = styled(Row)`
  h4 {
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0;
  }

  h4.right {
    text-align: right;
  }
`;
const LogTable = styled(Table)`
  &.ant-table,
  * .ant-table {
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }
  }

  + ul.ant-table-pagination.ant-pagination {
    float: left;
    margin: 16px;
  }

  .ant-pagination-item-link {
    svg {
      vertical-align: unset;
    }
  }
`;

type Entry = {
  key: string;
  field: string | ReactNode;
  value: string | number | null | ReactNode;
  children?: Entry[];
};

const columns = (t: TFunction) => [
  {
    title: t('id'),
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: t('User'),
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: t('Size'),
    dataIndex: 'file_size_str',
    key: 'file_size_str',
  },
];

type DetailModalProps = {
  t: TFunction;
  api: FileTransferApiType;
  fileTransfer: FileTransfer | null;
  closeModal: any;
};
type StatusProps = {
  log: FileTransferLog;
};

function Status({ log }: StatusProps) {
  if (log.oldstatus === log.status) return <></>;
  if (log.oldstatus) {
    return (
      <>
        <StatusTag status={log.oldstatus} />{' '}
        <ArrowRightOutlined className="mr-2" />
        <StatusTag status={log.status} />
      </>
    );
  }
  return <StatusTag status={log.status} />;
}

export default function DetailModal({
  t,
  api,
  fileTransfer,
  closeModal,
}: DetailModalProps) {
  const [details, setDetails] = useState<FileTransfer | null>(fileTransfer);

  useEffect(() => {
    if (!fileTransfer || !fileTransfer.id) return;

    api.get(fileTransfer.id).then(x => setDetails(x));
  }, [fileTransfer, fileTransfer?.updated_at]);
  const close = () => {
    setDetails(null);
    closeModal();
  };
  return (
    <Modal
      title={`${t('File transfer')} ${
        fileTransfer ? ' > ' + fileTransfer?.file_name : ''
      }`}
      cancelText={<>{t('common:Close')}</>}
      onCancel={close}
      okButtonProps={{ style: { display: 'none' } }}
      open={!!fileTransfer}
      width={'66%'}
    >
      {(fileTransfer && fileTransfer.id && (
        <LogTable
          bordered
          columns={columns(t)}
          dataSource={[fileTransfer]}
          showHeader={true}
          pagination={false}
          size="small"
        />
      )) || <Loading />}
      <Card
        className="mt-2"
        title={
          <TimelineHeader>
            <Col>
              <h4 className={'right mr-8'}>
                <>{t('Site')}</>
              </h4>
            </Col>
            <Col>
              <h4 className={'ml-8'}>
                <>{t('Center')}</>
              </h4>
            </Col>
          </TimelineHeader>
        }
      >
        <Timeline mode="alternate">
          {!details ? (
            <Loading />
          ) : (
            details.logs &&
            details.logs.map((log, i) => (
              <Timeline.Item
                key={`log${i}`}
                position={log.app === 'center' ? 'left' : 'right'}
                color={log.status === 'error' ? 'red' : 'gray'}
                {...(i === 0
                  ? {
                      dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                    }
                  : {})}
              >
                <small>
                  {dateFormat(log.time || '', 'mmmm dS, yyyy @ h:MM:ss TT')}
                </small>
                <br />
                {log.status && <Status log={log} />}
                {log.message && (
                  <Alert
                    className={'mt-1'}
                    message={log.message}
                    showIcon
                    // description={log.message}
                    type={log.level}
                  />
                )}
              </Timeline.Item>
            ))
          )}
        </Timeline>
      </Card>
    </Modal>
  );
}
