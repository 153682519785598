import { defaultStep1DisabledNext } from '../../defaults/step1DisabledNext';
import { isaeConfigureInputs } from './settingsInputs';
import { userNamePassInputs } from '../../defaults/inputs';
import { inputsToKeys } from '../../defaults/configKeys';
import { defaultStepDirectConnectDisabledNext } from '../../defaults/stepDirectConnectDisabledNext';
import { AssetConfig, AssetManagementISAEApplication } from '../../../../types';
import { emptyAssetFactory } from '../../../../utils/assetHelpers/emptyDefaultAsset';
import { inputToValidationReducer } from '../../../../utils/inputToValidation';

export const isaeConfig: AssetConfig<AssetManagementISAEApplication> = {
  name: 'Yokogawa - InsightSuite AE (ISAE)',
  type: 'isae',
  description:
    'Add a Yokogawa ISAE server as diagnostics collector. MSS will use Yokogawa ISAE to monitor the field assets from the PRM.',
  steps: [
    {
      label: 'Configure',
      value: 'configure',
      disabledNextStep: (formikContext, numberOfAssets) =>
        defaultStep1DisabledNext(
          formikContext,
          numberOfAssets,
          isaeConfigureInputs
        ),
    },
    {
      label: 'Connect',
      value: 'connect',
      disabledNextStep: formikContext =>
        defaultStepDirectConnectDisabledNext(formikContext),
    },
    {
      label: 'Collectors',
      value: 'collectors',
    },
  ],
  form: {
    initialValues: emptyAssetFactory({
      connectionTypes: ['direct'],
      assetType: 'assetmanagement_type',
      assetSubType: 'isae',
      extraFields: { isae_port: '' },
    }),
    configure: {
      inputs: isaeConfigureInputs,
      schema: inputToValidationReducer(isaeConfigureInputs),
    },
    connect: {
      direct: {
        schema: inputToValidationReducer(userNamePassInputs),
        inputs: userNamePassInputs,
        testConnection: {
          valueKeys: [
            ...inputsToKeys(userNamePassInputs),
            'ip_address',
            'isae_port',
          ],
        },
      },
    },
    collectors: {},
  },
};
