import { FormikErrors, FormikProps } from 'formik';
import {
  AnyAssetApplication,
  AnyInputConfig,
  FormikNewFormValues,
  FormikType,
} from '../../../types';

export function defaultStep1DisabledNext<AssetType extends AnyAssetApplication>(
  formikContext: FormikProps<FormikNewFormValues<AssetType>>,
  numberOfAssets: number,
  inputs: AnyInputConfig[]
) {
  if (Object.keys(formikContext.errors).length < 1 && formikContext.dirty) {
    return false;
  }

  if (!formikContext.dirty) return true;
  const fieldNames = [
    ...inputs.map(input => input.fieldName),
    'custom_field_values',
  ];

  return Array.from({ length: numberOfAssets }, (_, i) => {
    if (!formikContext.errors?.assets?.[i]) return false;
    const errors = formikContext.errors.assets[i] as FormikErrors<
      FormikType<AssetType>
    >;
    return Object.keys(errors.values as any).some(err =>
      fieldNames.includes(err)
    );
  }).some(Boolean);
}
