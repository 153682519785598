import { useCallback, useEffect, useState } from 'react';
import { parseFlatJsonToForm } from '../utils';

type UseFetchInput<ReturnValue, ParsedOutput> = {
  url: string;
  dataParser?: (arr: ReturnValue) => ParsedOutput;
  defaultValue?: any;
  options?: RequestInit;
  initialFetch?: boolean;
  body?: any;
  formdata?: boolean;
  onSucces?: (args: UseFetchState<ReturnValue>) => void;
  onFail?: (args: UseFetchState<ReturnValue>) => void;
  onEmpty?: (args: UseFetchState<ReturnValue>) => void;
  type?: 'json' | 'html';
  bearer?: string;
};

export type UseFetchState<ReturnValue> = {
  loading: boolean;
  data: ReturnValue;
  empty: boolean;
  error: boolean | number;
  errorInfo?: { msg: string; code: number };
};

export function useFetch<ReturnValue = any, ParsedOutput = ReturnValue>({
  url,
  dataParser,
  formdata,
  defaultValue = null,
  initialFetch = true,
  options = {},
  onSucces,
  onFail,
  onEmpty,
  body,
  bearer = sessionStorage.getItem('token') || undefined,
  type = 'json',
}: UseFetchInput<ReturnValue, ParsedOutput>): [
  UseFetchState<ParsedOutput>,
  () => void,
  () => void
] {
  const [count, setCount] = useState<number>(1);
  const [prevCount, setPrevCount] = useState<number>(initialFetch ? 0 : 1);

  const [result, setResult] = useState<UseFetchState<ParsedOutput>>({
    loading: initialFetch,
    data: defaultValue,
    empty: false,
    error: false,
  });

  const updateCount = () => setCount(count + 1);

  const reset = () => {
    setResult({
      loading: initialFetch,
      data: defaultValue,
      empty: false,
      error: false,
    });
  };

  const fetcher = useCallback(async () => {
    let optionsBody = body;
    if (formdata) optionsBody = parseFlatJsonToForm(optionsBody);
    if (!formdata) optionsBody = JSON.stringify(optionsBody);

    try {
      setResult({ ...result, loading: true });
      const api = await fetch(url, {
        ...options,
        body: optionsBody,
        credentials: 'include',
        headers: {
          'Content-Type': formdata
            ? 'application/x-www-form-urlencoded; charset=UTF-8'
            : 'application/json',
          Authorization: `Bearer ${bearer}`,
          ...options.headers,
        },
      });

      if (api.status >= 300 || api.status < 200) {
        const data = await api.json();
        const newResult = {
          loading: false,
          data: data || defaultValue,
          empty: true,
          error: api.status,
        };
        if (onFail) onFail(newResult);
        setResult(newResult);
        throw new Error();
      } else {
        const data: any = type === 'json' ? await api.json() : await api.text();
        const isArray = Array.isArray(data);

        let hasData = false;
        if (isArray) hasData = data.length > 0;
        if (!isArray && typeof data === 'object')
          hasData = Object.keys(data).length > 0;
        if (typeof data === 'string' || typeof data === 'number')
          hasData = true;

        if (hasData) {
          const dataMap = dataParser ? dataParser(data) : data;
          const newResult = {
            loading: false,
            data: dataMap,
            empty: false,
            error: false,
          };

          if (onSucces) onSucces(newResult);
          setResult(newResult);
        } else {
          const newResult = {
            loading: false,
            data: defaultValue,
            empty: true,
            error: false,
          };

          if (onEmpty) onEmpty(newResult);
          setResult(newResult);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [url, dataParser, defaultValue, options]);

  useEffect(() => {
    if (count !== prevCount) {
      fetcher();
      setPrevCount(count);
    }
  }, [fetcher, prevCount, count]);

  return [result, updateCount, reset];
}

export default useFetch;
