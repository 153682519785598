import { defaultConfig } from '../default';
import { AssetConfig, PlcDcsAVRAsset } from '../../../../types';

const baseAVRConfig = defaultConfig as AssetConfig<PlcDcsAVRAsset>;
export const avrConfig: AssetConfig<PlcDcsAVRAsset> = {
  ...baseAVRConfig,
  name: 'Yokogawa - VNet Router (AVR)',
  type: 'avr',
  description: 'Onboard a Yokogawa VNet Router (AVR) via HIS or HIS/ENG.',
  form: {
    ...baseAVRConfig.form,
    initialValues: { ...baseAVRConfig.form.initialValues, dsc_plc_type: 'avr' },
  },
};
