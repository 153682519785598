import Fetch from 'common/services/_fetch';

export const logout = async () => {
  const response = await Fetch.post(
    `${process.env.REACT_APP_API_URL}/me/logout/`,
  );
  if (response.status !== 200) throw new Error(response.statusText);
  const result = await response.json();
  window.location.href = result.redirect;
};
