import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'i18next';
import { ComputeSubType } from '../../types';

export function computeTypeTableLabel(compute_type: ComputeSubType): string {
  switch (compute_type) {
    case 'windows_agent':
      return 'Windows Asset - Agent';
    case 'windows_wmi':
      return 'Windows Asset - WMI';
    default:
      return 'To be implemented';
  }
}

export function computeTypeTableUrl(compute_type: ComputeSubType): string {
  switch (compute_type) {
    case 'windows_agent':
      return 'agent';
    case 'windows_wmi':
      return 'wmi';
    default:
      return '';
  }
}

export function computeTypeSteps(
  t: TFunction,
  compute_type?: string
): { label: string; value: string }[] {
  switch (compute_type) {
    case 'windows_agent':
      return [
        { label: t('Configure'), value: 'ca-windows_agent-configure' },
        { label: t('Collectors'), value: 'ca-windows_agent-collectors' },
        { label: t('Setup'), value: 'ca-windows_agent-setup' },
      ];
    case 'windows_wmi':
      return [
        { label: t('Configure'), value: 'ca-windows_wmi-configure' },
        { label: t('Connect'), value: 'ca-windows_wmi-connection' },
        { label: t('Collectors'), value: 'ca-windows_wmi-collectors' },
      ];
    default:
      return [];
  }
}

export function computeTypeTableIcon(
  compute_type: ComputeSubType
): IconDefinition {
  switch (compute_type) {
    case 'windows_agent':
    case 'windows_wmi':
      return faWindows;
    default:
      return faQuestionCircle;
  }
}
