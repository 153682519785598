import {
  AnyAssetApplication,
  AnyAssetSubType,
  ConnectionTypes,
} from '../../types';
import { insertObjIf } from '../arrayHelpers';

export const emptyDefaultAsset: Partial<AnyAssetApplication> = {
  location: '',
  role: '',
  name: '',
  custodian: '',
  priority: '',
  description: '',
  ip_address: '',
  custom_field_values: {},
};

export const emptyDirectConnection = {
  username: '',
  password: '',
};

export const emptyTelnetConection = {
  telnet_username: '',
  telnet_password: '',
  telnet_port: '23',
};

export const emptySSHConnection = (type: 'default' | 'fcn') => ({
  ssh_username: '',
  ssh_password: '',
  ssh_port: '22',
  ...insertObjIf(type === 'default', {
    ssh_passphrase: '',
    ssh_private_key: '',
    ssh_public_key: '',
  }),
  ...insertObjIf(type === 'fcn', {
    ssh_hostname: '',
  }),
});

export const emptySNMPConnection = {
  version: '',
  community: '',
  security_level: '',
  auth_protocol: 'MD5',
  privacy_protocol: 'AES',
  security_username: '',
  auth_password: '',
  privacy_password: '',
};

export const emptyDomain = {
  domain: '',
};

type EmptyAssetFactoryArgs<T> = {
  domain?: boolean;
  sshType?: 'default' | 'fcn';
  connectionTypes: ConnectionTypes[];
  assetType: keyof T;
  assetSubType: AnyAssetSubType;
  extraFields?: Record<string, any>;
};

// TODO: This should have the asset config as input, since it can defer every parameters from it
export function emptyAssetFactory<T = AnyAssetApplication>({
  domain,
  connectionTypes,
  sshType = 'default',
  assetType,
  assetSubType,
  extraFields,
}: EmptyAssetFactoryArgs<T>): T {
  return {
    ...insertObjIf(!!domain, emptyDomain),
    ...insertObjIf(connectionTypes.includes('direct'), emptyDirectConnection),
    ...insertObjIf(connectionTypes.includes('telnet'), emptyTelnetConection),
    ...insertObjIf(
      connectionTypes.includes('ssh'),
      emptySSHConnection(sshType)
    ),
    ...insertObjIf(connectionTypes.includes('snmp'), emptySNMPConnection),
    ...emptyDefaultAsset,
    [assetType]: assetSubType,
    ...insertObjIf(!!extraFields, extraFields),
  } as unknown as T;
}
